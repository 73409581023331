import { parse } from '../../../util/urlHelpers';
import { deleteAlgoliaData, updateCloseListing, updateListing } from '../../../util/api';
import { storableError } from '../../../util/errors';
import { addMarketplaceEntities } from '../../../ducks/marketplaceData.duck';

// ================ Action types ================ //

export const CLEAR_UPDATED_FORM = 'app/AdminPanelPage/CLEAR_UPDATED_FORM';

export const GET_APPROVED_LISTING_REQUEST = 'app/AdminPanelPage/GET_APPROVED_LISTING_REQUEST';
export const GET_APPROVED_LISTING_SUCCESS = 'app/AdminPanelPage/GET_APPROVED_LISTING_SUCCESS';
export const GET_APPROVED_LISTING_ERROR = 'app/AdminPanelPage/GET_APPROVED_LISTING_ERROR';

export const SEND_APPROVED_LISTING_REQUEST = 'app/AdminPanelPage/SEND_APPROVED_LISTING_REQUEST';
export const SEND_APPROVED_LISTING_SUCCESS = 'app/AdminPanelPage/SEND_APPROVED_LISTING_SUCCESS';
export const SEND_APPROVED_LISTING_ERROR = 'app/AdminPanelPage/SEND_APPROVED_LISTING_ERROR';


// ================ Reducer ================ //
const resultIds = data => data.data.map(l => l.id);
const perPageOutcomes = 10;
const initialState = {
  pagination: null,
  fetchInProgress: false,
  updateInProgress: false,
  updateTransactionError: null,
  getListingError: null,
  getListingData: [],
  listingUpdatedId: null,
  getPendingTransactionListingData: [],
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload, } = action;

  switch (type) {
    //  Admin Listing
    case GET_APPROVED_LISTING_REQUEST:
      return {
        ...state,
        fetchInProgress: true,
        getListingError: null,
        pagination: null
      };
    case GET_APPROVED_LISTING_SUCCESS: {
      return {
        ...state,
        getListingData: resultIds(payload.data),
        fetchInProgress: false,
        pagination: payload.data.meta
      };
    }
    case GET_APPROVED_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      return {
        ...state,
        getListingData: null,
        pagination: null,
        fetchInProgress: false,
        getListingError: payload.error
      };
    }

    //  Get it Approved
    case SEND_APPROVED_LISTING_REQUEST:
      return {
        ...state,
        updateInProgress: true,
        listingUpdatedId: payload.id,
      };

    case SEND_APPROVED_LISTING_SUCCESS:
      return {
        ...state,
        updateInProgress: false
      };

    case SEND_APPROVED_LISTING_ERROR:
      return {
        ...state,
        listingUpdated: false,
        updateInProgress: false
      };

    case CLEAR_UPDATED_FORM:
      return {
        ...state,
        updateTransactionError: null,
        getTransactionError: null
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const clearUpdatedForm = () => ({
  type: CLEAR_UPDATED_FORM,
});

export const getApproveListingRequest = params => ({ type: GET_APPROVED_LISTING_REQUEST, payload: { params } });
export const getApproveListingSuccess = result => ({ type: GET_APPROVED_LISTING_SUCCESS, payload: result });
export const getApproveListingError = error => ({ type: GET_APPROVED_LISTING_ERROR, payload: error, error: true });

export const sendToApproveListRequest = params => ({ type: SEND_APPROVED_LISTING_REQUEST, payload: { ...params } });
export const sendToApproveListSuccess = result => ({ type: SEND_APPROVED_LISTING_SUCCESS, payload: result });
export const sendToApproveListError = error => ({ type: SEND_APPROVED_LISTING_ERROR, payload: error, error: true });

// ================ Thunk ================ //

export const fetchAdminByApproval = (searchParams, needRequest = true) => (dispatch, getState, sdk) => {
  const { page = 1, ...rest } = searchParams;
  if (needRequest) {
    dispatch(getApproveListingRequest());
  }
  const params = {
    page,
    per_page: perPageOutcomes,
    include: ['author'],
    // pub_approvedByAdmin: false,
    ...rest
  };
  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(getApproveListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(getApproveListingError(storableError(e)));
      throw e;
    });
}

export const closeListingByAdmin = ({ id, page }, fetchListings = true) => (dispatch, getState, sdk) => {
  dispatch(sendToApproveListRequest({ id }));

  return updateCloseListing({ id })
    .then(response => {
      dispatch(sendToApproveListSuccess(response));
      deleteAlgoliaData({id}).then(()=>{
      })
      if (fetchListings) {
        setTimeout(() => {
          dispatch(fetchAdminByApproval({ page }, false));
        }, 1000);
      }
      return response;
    })
    .catch(e => {
      dispatch(sendToApproveListError(storableError(e)));
      throw e;
    });
}

export function loadData(param, search) {
  const { page = 1, ...rest } = parse(search) || {};

  return fetchAdminByApproval({ ...rest, page });
}
