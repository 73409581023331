import React, { Component } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm, Field } from 'react-final-form';

import config from '../../config';
import * as validators from '../../util/validators';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
} from '../../util/validators';
import { findOptionsForSelectFilter } from '../../util/search';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';

import {
  Form,
  Button,
  IconCard,
  NamedLink,
  AddImages,
  DragAndDrop,
  IconSpinner,
  FieldSelect,
  FieldCheckbox,
  FieldTextInput,
  FieldRadioButton,
  FieldPhoneNumberInput,
  LocationAutocompleteInputField,
  Modal,
} from '../../components';
import RemoveImageButton from '../../components/AddImages/RemoveImageButton';
import { LightBlueButton, OrangeButton, PrimaryButton } from '../../components/Button/Button';

import css from './RequirmentsProjectForm.module.css';
import { TOS_ASSET_NAME, TermsOfServiceContent } from '../../containers/TermsOfServicePage/TermsOfServicePage';
import { camelize } from '../../util/string';
import moment from 'moment';

const identity = v => v;
const consultationOptions = findOptionsForSelectFilter('consultation', config.custom.filters);
const skillLevel = findOptionsForSelectFilter('skillLevel', config.custom.filters);
const ACCEPT_IMAGES = 'image/*,video/*';
const MAX_LENGTH = '500';

class RequirmentsProjectFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { imageUploadRequested: false, openModal: false, modalEverClicked: false };

    this.handleDrop = this.handleDrop.bind(this);
    this.onImageUploadHandler = this.onImageUploadHandler.bind(this);
  }

  handleDrop(files) {
    if (files && files.length) {
      const filetype = files[0].type;
      if (filetype.search('image') > -1) {
        this.onImageUploadHandler(files);
      }
    }
  };

  onImageUploadHandler = async (files) => {
    if (files && files.length) {
      this.setState({ imageUploadRequested: true });
      this.props.onImageUpload({ files })
        .then(() => {
          this.setState({ imageUploadRequested: false })
        }
        )
    }
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        keepDirtyOnReinitialize={true}
        onImageUploadHandler={this.onImageUploadHandler}
        imageUploadRequested={this.state.imageUploadRequested}
        handleDrop={this.handleDrop}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            intl,
            invalid,
            form,
            images,
            values,
            guestUser,
            showForm,
            handleDrop,
            currentUser,
            handleSubmit,
            onRemoveImage,
            uploadInProgress,
            redirectTologin,
            guestUserRedirect,
            submitInProgress,
            redirectToListing,
            redirectToPrevious,
            imageUploadRequested,
            onImageUploadHandler,
            onManageDisableScrolling,
            tosAssetsData,
            tosFetchError,
            tosFetchInProgress,
            // ready,
            // updated,
            // disabled,
            // pristine,
            // inProgress,
            // fetchErrors,
            // saveActionMsg,
            // onImageUpload,
            // uploadImageError,
          } = formRenderProps;

          const classes = classNames(rootClassName, className);
          const submitDisabled = invalid || submitInProgress;

          const videoUrls = [];
          const imageUrls = [];
          if (images && images.length) {
            for (let i = 0; i < images.length; i++) {
              const url = images[i];
              if (url.endsWith(".mp4") || url.endsWith(".webm") || url.endsWith(".mov") || url.endsWith(".avi")) {
                videoUrls.push(url);
              } else if (url.endsWith(".png") || url.endsWith(".jpg") || url.endsWith(".jpeg")) {
                imageUrls.push(url);
              }
            }
          }
          const descriptionLabel = intl.formatMessage({
            id: 'RequirmentsProjectForm.descriptionLabel',
          });
          const selectLabel = intl.formatMessage({
            id: 'RequirmentsProjectForm.selectLabel',
          });
          const skillLevelLabel = intl.formatMessage({
            id: 'RequirmentsProjectForm.skillLevelConsultation',
          });
          const preferencelabel = intl.formatMessage({
            id: 'RequirmentsProjectForm.preferencelabel',
          });
          const requiredlabel = intl.formatMessage({
            id: 'RequirmentsProjectForm.requiredlabel',
          });
          const videoChatLabel = intl.formatMessage({
            id: 'RequirmentsProjectForm.videoChatLabel',
          });
          const phoneLabel = intl.formatMessage({
            id: 'RequirmentsProjectForm.phoneLabel',
          });
          const issueDescription = intl.formatMessage({
            id: 'RequirmentsProjectForm.textareaDescribeYourIssue',
          });
          const listDetailsLikeMaterials = intl.formatMessage({
            id: 'RequirmentsProjectForm.listDetailsLikeMaterials',
          });

          const nameLabel = intl.formatMessage({
            id: 'RequirmentsProjectForm.nameLabel',
          });
          const yourProvinceLabel = intl.formatMessage({
            id: 'RequirmentsProjectForm.yourProvinceLabel',
          });
          const addressNotRecognizedMessage = intl.formatMessage({
            id: 'EditListingLocationForm.addressNotRecognized',
          });

          const termsLink = (
            <span
              className={css.termsLink}
              onClick={() => this.setState({ openModal: true, modalEverClicked: true })}
              role="button"
              tabIndex="0"
            >
              <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
            </span>
          );
          const emailLabel = intl.formatMessage({
            id: 'RequirmentsProjectForm.emailLabel',
          });
          const phoneNumberLabel = intl.formatMessage({
            id: 'RequirmentsProjectForm.phoneNumberLabel',
          });
          const emailInvalidMessage = intl.formatMessage({
            id: 'LoginForm.emailInvalid',
          });
          const emailValid = validators.emailFormatValid(emailInvalidMessage);

          const numberRequiredMessage = intl.formatMessage({
            id: 'ProfileSetupForm.phoneRequired',
          });

          const phoneNumberMinLengthMessage = intl.formatMessage(
            {
              id: 'ProfileSetupForm.phoneTooShort',
            },
            {
              minLength: validators.PASSWORD_MIN_LENGTH,
            }
          );
          const numberinLength = validators.minLength(
            phoneNumberMinLengthMessage,
            validators.PHONE_MIN_LENGTH
          );

          const numberRequired = validators.required(numberRequiredMessage);
          const chooseImageText = (
            <span className={css.chooseImageText}>
              <IconCard brand="projectupload" />
            </span>
          );


          // const readAggredLabel = intl.formatMessage({
          //   id: 'RequirmentsProjectForm.readAggredLabel',
          //   values: { termsServices },
          // });
          let uploadImageFailed = null;

          const maxLengthMessage = intl.formatMessage(
            { id: 'RequirmentsProjectForm.maxLimit' },
            {
              maxLength: MAX_LENGTH,
            }
          );
          const addressPlaceholder = intl.formatMessage({
            id: 'ProfileSetupForm.addressPlaceholder',
          });
          const addressRequiredMessage = intl.formatMessage({
            id: 'EditListingLocationForm.addressRequired',
          });

          const consultationReasonRequired = validators.required(requiredlabel);
          const skillRequired = validators.required(requiredlabel);
          const issueStepsRequired = validators.required(requiredlabel);
          const materialInvolvedRequired = validators.required(requiredlabel);
          const nameRequired = validators.required(requiredlabel);
          const provienceRequired = validators.required(requiredlabel);
          const emailRequired = validators.required(requiredlabel);
          const phoneRequired = validators.required(requiredlabel);
          // const extraInfoRequired = validators.required(requiredlabel);
          const maxLength = validators.maxLength(
            maxLengthMessage,
            MAX_LENGTH
          );
          // const extraInfoLabel = intl.formatMessage({ id: "RequirmentsProjectForm.readAggredLabel" }, { termsServices });

          return (
            <Form
              className={classes}
              onSubmit={handleSubmit}
              enforcePagePreloadFor="SearchPage"
            >
              {showForm
                ? showForm == 'firstForm'
                  ? <div
                    className={css.firstSection}
                  >
                    <p className={css.requirementDescription}>Share what you can to help give the Pro as much insight into how they can assist and answer you.  Give as much detail as possible about the project.</p>
                    <div className={css.inputWrapper}>
                      <FieldSelect
                        className={css.inputSelect}
                        name="consultationReason"
                        label={descriptionLabel}
                        id="consultationReason"
                        validate={consultationReasonRequired}
                      >
                        <option disabled value="">
                          {selectLabel}
                        </option>
                        {consultationOptions.map(({ key, label }) => {
                          return (
                            <option key={key} value={key}>
                              {label}
                            </option>
                          );
                        })}
                      </FieldSelect>
                      <FieldSelect
                        name="inputSkill"
                        className={css.inputSelect}
                        placeholder={selectLabel}
                        label={skillLevelLabel}
                        id="inputSkill"
                        validate={skillRequired}
                      >
                        <option disabled value="">
                          {selectLabel}
                        </option>
                        {skillLevel.map(({ key, label }) => {
                          return (
                            <option key={key} value={key}>
                              {label}
                            </option>
                          );
                        })}
                      </FieldSelect>
                    </div>
                    <div className={css.radioButton}>
                      <p>{preferencelabel}</p>
                      <div className={css.radioRow}>
                        <FieldRadioButton
                          name="chatOver"
                          id="videoChat"
                          label="Video Chat"
                          value="Videochat"
                        />
                        <FieldRadioButton
                          name="chatOver"
                          id="phoneChat"
                          label="Phone"
                          value="Phone"
                        />
                      </div>
                    </div>
                  </div>
                  : showForm == 'secondForm' && (currentUser || guestUser)
                    ? <div
                      className={css.secondSection}
                    >
                      <p className={css.requirementDescription}>
                        <FormattedMessage id="RequirmentsProjectForm.helpfulText" />
                      </p>
                      <div >
                        <div className={css.descriptionIssue}>
                          <div className={css.descriptionHeading}>{issueDescription}</div>
                          <FieldTextInput
                            className={css.fieldTextarea}
                            type="textarea"
                            id="issues"
                            name="issues"
                            validate={validators.composeValidators(issueStepsRequired, maxLength)}
                          />
                        </div>
                        <div className={css.imagesSet}>
                          <p className={css.headingImage}><FormattedMessage id="Requirement.heading"/></p>
                          <div>

                            <AddImages
                              className={classNames({
                                [css.imagesField]: images?.length,
                                [css.fullWidthField]: !images?.length
                              })}
                              savedImageAltText={intl.formatMessage({
                                id: 'EditListingPhotosForm.savedImageAltText',
                              })}
                              images={imageUrls}
                              thumbnailClassName={css.thumbnail}
                              onRemoveImage={onRemoveImage}
                            >
                              {videoUrls && videoUrls.length ? videoUrls.map((val, index) => {
                                return <div key={index} className={css.threeToTwoWrapper}>
                                  <div className={css.aspectWrapper}>
                                    <video width="118" height="118" controls>
                                      <source src={val} type="video/mp4" />
                                    </video>
                                  </div>
                                  <RemoveImageButton onClick={() => onRemoveImage(val)} />
                                </div>
                              }) : null}
                              <DragAndDrop
                                dragdrop={css.thumbnail}
                                handleDrop={handleDrop}
                              >
                                <Field
                                  id="addImage"
                                  name="addImage"
                                  accept={ACCEPT_IMAGES}
                                  form={null}
                                  label={chooseImageText}
                                  type="file"
                                  disabled={images.length == 4 ? true : uploadImageFailed ? false : imageUploadRequested}

                                >
                                  {fieldprops => {
                                    const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                                    const { name, type } = input;
                                    const onChange = e => {
                                      const file = e.target.files[0];
                                      form.change(`addImage`, file);
                                      form.blur(`addImage`);
                                      onImageUploadHandler(e.target.files);
                                    };
                                    const inputProps = { accept, id: name, name, onChange, type };
                                    return (
                                      <div className={css.addImageWrapper}>
                                        <div className={css.aspectRatioWrapper}>
                                          {fieldDisabled ? null : (
                                            <input {...inputProps} multiple className={css.addImageInput} />
                                          )}
                                          <label htmlFor={name} className={css.addImage}>
                                            {label}
                                            {uploadInProgress && <div className={css.thumbnailLoading}>
                                              <IconSpinner />
                                            </div>}
                                          </label>
                                        </div>
                                      </div>
                                    );
                                  }}
                                </Field>
                              </DragAndDrop>
                            </AddImages>
                            {uploadImageFailed}
                          </div>
                          {/* {images.length == 4? 'You can attach up to 4 pictures or videos' : null} */}

                        </div>
                        <div className={css.listDetail}>
                          <div className={css.descriptionHeading}>{listDetailsLikeMaterials}</div>
                          <FieldTextInput
                            className={css.fieldTextarea}
                            type="textarea"
                            id="listDetail"
                            name="listDetail"
                            validate={maxLength}
                          // validate={materialInvolvedRequired}
                          />
                        </div>
                      </div>
                    </div>
                    : showForm == 'thirdForm'
                      ? <div
                        className={css.thirdSection}
                      >
                        <p className={css.requirementDescription}>
                          <FormattedMessage id="RequirmentsProjectForm.helpfulText" />
                        </p>
                        <div>
                          <FieldTextInput
                            className={css.inputBox}
                            type="textarea"
                            id="yourName"
                            name="yourName"
                            label={nameLabel}
                            validate={nameRequired}
                          // placeholder={}
                          />

                          <LocationAutocompleteInputField
                            className={css.locationAddress}
                            locationIcon={true}
                            inputClassName={css.locationAutocompleteInput}
                            iconClassName={css.locationAutocompleteInputIcon}
                            predictionsClassName={css.predictionsRoot}
                            validClassName={css.validLocation}
                            name="provience"
                            label={yourProvinceLabel}
                            placeholder={addressPlaceholder}
                            useDefaultPredictions={false}
                            format={identity}
                            valueFromForm={values.provience}
                            validate={composeValidators(
                              autocompleteSearchRequired(addressRequiredMessage),
                              autocompletePlaceSelected(addressNotRecognizedMessage)
                            )}
                          />
                        </div>
                        <div className={css.inputRow}>
                          <FieldTextInput
                            type="email"
                            name="email"
                            className={css.inputBox}
                            // placeholder={selectLabel}
                            label={emailLabel}
                            id="email"
                            validate={validators.composeValidators(emailRequired, emailValid)}
                          />

                          <FieldPhoneNumberInput
                            name="phoneNumber"
                            className={css.inputBox}
                            // placeholder={selectLabel}
                            label={phoneNumberLabel}
                            id="phone"
                            // validate={phoneRequired}
                            validate={validators.composeValidators(numberRequired, numberinLength)}
                          />

                        </div>
                        <p className={css.bottomWrapperText}>
                          <span className={css.termsText}>
                            <FormattedMessage
                              id="RequirementProfileSetupForm.termsAndCondition"
                              values={{ termsLink }}
                            />
                          </span>
                        </p>

                        <div>
                          {/* <FieldTextInput
                            className={css.inputBox}
                            type="textarea"
                            id={"extraInfo"}
                            name={"extraInfo"}
                            label={extraInfoLabel}
                            validate={extraInfoRequired}
                          // placeholder={}
                          /> */}
                        </div>
                      </div>
                      : null
                : null}

              {(showForm && showForm == 'secondForm' && !currentUser && !guestUser)
                ? <div
                  className={css.fourthSction}
                >
                  <p className={css.requirementDescription}>
                    <FormattedMessage id="RequirmentsProjectForm.helpfulText" />
                  </p>
                  <div className={css.authenticationButtons}>
                    <Button onClick={redirectTologin}>
                      <FormattedMessage id="RequirmentsProjectForm.loginSignup" />
                    </Button>
                    {/* <Button onClick={guestUserRedirect}>
                      <FormattedMessage id="RequirmentsProjectForm.checkoutGuest" />
                    </Button> */}
                  </div>
                </div>
                : null}

              <div className={css.buttonBox}>
                {showForm != 'thirdForm'
                  ? <LightBlueButton
                    onClick={() => showForm == 'firstForm' ? redirectToListing() : redirectToPrevious()}
                    className={css.blueButton}>
                    <IconCard brand="leftarrow" />
                    {showForm == 'firstForm'
                      ? 'Service Page'
                      : !currentUser
                        ? 'Edit Project'
                        : 'Previous Step'}
                  </LightBlueButton>
                  : null}

                {showForm == 'firstForm' || (showForm == 'secondForm' && !currentUser && guestUser)
                  ? <PrimaryButton
                    className={css.primaryButton}
                    inProgress={submitInProgress}
                    disabled={submitDisabled}
                    onClick={() => {
                      if (showForm == 'firstForm') {
                        form.change('redirectTo', 'secondForm');
                        form.change('diyerTimezone',  moment.tz.guess());
                      } else if (showForm == 'secondForm') {
                        form.change('redirectTo', 'thirdForm');
                      }
                      handleSubmit(values);
                    }}
                  >
                    Next Step <IconCard brand="rightarrow" />
                  </PrimaryButton>
                  : (showForm == 'secondForm' && currentUser) || (showForm == 'thirdForm' && guestUser)
                    ? <OrangeButton
                      className={css.primaryButton}
                      inProgress={submitInProgress}
                      disabled={submitDisabled}
                      onClick={() => {

                        if (this.state.modalEverClicked == false && (showForm == 'thirdForm' && guestUser)) {
                          this.setState({ openModal: true });
                          this.setState({ modalEverClicked: true })
                        } else {
                          form.change('redirectTo', 'checkoutpage');
                          handleSubmit(values);
                        }

                      }}
                    >
                      Checkout <IconCard brand="rightarrow" />
                    </OrangeButton>
                    : null}
              </div>

              <Modal
                id="Term&condition"
                isOpen={this.state.openModal}
                usePortal
                privacyModal={this.state.openModal ? true : false}
                onClose={() => this.setState({ openModal: false })}
                onManageDisableScrolling={onManageDisableScrolling}
              ><TermsOfServiceContent
                  className={css.termsModal}
                  inProgress={tosFetchInProgress}
                  error={tosFetchError}
                  data={tosAssetsData?.[camelize(TOS_ASSET_NAME)]?.data}
                  close={() => this.setState({ openModal: false })}
                  FromModal={true}
                /></Modal>
            </Form>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

RequirmentsProjectFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

RequirmentsProjectFormComponent.propTypes = {
  onManageDisableScrolling: func.isRequired,
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const RequirmentsProjectForm = compose(injectIntl)(RequirmentsProjectFormComponent);

export default RequirmentsProjectForm;
