import React from 'react';
import { Skeleton, TimeRange } from '../../components';

import css from './TranscriptDetailsForm.module.css';
import { DATE_TYPE_DATETIME } from '../../util/types';
import moment from 'moment';

const TranscriptDetailsForm = (props) => {
  const {
    currentUser,
    collectionData,
    fetchCollectionError,
    fetchCollectionInProgress,
    checkoutPage
  } = props;
//  console.log(collectionData,'collectionData',currentUser);

 const currentUserId = currentUser && currentUser.id && currentUser.id.uuid || '';
  return (
    <div className={css.transcriptItemContainer}>
      {fetchCollectionInProgress
        ? <div className={css.skeleton}>
          {Array(5).fill("1").map((n, i) => <Skeleton key={'Skeleton_' + i} width='100%' height={22} />)}
        </div>
        : collectionData && Array.isArray(collectionData) && collectionData.length
          ? collectionData
            .map((val, index) => {
              return (
                <div key={'TranscriptDetailsForm' + '_' + index} className={css.transcriptItem}>
                  <div className={css.flex}>
                   <div className={css.flex}> {val.attributes && val.attributes.attributes && val.attributes.attributes.displayStart ?<><span >{ moment(val?.attributes?.attributes?.displayStart).format('dddd')  } ,&nbsp;</span> <TimeRange
                      // className={bookingClassName}
                      startDate={val?.attributes?.attributes?.displayStart}
                      endDate={val?.attributes?.attributes?.displayEnd}
                      dateType={DATE_TYPE_DATETIME}
                      // timeZone={'Etc/UTC'}
                    div/> </>: <p> dd Month year </p>}
                    </div>

                    <p className={css.transcriptRequest}>  
                    {val?.attributes?.metadata?.id && val?.attributes?.protectedData?.id &&
                      currentUserId == val?.attributes?.metadata?.id?.uuid ? '    '+ val?.attributes?.protectedData?.attributes?.profile?.displayName + ' - DIY Transcript Request' : currentUserId == val?.attributes?.protectedData?.id?.uuid ? '     '+ val?.attributes?.metadata?.attributes?.profile?.displayName  + ' - Pro Transcript request': 'Customer - Provider'
                    }
                    </p>
                    <div
                      className={css.requestBtn}
                    onClick={() => checkoutPage(val.id?.uuid, 'steph-s-fabrication')}
                    >
                      Buy Now
                    </div>
                  </div>
                </div>
              )
            })
          : <div>No record found!!</div>
      }
    </div>
  );
}

export default TranscriptDetailsForm;