import React, { useState, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from './cropImage';
import { PrimaryButton } from '../../components';

import css from './imageCropper.module.css';

const ImageCropper = props => {
  const { inputImg, onImageUploadHandler, onRemoveImage, imageId, onCropperModalClose } = props;

  const [upImg, setUpImg] = useState(inputImg);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1.5);
  const [croppedImage, setCroppedImage] = useState(null);

  const createFile = async (url, type) => {
    if (typeof window === 'undefined') return; // make sure we are in the browser
    const response = await fetch(url);
    const data = await response.blob();
    const metadata = {
      type: type || 'image/*',
    };
    return new File([data], url, metadata);
  };

  useEffect(() => {
    if (inputImg) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      if (typeof inputImg === 'string') {
        createFile(inputImg, 'image/*').then(res => {
          reader.readAsDataURL(res);
        });
      } else {
        reader.readAsDataURL(inputImg);
      }
    }

    return () => {
      setUpImg(null);
    };
  }, [inputImg]);

  const onCropComplete = async (_, croppedAreaPixels) => {
    if (croppedAreaPixels && upImg) {
      getCroppedImg(upImg, croppedAreaPixels, 'upload-image').then(res => {
        const imgFile = new File([res], 'upload-image', {
          lastModifiedDate: new Date(),
          type: 'image/*',
        });
        setCroppedImage(imgFile);
      });
    }
  };

  const onSaveCrop = () => {
    if (croppedImage) {
      if (imageId) {
        onRemoveImage(imageId);
      }
      onImageUploadHandler(croppedImage);
      onCropperModalClose();
    }
  };

  return (
    /* need to have a parent with `position: relative` 
    to prevent cropper taking up whole page */
    <>
      <div className={css.cropContainer}>
        <Cropper
          image={upImg}
          crop={crop}
          zoom={zoom}
          aspect={1/1}
          // restrictPosition={false}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          cropShape='round'
          objectFit="contain"
        />
        <PrimaryButton className={css.cropButton} type="button" onClick={onSaveCrop}>
          {'Done'}
        </PrimaryButton>
      </div>

    </>
  );
};

export default ImageCropper;
