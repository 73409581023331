import React, { useEffect, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
const ChatAPI = require('twilio-chat');
// import Video from 'twilio-video';
// import adapter from 'webrtc-adapter';
import { Device } from '@twilio/voice-sdk';

import meetingImg from '../../assets/meeting.png';
import { FormattedMessage } from '../../util/reactIntl';

import IconVideo from './IconVideo';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';

import Room from '../../containers/MeetingPage/TwilioVideo/components/Room/Room';
import Controls from '../../containers/MeetingPage/TwilioVideo/components/Controls/Controls';
import { SymblProvider } from '../../containers/MeetingPage/TwilioVideo/components/SymblProvider';
import useRoomState from '../../containers/MeetingPage/TwilioVideo/hooks/useRoomState/useRoomState';
import useVideoContext from '../../containers/MeetingPage/TwilioVideo/hooks/useVideoContext/useVideoContext';
// import WhiteBoard from './WhiteBoard';
// import ClosedCaptions from '../../containers/MeetingPage/TwilioVideo/components/ClosedCaptions/ClosedCaptions';
import css from './TwilioVideo.module.css';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import OutsideClickHandler from '../OutsideClickHandler/OutsideClickHandler';
import FirApp from './TwilioVoice/FirApp';
import { sendGridMail } from '../../util/api';

const VideoChat = props => {
  const {
    txId,
    currentUser,
    currentRoom,
    onGetToken,
    onGetTokenByCall,
    isProvider,
    displayName,
    onInsertCollection,
    onManageDisableScrolling,
    openModal,
    currentTransaction,
    setOpenModal,
    history,
    modalForCountdown,
    closeTimer,
    onGetTokenVoice,
    oncallApiForVoice,
    updateTransactionForUserEntry,
    checkTransactionsForUserPresence,
    metadata,
    updateTransaction
  } = props;
  const { email, profile } = (currentUser && currentUser.attributes) || {}
  const { firstName = '' } = profile || {};
  const { abbreviatedName: proAbbreviateName } = (currentTransaction && currentTransaction?.provider && currentTransaction?.provider?.attributes && currentTransaction?.provider?.attributes?.profile) || {};
 
 

  const { end, start } = (currentTransaction && currentTransaction.id && currentTransaction.booking.attributes) || {};

  const { payoutTotal, protectedData, lineItems
  } = (currentTransaction && currentTransaction.id && currentTransaction.attributes) || {};
  const { pi, quantity, provienceTax, selectedPrice, chatOver, proHasBussinessAccount = false
  } = protectedData || {};
  const { amount } = payoutTotal || {};
  const tpgPlatformFees = lineItems && lineItems.length && lineItems[1] && lineItems[1].lineTotal.amount || '0';
  const ProviderPhoneNumber = currentTransaction && currentTransaction.provider && currentTransaction.provider.attributes && currentTransaction.provider.attributes.profile && currentTransaction.provider.attributes.profile.publicData.countryCode && currentTransaction.provider.attributes.profile.publicData.countryCode + currentTransaction.provider.attributes.profile.publicData.phoneNumber || '';
  const customerPhoneNumber = currentTransaction && currentTransaction.customer && currentTransaction.customer.attributes && currentTransaction.customer.attributes.profile && currentTransaction.customer.attributes.profile.publicData.countryCode && currentTransaction.customer.attributes.profile.publicData.countryCode + currentTransaction.customer.attributes.profile.publicData.phoneNumber || '';
  const ProviderId = currentTransaction && currentTransaction.provider && currentTransaction.provider.id && currentTransaction.provider.id.uuid || '';



  const [connecting, setConnecting] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  const { roomState } = useRoomState({ onInsertCollection });
  const [roomName, setRoomName] = useState(currentRoom);
  const [userName, setUserName] = useState(displayName);
  const { connect, room } = useVideoContext();

  const [hasStarted, setHasStarted] = useState(false);
  const [isStarting, setIsStarting] = useState(false);
  const [messages, setMessages] = useState([]);
  const [channel, setChannel] = useState(null);
  const [text, setText] = useState('');
  const [isChatOpened, toggleChatWindow] = useState(false);
  const [showCC, toggleCC] = useState(false);
  const [endcall, setEndCall] = useState(false);
  const [timer, setTimer] = useState(15);
  const [show15MinModal, setShow15MinModal] = useState(false);

  const updateText = e => setText(e);
  let isMeetingTimePassed = true;
  const [disableMeetingButton, setdisableMeetingButton] = useState(true);
  // console.log(currentTransaction,'currentTransaction');
  useEffect(() => {
    return () => {
      window.location = "/";
    }
  }, [])
  const stripeAccountId = (currentUser && currentUser.stripeAccount && currentUser.stripeAccount.attributes && currentUser.stripeAccount.attributes.stripeAccountId
  ) || '';

  const addDetailInMongo = (txId, stateOfRoom, isProvider, currentUser, start, end, startTime, endTimeStamp, amount, pi, quantity, stripeAccountId, provienceTax, selectedPrice, tpgPlatformFees, chatOver, isConnectedFromMobile = false) => {
    // console.log(isConnectedFromMobile, 'isConnectedFromMobileisConnectedFromMobile', isProvider, 'isProvider');

    // return false
    onInsertCollection({
      tableName: 'meetAttended',
      payload: {
        txId,
        state: stateOfRoom,
        createdAt: moment().unix(),
        transactionRole: isProvider ? 'provider' : 'customer',
        userId: currentUser && currentUser.id && currentUser.id.uuid,
        status: 'PENDING',
        meetingStartData: start,
        meetingEndData: end,
        meetingStartDataStamp: startTime,
        meetingEndDataStamp: endTimeStamp,
        payOutAmount: amount,
        paymentIntentId: pi,
        bookedQuantityTime: quantity,
        stripeAccountId: stripeAccountId,
        provienceTaxPercentage: provienceTax,
        priceOf1Hour: selectedPrice,
        tpgPlatformFees: Math.abs(tpgPlatformFees),
        meetingBy: chatOver,
        ProviderId: ProviderId,
        proHasBussinessAccount: proHasBussinessAccount
        // provinceName: provinceName
      }
    })
  }
  useEffect(() => {
    if (end && start) {
      const nowTime = moment(new Date(), 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').unix();
      const startTimeStamp = moment(start, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').unix();
      const endTimeStamp = moment(end, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').unix();

      let timeDifferenceInSeconds = (startTimeStamp - nowTime) <= 0 ? 0 : (startTimeStamp - nowTime);
      const MeetingTimeAlreadyPassed = (endTimeStamp - nowTime) >= 0 ? false : true;
      // console.log(MeetingTimeAlreadyPassed, endTimeStamp - nowTime, timeDifferenceInSeconds);
      if (!MeetingTimeAlreadyPassed) {
        setTimeout(() => {
          setdisableMeetingButton(false)
          if (((startTimeStamp + 900) <= nowTime) && (nowTime <= endTimeStamp)) {
            // it will check if someones joining the call after 15 min that they have already joined or not
            if (metadata && metadata.ProComesToMeeting && metadata.DiyerComesToMeeting) {
              setdisableMeetingButton(false);
            } else {
              setdisableMeetingButton(true);
            }
          }
        }, timeDifferenceInSeconds * 1000)
      } else {
        // setjoinMeetinglinkDisabled(true)
        setdisableMeetingButton(true)
      }
      if (startTimeStamp <= nowTime && nowTime <= endTimeStamp) {
        setdisableMeetingButton(false);
      } else {
        setdisableMeetingButton(true);
      }
      if (((startTimeStamp + 900) <= nowTime) && (nowTime <= endTimeStamp)) {
        // it will check if someones joining the call after 15 min that they have already joined or not
        if (metadata && metadata.ProComesToMeeting && metadata.DiyerComesToMeeting) {
          setdisableMeetingButton(false);
        } else {
          setdisableMeetingButton(true);
        }

      }

    }
  }, [end])

  useEffect(() => {
    if (onInsertCollection && typeof onInsertCollection == 'function' && roomState && currentUser && currentUser.id && start && (roomState == 'connected')) {
      const startTimeStamp = moment(start, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').unix();
      const nowTime = moment(new Date(), 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').unix();
      const endTimeStamp = moment(end, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').unix();
      const todayTime = nowTime; // Replace this with your first Unix timestamp
      const startTime = startTimeStamp // Replace this with your second Unix timestamp
      let timeDifferenceInSeconds = (startTime - todayTime) <= 0 ? 0 : (startTime - todayTime);
      isMeetingTimePassed = (endTimeStamp - nowTime) >= 0 ? false : true;
      if (endTimeStamp > nowTime) {
        setTimeout(() => {
          setEndCall(true);
        }, (endTimeStamp - (nowTime + 2)) * 1000)
      }
      // Convert the time difference to hours, minutes, and seconds
      const hours = Math.floor(timeDifferenceInSeconds / 3600);
      const minutes = Math.floor((timeDifferenceInSeconds % 3600) / 60);
      const seconds = timeDifferenceInSeconds % 60;
      //checking 15 min case beacuse in that case it automatically cancels the meeting
      if (endTimeStamp - startTimeStamp != 900) {
        if ((startTime + 900) > todayTime) {

          //will check whether all user comes or not at exact 15 min
          setTimeout(() => {

            checkTransactionsForUserPresence({ id: txId }).then((res) => {

              if (res && res.data && res.data.data && res.data.data.attributes && res.data.data.attributes.metadata) {
                let allData = res?.data?.data?.attributes;
                if (isProvider) {
                  if (allData?.metadata?.DiyerComesToMeeting) {
                  } else {
                    setShow15MinModal(true)
                    setTimeout(() => {
                      setEndCall(true);
                    }, 4000)
                  }
                } else {
                  if (allData?.metadata?.ProComesToMeeting) {
                  } else {
                    console.log('diyerComes');
                    if (email && firstName) {
                      let sendingData = {
                        proName: proAbbreviateName,
                        diyerName: firstName,
                        diyerEmail: email
                      }

                      const dataSend = { templateId: 'PRO_NOT_COME', sendingData }
                      sendGridMail(dataSend);
                      let sendData = {
                       id: txId,
                       data: {ProNotComeMailSend: true}
                      }
                      updateTransaction(sendData)
                    }

                    setShow15MinModal(true)
                    setTimeout(() => {
                      setEndCall(true);
                    }, 4000)
                  }
                }
              }
            })
          }, (((startTime + 900) - todayTime) * 1000))


        } else {
          console.log('here in end call because user has come after 15 min');
          // setEndCall(true);
        }
      }



      // console.log(`Time left: ${hours} hours, ${minutes} minutes, ${seconds} seconds.`, timeDifferenceInSeconds, 'timeDifferenceInSecondstimeDifferenceInSeconds');
      if (!isMeetingTimePassed) {
        let passedData = {
          id: txId,
          isProvider
        }

        setTimeout(() => {
          updateTransactionForUserEntry(passedData)
          addDetailInMongo(txId, 'connected', isProvider, currentUser, start, end, startTime, endTimeStamp, amount, pi, quantity, stripeAccountId, provienceTax, selectedPrice, tpgPlatformFees, chatOver);
        }, timeDifferenceInSeconds * 1000)



      } else {
        console.log('meeting time passed');
      }
    }
  }, [roomState]);


  useEffect(() => {
    if (modalForCountdown.open) {
      setOpenModal();
      const interval = setTimeout(() => {
        if (timer == 0) {
          // console.log('condition','looking up',interval);
          clearInterval(interval)
        } else {
          setTimer(timer - 1);
        }
      }, 1000);
    }
  }, [modalForCountdown, timer])

  const joinChannel = async channel => {
    if (channel.channelState.status !== 'joined') {
      await channel.join();
    }

    setChannel(channel);
    channel.on('messageAdded', function (message) {
      handleMessageAdded(message);
    });
  };

  const handleMessageAdded = message => {
    setMessages(messages => [...messages, message]);
  };

  const sendMessage = () => {
    if (text) {
      channel.sendMessage(String(text).trim());
      setText('');
    }
  };

  const onJoinRoom = async () => {
    try {
      setConnecting(true);
      const getToken = await onGetToken();
      const token = getToken && getToken.twilioVideoToken;
      // const voiceToken = getToken && getToken.twilioVoiceToken;
      const chatToken = getToken && getToken.twilioChatToken;
      if (roomState === 'disconnected' && !hasStarted && !isStarting) {
        setRoomName(currentRoom);
        setUserName(displayName);
        if (roomName && userName) {
          setIsStarting(true)
          connect(token)
          setIsStarting(false);
          setHasStarted(true);
          const client = await ChatAPI.Client.create(chatToken);

          client.on('tokenAboutToExpire', async () => {
            const getToken = await onGetToken();
            if (getToken && getToken.twilioChatToken) {
              client.updateToken(getToken.twilioChatToken);
            }
          });

          client.on('tokenExpired', async () => {
            const getToken = await onGetToken();
            if (getToken && getToken.twilioChatToken) {
              client.updateToken(getToken.twilioChatToken);
            }
          });

          client.on('channelJoined', async channel => {
            // getting list of all messages since this is an existing channel
            const newMessages = await channel.getMessages();
            setMessages(newMessages.items || []);
          });

          try {
            const channel = await client.getChannelByUniqueName(currentRoom);
            joinChannel(channel);
            setChannel(channel);
          } catch (err) {
            console.log(err);
            try {
              const channel = await client.createChannel({
                uniqueName: currentRoom,
                friendlyName: currentRoom,
              });

              joinChannel(channel);
            } catch {
              throw new Error('Unable to create channel, please reload this page');
            }
          }
        }
      }
      setConnecting(false);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const disconnectUpdate = () => {
    console.log('disconnected has been clicked');
    const startTimeStamp = moment(start, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').unix();
    const endTimeStamp = moment(end, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').unix();
    const nowTime = moment(new Date(), 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').unix();
    // console.log(nowTime, new Date());
    // console.log(startTimeStamp, nowTime, endTimeStamp);
    if (startTimeStamp <= nowTime && nowTime <= endTimeStamp) {
      if (onInsertCollection && typeof onInsertCollection == 'function' && roomState && currentUser && currentUser.id) {
        onInsertCollection({
          tableName: 'meetAttended',
          payload: {
            txId,
            state: 'disconnected',
            status: 'PENDING',
            createdAt: moment().unix(),
            transactionRole: isProvider ? 'provider' : 'customer',
            userId: currentUser && currentUser.id && currentUser.id.uuid,
            meetingStartData: start,
            meetingEndData: end,
            meetingStartDataStamp: startTimeStamp,
            meetingEndDataStamp: endTimeStamp,
            payOutAmount: amount,
            paymentIntentId: pi,
            bookedQuantityTime: quantity,
            stripeAccountId: stripeAccountId,
            provienceTaxPercentage: provienceTax,
            priceOf1Hour: selectedPrice,
            tpgPlatformFees: Math.abs(tpgPlatformFees),
            ProviderId: ProviderId,
            proHasBussinessAccount: proHasBussinessAccount
            // provinceName: provinceName
          }
        }).then(() => {
          room.disconnect();
          window.location = "/";
        })
      }
    } else {
      // console.log('else part of disconnecting');
      setTimeout(() => {
        room.disconnect();
        window.location = "/";
      }, [2000])

    }
  }

  const callConnected = (isConnected) => {
    // console.log(isConnected, 'isConnectedisConnected');
    const startTimeStamp = moment(start, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').unix();
    const nowTime = moment(new Date(), 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').unix();
    const endTimeStamp = moment(end, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').unix();
    const startTime = startTimeStamp // Replace this with your second Unix timestamp

    addDetailInMongo(txId, isConnected ? 'connected' : 'disconnected', false, currentUser, start, end, startTimeStamp, endTimeStamp, amount, pi, quantity, stripeAccountId, provienceTax, selectedPrice, tpgPlatformFees, chatOver);

    if (isConnected) {
      // i have called again because the above one is for customer other one is for provider
      addDetailInMongo(txId, isConnected ? 'connected' : 'disconnected', true, currentUser, start, end, startTimeStamp, endTimeStamp, amount, pi, quantity, stripeAccountId, provienceTax, selectedPrice, tpgPlatformFees, chatOver);
    }
  }




  return (
    <div className={css.videoWrapper}>
      {roomState === 'disconnected' ? (
        <div className={css.prejoiningContainer}>
          <div className={css.meetingInitialContent}>
            <h2>
              <FormattedMessage id="TwilioVideo.title" />
            </h2>
            <p>
              <FormattedMessage id="TwilioVideo.description" />
            </p>
            <div className={css.joinMeetingSec}>


              {/* <Button  onClick={onJoinRoom}>
             
                <FormattedMessage id="TwilioVideo.joinMeeting" /> <IconVideo type="VIDEO" />
              </Button>


<FirApp onGetTokenVoice={onGetTokenVoice} oncallApiForVoice={oncallApiForVoice} disableMeetingButton={false} classs={css.joinMeetingAudio} endcall={endcall} phoneNu={isProvider ? customerPhoneNumber : ProviderPhoneNumber} callConnected={() => callConnected(true)} callDisconnected={() => callConnected(false)} end={end} /> */}

              {chatOver == 'Videochat' ? <Button disabled={disableMeetingButton} onClick={onJoinRoom}>

                <FormattedMessage id="TwilioVideo.joinMeeting" /> <IconVideo type="VIDEO" />
              </Button> : isProvider ? <h3>At the time of meeting customer will call you on your given Phone number.</h3> : <FirApp onGetTokenVoice={onGetTokenVoice} oncallApiForVoice={oncallApiForVoice} disableMeetingButton={disableMeetingButton} classs={css.joinMeetingAudio} endcall={endcall} phoneNu={isProvider ? customerPhoneNumber : ProviderPhoneNumber} callConnected={() => callConnected(true)} callDisconnected={() => callConnected(false)} end={end} />}



              {/* <FirApp onGetTokenVoice={onGetTokenVoice} oncallApiForVoice={oncallApiForVoice} disableMeetingButton={disableMeetingButton} classs={css.joinMeetingAudio} endcall={endcall} phoneNu={isProvider ? customerPhoneNumber : ProviderPhoneNumber} callConnected={ () => callConnected(true)} callDisconnected={() =>callConnected(false)} end={end} /> */}
              {connecting ? (
                <div className={css.ripple}>
                  <div></div>
                  <div></div>
                </div>
              ) : null}
            </div>
          </div>

          <div className={css.meetingImage}>
            <img src={meetingImg} alt="Join Meeting" />
          </div>
        </div>
      ) : <div
        className={classNames(
          css.postjoiningContainer
        )}
      >
        <div className={classNames(css.videoRoom, {
          [css.videoChat]: isChatOpened
        })}>
          <SymblProvider roomName={roomName}>
            <div className={css.roomWrapper}>
              <Room
                txId={txId}
                messages={messages}
                displayName={displayName}
                onUpdateText={updateText}
                onSendMessage={sendMessage}
                text={text}
                toggleChatWindow={toggleChatWindow}
                isChatOpened={isChatOpened}
                showCC={showCC}
                onInsertCollection={onInsertCollection}
                transactionRole={isProvider ? 'provider' : 'customer'}
                userId={currentUser && currentUser.id && currentUser.id.uuid}
              />
            </div>
            {/* <ClosedCaptions /> */}
            <Controls
              toggleChatWindow={toggleChatWindow}
              isChatOpened={isChatOpened}
              toggleCC={toggleCC}
              showCC={showCC}
              endcall={endcall}
              history={history}
              disconnectUpdate={disconnectUpdate}
            />
          </SymblProvider>

        </div>
        <Modal
          id="meetingModal"
          isOpen={openModal == true}
          onClose={setOpenModal}
          onManageDisableScrolling={onManageDisableScrolling}
          className={css.modalReminder}
        >
          {/* <OutsideClickHandler onOutsideClick={() => openModal == true && setOpenModal()}> */}
          <p className={css.reminderText}>
            Heads up! Less than 3 minutes left on this call. Make them count!
          </p>
          {/* </OutsideClickHandler> */}
        </Modal>

        <Modal
          id="countdownMeetingModal"
          isOpen={modalForCountdown.open}
          onClose={closeTimer}
          onManageDisableScrolling={onManageDisableScrolling}
          className={css.modalReminder}
        >
          {/* <OutsideClickHandler onOutsideClick={() => modalForCountdown.open && closeTimer()}> */}
          <p className={css.reminderText}>
            There is {timer} seconds left! Let’s wrap it up.
          </p>


          {/* </OutsideClickHandler> */}
        </Modal>

        <Modal
          id="15Minute"
          isOpen={show15MinModal}
          onClose={() => { setShow15MinModal(false) }}
          onManageDisableScrolling={onManageDisableScrolling}
          className={css.modalReminder}
        >
          {/* <OutsideClickHandler onOutsideClick={() => modalForCountdown.open && closeTimer()}> */}
          <p className={css.reminderText}>
            It has been 15 minutes and {isProvider ? 'Diyer' : 'Provider'} has not come. So we are going to end this meeting soon.
          </p>


          {/* </OutsideClickHandler> */}
        </Modal>



        <div>

        </div>
      </div>}
    </div>
  );
};

export default VideoChat;
