import React,{ useRef,useEffect} from 'react';
import { compose } from 'redux';
import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { Field, Form as FinalForm } from 'react-final-form';
import { bool, func, shape, string } from 'prop-types';

import config from '../../config';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { findOptionsForSelectFilter } from '../../util/search';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';

import {
  Form,
  Button,
  FieldSelect,
  FieldMultiSelect,
  FieldPhoneNumberInput,
  FieldCurrencyInput,
  FieldTextInput,
  IconCard,
} from '../../components';

import css from './EditListingFeaturesForm.module.css';
import moment from 'moment';

const EditListingFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    formId={'EditListingTrade.Form'}
    mutators={{ ...arrayMutators }}
    keepDirtyOnReinitialize={true}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        values,
        intl,
        form,
        formId,
        updated,
        invalid,
        categories,
        fetchErrors,
        saveActionMsg,
        updateInProgress,
        ListingType
      } = formRenderProps;

      const tradesRequired = validators.required('Required fileld');

      const levellabel = intl.formatMessage({
        id: 'EditListingFeaturesPanel.Levellabel',
      });
      const pricePerHourlabel = intl.formatMessage({
        id: 'EditListingFeaturesPanel.pricePerHourlabel',
      });

      const yearYouStartedlabel = intl.formatMessage({
        id: 'EditListingFeaturesPanel.yearYouStartedlabel',
      });
      const certificatelabel = intl.formatMessage({
        id: 'EditListingFeaturesPanel.certificatelabel',
      });

      const levelRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.LevelRequired',
      });
      const pricesRequired = intl.formatMessage({
        id: 'EditListingDescriptionForm.PriceRequired',
      });
      const yearRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.YearRequired',
      });
      const languageLabelMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.languageLabelMessage',
      });
      const minimumAmountMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.minimumAmountMessage',
      });
      const levelRequired = validators.required(levelRequiredMessage);
      const priceRequired = validators.required(pricesRequired);
      const yearRequired = validators.required(yearRequiredMessage);
      const languagelabel = validators.required(languageLabelMessage);
      const minimumAmount = validators.minAmount(
        minimumAmountMessage
      );
      const sortCategories = categories && categories.sort(function (a, b) {
        const textA = a.label.toUpperCase();
        const textB = b.label.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      // const [addOneMore, setaddOneMore] = useState(1);
      // const addOneMoreTab = (() => {
        //   setaddOneMore(addOneMore + 1);
        // });
       const selectRef= useRef(null);
       const openDropdown =(e)=>{
        e.preventDefault();
        selectRef.current.props.onMenuOpen();
        selectRef.current.focus();
       }
      

      const commonPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.SelectPlaceHolder',
      });
      const yearExperienceOptions = findOptionsForSelectFilter(
        'yearExperience',
        config.custom.filters
      );
      const CompulsoryTraditionalTrades = findOptionsForSelectFilter(
        'CompulsoryTraditionalTrades',
        config.custom.filters
      );
      const NonCompulsoryTraditionalTradesOptions = findOptionsForSelectFilter(
        'NonCompulsoryTraditionalTrades',
        config.custom.filters
      );

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          <div className={css.featureForm}>
            <div className={css.subTitle}>
              <FormattedMessage id="EditListingFeaturesForm.subTitle" />
            </div>
            <div>
              <FieldMultiSelect
                isMulti={true}
                isRequired={true}
                className={css.field}
                id="trades"
                name="trades"
                fontClass={css.fontClass}
                options={sortCategories}
                label={languagelabel}
                validate={tradesRequired}
                defaultValue={values.trades || ''}
                selectRef={selectRef}
              />
            </div>

            {values.trades && values.trades.length ? <div className={css.dividerLine} /> : null}

            <div className={css.accordanceBox}>
              {values.trades && values.trades.length
                ? values.trades.map((trade, i) => {
                  // const requirementRequest = Object.keys(values).filter(
                  //   v => v.search([trade.key + '-requirement_request']) > -1

                  // );
                  const requirementRequest = Object.keys(values).filter(v => {
                    const isMatchingKey = v.search([trade.key + '-requirement_request']) > -1;
                    const valueIsEmpty = values[v] == undefined; // Check if the value is empty (falsy)

                    return isMatchingKey && !valueIsEmpty;
                  });

                  const notOfficalyCertification = Object.keys(values).filter(v => {
                    const isMatchingKey = v.search([trade.key + '-not_officaly_certification']) > -1
                    const valueIsEmpty = values[v] == undefined; // Check if the value is empty (falsy)

                    return isMatchingKey && !valueIsEmpty;
                  });
                  return (
                    <details className={css.accordion} key={trade + '-' + i} open={values.trades.length == (i + 1)}>
                      <summary className={css.accordionTitle}>{trade.label}</summary>
                      <div className={css.accordanceBoxWrap}>
                        <div className={css.rowForm}>
                          {trade.compulsory == 1 ? (
                            <FieldMultiSelect
                              isMulti={false}
                              isRequired={true}
                              fontClass={css.fontClass}
                              options={CompulsoryTraditionalTrades}
                              className={css.selectDropdown}
                              id={trade.key + '-level'}
                              name={trade.key + '-level'}
                              label={levellabel}
                              validate={levelRequired}
                            />
                          ) : (
                            <FieldMultiSelect
                              isMulti={false}
                              isRequired={true}
                              fontClass={css.fontClass}
                              options={NonCompulsoryTraditionalTradesOptions}
                              className={css.selectDropdown}
                              id={trade.key + '-level'}
                              name={trade.key + '-level'}
                              label={levellabel}
                              validate={levelRequired}
                            />
                          )}
                          <FieldCurrencyInput
                            id={trade.key + '-price'}
                            name={trade.key + '-price'}
                            className={css.rowInput}
                            label={pricePerHourlabel}
                            placeholder={pricePerHourlabel}
                            currencyConfig={config.currencyConfig}
                            validate={ListingType == 'Pro' ? validators.composeValidators(priceRequired, minimumAmount) : validators.composeValidators(priceRequired)}
                          />
                        </div>
                        <FieldMultiSelect
                          isMulti={false}
                          isRequired={true}
                          fontClass={css.fontClass}
                          options={yearExperienceOptions.slice(0).reverse().map(nctt => (
                            { key: nctt.key, value: nctt.key, label: `${nctt.label}  (${moment().diff(moment(nctt.label, 'YYYY'), 'years')} ${moment().diff(moment(nctt.label, 'YYYY'), 'years') == 0 || moment().diff(moment(nctt.label, 'YYYY'), 'years') == 1 ? 'year' : 'years'} of experience) ` }
                          ))}
                          className={css.fullInput}
                          id={trade.key + '-startedYear'}
                          name={trade.key + '-startedYear'}
                          label={yearYouStartedlabel}
                          validate={yearRequired}
                        />

                        <label className={css.requestLabel}>
                          <FormattedMessage id="EditListingFeaturesForm.listYourCertifications" />
                        </label>
                        <div className={css.spillHeighlight}>
                          {/* <label className={css.singleRequirementLabel}>
                            <FormattedMessage id="EditListingDetailsForm.singleRequirement" />
                          </label> */}
                          {requirementRequest.length ? (
                            requirementRequest.map((v, i) => (
                              <div
                                className={css.certificationInput}
                                key={v}
                              >
                                <span className={css.inputDot}>
                                  <IconCard brand="yellowdot" />
                                </span>
                                <Field name="yourField">
                                  {({ field }) => (
                                    <FieldTextInput
                                      id={v}
                                      name={v}
                                      className={css.rowInput}
                                      type="text"
                                      autoFocus
                                      placeholder={certificatelabel}
                                      onKeyDown={(e) => {
                                        if (requirementRequest.length < 5 && e.key === 'Enter' && (requirementRequest.length - 1) == i) {
                                          e.preventDefault(); // Prevent form submission
                                          const addOne = parseInt(v.split(trade.key + '-requirement_request')[1]) + 1;
                                          form.change((trade.key + '-requirement_request' + addOne), '');
                                          // Custom logic for Enter key press
                                        }
                                      }}
                                    />
                                  )}</Field>
                                {requirementRequest.length < 5 && ((requirementRequest.length - 1) == i)
                                  ? <span
                                    className={css.plusIcon}
                                    onClick={() => {
                                      const addOne = parseInt(v.split(trade.key + '-requirement_request')[1]) + 1;
                                      form.change((trade.key + '-requirement_request' + addOne), '');
                                    }}
                                  >
                                    <IconCard brand="yellowplus" />
                                  </span>
                                  : <span
                                    className={css.plusIcon}
                                    onClick={() => {

                                      form.change(v, undefined)

                                    }}
                                  >
                                    <IconCard brand="yellowcross" />
                                  </span>}
                              </div>
                            ))
                          ) : (
                            <div
                              className={css.certificationInput}
                              key={trade.key + '-requirement_request1'}
                            >
                              <span className={css.inputDot}>
                                <IconCard brand="yellowdot" />
                              </span>
                              <FieldTextInput
                                id={trade.key + '-requirement_request1'}
                                name={trade.key + '-requirement_request1'}
                                className={css.inputBox}
                                type="text"
                                placeholder={certificatelabel}
                              // label="List your individual certifications & accreditations."
                              />
                              <span
                                className={css.plusIcon}
                                onClick={() => form.change((trade.key + '-requirement_request' + 2), '')}
                              >
                                <IconCard brand="yellowplus" />
                              </span>
                            </div>
                          )}
                        </div>
                        <label className={css.requestLabel}>
                          <FormattedMessage id="EditListingFeaturesForm.notCertifications" />
                        </label>

                        {notOfficalyCertification.length ? (
                          notOfficalyCertification.map((v, i) => (
                            <div
                              className={css.certificationInput}
                              key={v}
                            >
                              <span className={css.inputDot}>
                                <IconCard brand="yellowdot" />
                              </span>
                              <Field name="yourField">
                                {({ field }) => (
                                  <FieldTextInput
                                    id={v}
                                    name={v}
                                    className={css.rowInput}
                                    type="text"
                                    autoFocus
                                    placeholder={certificatelabel}
                                    onKeyDown={(e) => {
                                      if (notOfficalyCertification.length < 5 && e.key === 'Enter' && (notOfficalyCertification.length - 1) == i) {
                                        e.preventDefault(); // Prevent form submission
                                        const addOne = parseInt(v.split(trade.key + '-not_officaly_certification')[1]) + 1;
                                        form.change((trade.key + '-not_officaly_certification' + addOne), '');
                                        // Custom logic for Enter key press
                                      }
                                    }}
                                  // label="List your individual certifications & accreditations."
                                  />
                                )}</Field>

                              {notOfficalyCertification.length < 5 && ((notOfficalyCertification.length - 1) == i)
                                ? <span
                                  className={css.plusIcon}
                                  onClick={() => {
                                    const addOne = parseInt(v.split(trade.key + '-not_officaly_certification')[1]) + 1;
                                    form.change((trade.key + '-not_officaly_certification' + addOne), '');
                                  }}
                                >
                                  <IconCard brand="yellowplus" />
                                </span>
                                : <span
                                  className={css.plusIcon}
                                  onClick={() => form.change(v, undefined)}
                                >
                                  <IconCard brand="yellowcross" />
                                </span>}
                              {/* <span
                                className={css.plusIcon}
                                onClick={() =>
                                  form.change(
                                    trade.key +
                                    '-not_officaly_certification' +
                                    (parseInt(notOfficalyCertification.length) + 1),
                                    ''
                                  )
                                }
                              >
                                <IconCard brand="yellowplus" />
                              </span> */}
                            </div>
                          ))
                        ) : (
                          <div
                            className={css.certificationInput}
                            key={trade.key + '-not_officaly_certification1'}
                          >
                            <span className={css.inputDot}>
                              <IconCard brand="yellowdot" />
                            </span>
                            <FieldTextInput
                              id={trade.key + '-not_officaly_certification1'}
                              name={trade.key + '-not_officaly_certification1'}
                              className={css.rowInput}
                              type="text"
                              placeholder={certificatelabel}
                            // label="List your individual certifications & accreditations."
                            />
                            <span
                              className={css.plusIcon}
                              onClick={() =>
                                form.change(trade.key + '-not_officaly_certification' + 2, '')
                              }
                            >
                              <IconCard brand="yellowplus" />
                            </span>
                          </div>
                        )}
                      </div>
                    </details>
                  );
                })
                : null}
              {values.trades && values.trades.length ? <div>
                <Button onClick={openDropdown} className={css.addAnotherTradeBtn}><FormattedMessage id="EditListingFeaturesForm.addAnotherTradeBtnText" /></Button>
              </div> : null}
            </div>
          </div>

          <Button
            className={css.submitButton}
            type="submit"
            ready={submitReady}
            disabled={submitDisabled}
            inProgress={submitInProgress}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  intl: intlShape.isRequired,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default compose(injectIntl)(EditListingFeaturesForm);
