import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const LineItemBasePriceMaybe = props => {
  const { transaction, unitType, intl } = props;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const translationKey = isNightly
    ? 'BookingBreakdown.baseUnitNight'
    : isDaily
      ? 'BookingBreakdown.baseUnitDay'
      : 'BookingBreakdown.baseUnitHours';



  if (transaction.attributes.protectedData && transaction.attributes.protectedData.Customer_paid && transaction.attributes.lineItems && transaction.attributes.lineItems.length) {
    const customerLineItem = transaction.attributes.protectedData.Customer_paid;
    const providerLineItem = transaction.attributes.protectedData.Provider_received;
    const { proHasBussinessAccount } = transaction.attributes.protectedData || false;


    Object.assign(transaction.attributes.lineItems[0].lineTotal, { amount: customerLineItem.sale_tax ? customerLineItem.sale_tax : '100' })
    Object.assign(transaction.attributes.lineItems[1].lineTotal, { amount: providerLineItem.Tpg_flat_fees ? (-providerLineItem.Tpg_flat_fees * 100) : '100' })
    if (proHasBussinessAccount) {
      Object.assign(transaction.attributes.lineItems[2].lineTotal, { amount: customerLineItem.taxMoney ? customerLineItem.taxMoney : '100' })
      Object.assign(transaction.attributes.lineItems[3].lineTotal, { amount: providerLineItem.Tpg_flat_fees ? (-15 * providerLineItem.Tpg_flat_fees) : '100' })
    } else {
      Object.assign(transaction.attributes.lineItems[2].lineTotal, { amount: providerLineItem.Tpg_flat_fees ? (-15 * providerLineItem.Tpg_flat_fees) : '100' })
    }

    Object.assign(transaction.attributes.payinTotal, { amount: customerLineItem.Total ? customerLineItem.Total : '100' })
    Object.assign(transaction.attributes.payoutTotal, {
      amount: providerLineItem.Total ? providerLineItem.Total
        : '100'
    })

  }

  // Find correct line-item for given unitType prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/units', or 'line-item/time'
  // These are defined in '../../util/types';
  const unitPurchase = transaction.attributes.lineItems.find(
    item => item.code === unitType && !item.reversal
  );

  const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
  const unitPrice = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;
  const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;
  let hours = parseInt(quantity) > 0
    ? parseInt(quantity) > 1
      ? (parseInt(quantity) + ' hours')
      : (parseInt(quantity) + ' hour')
    : '';
  let minutes = ((parseInt(quantity * 100) % 100 == 0)
    ? 0
    : (parseInt(quantity * 100) % 100 <= 25)
      ? 15 + " minutes"
      : (parseInt(quantity * 100) % 100 <= 50)
        ? 30 + " minutes"
        : 45 + " minutes");

  if (transaction.attributes.protectedData?.Customer_paid) {
    minutes = transaction.attributes.protectedData?.Customer_paid.unitTalkedtime + " minutes";
    hours = ''
  }
  return quantity && total ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id={translationKey} values={{ unitPrice, time: (hours ? (hours + " ") : "") + (minutes ? (minutes) : "") }} />
      </span>
      <span className={css.itemValue}>{total}</span>
    </div>
  ) : null;
};

LineItemBasePriceMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;
