import React, { useEffect, useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
} from '../../util/validators';
import { obfuscatedCoordinates } from '../../util/maps';
import { Form, LocationAutocompleteInputField, Button, FieldTextInput, SliderTiles, Map } from '../../components';

import css from './EditListingLocationForm.module.css';

const identity = v => v;
const { LatLng } = sdkTypes;

export const EditListingLocationFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        initialValues,
        fetchErrors,
        values,
        // address = "New York",
        // geolocation = ,
      } = formRenderProps;

      const [rangeValue, setRangeValue] = useState(initialValues && initialValues.rangeValue && Array.isArray(initialValues.rangeValue) ? initialValues.rangeValue : [50]);

      const [address, setAddress] = useState("Canada");
      const [geolocation, setGeolocation] = useState(new LatLng(41.917576401307, -74.7008392055224))

      useEffect(() => {
        setAddress(values?.location?.selectedPlace?.address);
        if (values?.location?.selectedPlace?.origin?.lat) {
          setGeolocation(new LatLng(values?.location?.selectedPlace?.origin?.lat, values?.location?.selectedPlace?.origin?.lng))
        }
      }, [values.location]);

      const mapProps = config.maps.fuzzy.enabled
        ? {
          radius: (rangeValue[0] * 1000),
          obfuscatedCenter: obfuscatedCoordinates(geolocation),
        }
        : { address, center: geolocation };
      values.rangeValue = rangeValue

      const postalCodeMessage = intl.formatMessage({ id: 'EditListingLocationForm.postalCodeMessage' });
      const postalCodePlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.postalCodePlaceholderMessage',
      });
      const addressRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressRequired',
      });
      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressNotRecognized',
      });

      const optionalText = intl.formatMessage({
        id: 'EditListingLocationForm.optionalText',
      });

      const buildingMessage = intl.formatMessage(
        { id: 'EditListingLocationForm.building' },
        { optionalText: optionalText }
      );
      const buildingPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.buildingPlaceholder',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          <div className={css.locationMainWrapper}>
            <div className={css.subTitleName}>
              <FormattedMessage id="EditListingLocationForm.addHintHere" />
            </div>
            <LocationAutocompleteInputField
              className={css.locationAddress}
              inputClassName={css.locationAutocompleteInput}
              iconClassName={css.locationAutocompleteInputIcon}
              predictionsClassName={css.predictionsRoot}
              validClassName={css.validLocation}
              autoFocus
              name="location"
              label={postalCodeMessage}
              placeholder={postalCodePlaceholderMessage}
              useDefaultPredictions={false}
              format={identity}
              valueFromForm={values.location}
              validate={composeValidators(
                autocompleteSearchRequired(addressRequiredMessage),
                autocompletePlaceSelected(addressNotRecognizedMessage)
              )}
            />

            <div>
              {/* MAP HERE */}
              <div className={css.map}>
                <Map {...mapProps} />
              </div>
              {/* <div className={css.rangeSliderBox}>
                <SliderTiles
                  rangeValue={rangeValue}
                  setRangeValue={setRangeValue}
                />
              </div> */}
              <div className={css.mapButtonsGroup}>
                <Button onClick={(e)=> { setRangeValue([50]), e.preventDefault() }} className={classNames(rangeValue[0] == 50 && css.active)}>
                  50KM
                </Button>
                <Button onClick={(e)=>{setRangeValue([100]), e.preventDefault()}} className={classNames(rangeValue[0] == 100 && css.active)}>
                  100KM
                </Button>
                <Button onClick={(e)=>{setRangeValue([150]), e.preventDefault()}} className={classNames(rangeValue[0] == 150 && css.active)}>
                  150KM
                </Button>
              </div>
            </div>

          </div>

          <Button
            className={css.submitButton}
            type="submit"
            onSubmit={(e) => onSubmit({ ...initialValues, ...e, rangeValue })}
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingLocationFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
};

EditListingLocationFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingLocationFormComponent);
