import React, { Component } from 'react';
import { array, string, func } from 'prop-types';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { richText } from '../../util/richText';
import { formatMoney } from '../../util/currency';
import { createSlug } from '../../util/urlHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';

import { NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';

const { Money } = sdkTypes;
const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    hit,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const {
    objectID,
    title,
    price,
    trades = [],
    businessName,
    uploadedImagesUrls,
  } = hit;

  const slug = createSlug(title);
  const params = {
    id: objectID,
    slug,
  };

  let { url } = (uploadedImagesUrls && uploadedImagesUrls.length && Array.isArray(uploadedImagesUrls) && uploadedImagesUrls[0]) || {};
  if (!url) {
    if (trades && trades.length) {
      if(trades[0].defaultImg){
        url = trades[0].defaultImg
      }
    }
  }
  const priceArray = []
  if(trades && trades.length){
    trades.map((val)=>{
    priceArray.push(val.inputValues.price)
    })
  }
  let maxPrice , minPrice='';
  if(priceArray && priceArray.length){
    maxPrice = Math.max(...priceArray)
    minPrice = Math.min(...priceArray)
  }

  const { formattedPrice } = (price && price.amount && priceData(new Money((price.amount)/4, price.currency), intl)) || {};

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
      ? 'ListingCard.perDay'
      : 'ListingCard.perUnit';

  return (
    <NamedLink className={classes} name="ListingPage" params={params} key={objectID}>
      <div className={css.threeToTwoWrapper} >
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={businessName}
            url={url}
          />
        </div>
      </div>
      <div className={css.info}>

        <div className={css.price}>
          <div className={css.priceValue} >
         {(priceArray && priceArray.length > 1 && minPrice != maxPrice)  ?  `$${  minPrice/400}-${maxPrice/400} ${price.currency}`   : formattedPrice} 
          </div>
          <div className={css.perUnit}>
            <FormattedMessage id={unitTranslationKey} />
          </div>
        </div>        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>

          {trades && trades.length
            ? <div className={css.title}>
              {trades.map((val, index) => <React.Fragment key={val.label}>
                <span>{val.label}</span>
                {(trades.length - 1 == index) ? null : <span > | </span>}
              </React.Fragment>)}
            </div>
            : null}
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  // listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
