import React, { useEffect, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import routeConfiguration from '../../routeConfiguration';
import { FormattedMessage } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import { GoogleAPI, OrangeButton, PrimaryButton, SecondaryButton } from '../../components';

import css from './TransactionPanel.module.css';
// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const SaleActionButtonsMaybe = props => {
  const {
    className,
    rootClassName,
    showButtons,
    acceptInProgress,
    declineInProgress,
    acceptSaleError,
    declineSaleError,
    onAcceptSale,
    onDeclineSale,
    history,
    currentProvider,
    currentCustomer,
    currentListing,
    currentTransaction,
    showMeetingButton,
    updateTransactionMeta,
    transactionRole
  } = props;

  
  let isMeetingButtonDisabled;
  const acceptErrorMessage = acceptSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.acceptSaleFailed" />
    </p>
  ) : null;
  const declineErrorMessage = declineSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.declineSaleFailed" />
    </p>
  ) : null;
  const classes = classNames(rootClassName || css.actionButtons, className);
  const { start, end } = (currentTransaction && currentTransaction.id && currentTransaction.booking.attributes) || {};
  const metadata = (currentTransaction && currentTransaction.id && currentTransaction.attributes && currentTransaction.attributes.metadata);
  
  const nowTime = moment(new Date(), 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').unix();
  const endTimeStamp = moment(end, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').unix();
  const isMeetingTimePassed = (endTimeStamp - nowTime) >= 0 ? false : true;
  const startTimeStamp = moment(start, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').unix();
  const meetingStartTimePassed = (startTimeStamp - nowTime) >= 0 ? false : true;
  const buttonsDisabled = acceptInProgress || declineInProgress || isMeetingTimePassed;

  let timeDifferenceInSeconds = (startTimeStamp - nowTime) <= 0 ? 0 : (startTimeStamp - nowTime);
  const [joinMeetinglinkDisabled, setjoinMeetinglinkDisabled] = useState(true);
  let alreadyEventAdded = false;
  if (transactionRole == 'customer') {
    alreadyEventAdded = metadata && metadata.calenderAddedinCustomer || false;
  } else {
    alreadyEventAdded = metadata && metadata.calenderAddedinProvider || false;
  }
  useEffect(() => {
    if (!isMeetingTimePassed) {
      setTimeout(() => {
        setjoinMeetinglinkDisabled(false)
      }, timeDifferenceInSeconds * 1000)
    } else {
      setjoinMeetinglinkDisabled(true)
    }
  })

  if (moment().format('YYYY-MM-DD') == moment(start).format('YYYY-MM-DD')) {
    isMeetingButtonDisabled = false;
  } else {
    isMeetingButtonDisabled = true;
  }

  return showButtons ? (
    <div className={classes}>
      <div className={css.actionErrors}>
        {acceptErrorMessage}
        {declineErrorMessage}
      </div>
      <div className={css.actionButtonWrapper}>
        <SecondaryButton
          inProgress={declineInProgress}
          disabled={buttonsDisabled}
          onClick={() => onDeclineSale("DeclineBooking")}
        >
          <FormattedMessage id="TransactionPanel.declineButton" />
        </SecondaryButton>
        <OrangeButton
          inProgress={acceptInProgress}
          disabled={buttonsDisabled}
          onClick={onAcceptSale}
        >
          <FormattedMessage id="TransactionPanel.acceptBooking" />
        </OrangeButton>
      </div>
    </div>
  ) : showMeetingButton ?
    (<div className={classes}>
      <div className={classNames(css.actionButtonWrapper, css.actionButtonColumn)}>
        <GoogleAPI
          location={currentListing.attributes.publicData.location? currentListing.attributes.publicData.location.address: 'Canada'}
          description={process.env.REACT_APP_ROOT_URL + '/meeting/' + currentTransaction.id.uuid}
          startTime={start}
          endTime={end}
          meetingStartTimePassed={meetingStartTimePassed}
          timeZone={currentTransaction.attributes.protectedData.timeZone ? currentTransaction.attributes.protectedData.timeZone : moment.tz.guess()}
          customerEmail={currentTransaction.attributes.protectedData.customerEmail}
          providerEmail={currentTransaction.attributes.protectedData.providerEmail}
          customerName={currentCustomer.attributes.profile.displayName}
          providerName={currentProvider.attributes.profile.displayName}
          conferenceData={{ meetingURL: process.env.REACT_APP }}
          transactionId={currentTransaction.id}
          updateTransactionMeta={updateTransactionMeta}
          alreadyEventAdded={alreadyEventAdded}
          transactionRole={transactionRole}
        />
        <PrimaryButton
          // disabled={isMeetingButtonDisabled}    // asked to remove by Aaron
          onClick={() =>
            history.push(
              createResourceLocatorString(
                'MeetingPage',
                routeConfiguration(),
                { id: currentTransaction.id.uuid },
                {}
              )
            )
          }
          disabled={joinMeetinglinkDisabled}
          className={css.joinLinkBtn}
        >
          <FormattedMessage id="TransactionPanel.joinMeetButton" />
        </PrimaryButton>

        <OrangeButton
          disabled={meetingStartTimePassed}
          onClick={() => { if (!meetingStartTimePassed) onDeclineSale("CancelBooking") }}
        >
          Cancel Meeting
        </OrangeButton>
      </div>
    </div>
    ) : null;
};

export default SaleActionButtonsMaybe;
