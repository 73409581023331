import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingPhotosForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';

import css from './EditListingPhotosPanel.module.css';

class EditListingPhotosPanel extends Component {

  constructor(props) {
    super(props);
    // this.state = {
    //   savedImageinAlgolia: props.listing.attributes.publicData.uploadedImagesUrls,
    // };

    const { publicData } = props.listing.attributes || {};
    const uploadedImagesUrls = publicData ? publicData.uploadedImagesUrls : [];
    // console.log(uploadedImagesUrls,'uploadedImagesUrls');
    this.state = {
      savedImageinAlgolia: uploadedImagesUrls,
    };
  }

  componentDidUpdate(prevProps) {
      if (prevProps.listing.attributes.publicData.uploadedImagesUrls !== this.props.listing.attributes.publicData.uploadedImagesUrls) {

        // console.log('ram');
      this.setState({
        savedImageinAlgolia: this.props.listing.attributes.publicData.uploadedImagesUrls,
      });
    }
  }

  render() {
    const {
      className,
      rootClassName,
      errors,
      disabled,
      ready,
      images,
      listing,
      onImageUpload,
      onUpdateImageOrder,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      onChange,
      onSubmit,
      onRemoveImage,
    } = this.props;

    const rootClass = rootClassName || css.root;
    const classes = classNames(rootClass, className);
    const currentListing = ensureOwnListing(listing);
    const { publicData } = currentListing.attributes || {};
    const onRemove = (e) => {
      onRemoveImage(e);

      let removedImageData = this.state.savedImageinAlgolia.filter((val) => {
        return val.id != e.uuid
      })
      this.setState({ savedImageinAlgolia: removedImageData })
     
    }
    const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingPhotosPanel.title"
      // values={{
      //   listingTitle: (
      //     <ListingLink listing={listing}>
      //       <FormattedMessage id="EditListingPhotosPanel.listingTitle" />
      //     </ListingLink>
      //   ),
      // }}
      />
    ) : (
      <FormattedMessage id="EditListingPhotosPanel.createListingTitle" />
    );

    const panelSubTitle = <FormattedMessage id="EditListingPhotosPanel.AddPhotos" />;

    return (
      <div className={classes}>
        <h1 className={css.title}>{panelTitle}</h1>
        {panelSubTitle
          ? <h3 className={css.subTitle}>{panelSubTitle}</h3>
          : null}
        <EditListingPhotosForm
          className={css.form}
          disabled={disabled}
          ready={ready}
          fetchErrors={errors}
          initialValues={{ images, ...publicData }}
          images={images}
          onImageUpload={onImageUpload}
          onSubmit={values => {
            const { addImage, uploadedImagesUrls = [], ...updateValues } = values;
            Object.assign(updateValues, { publicData: { uploadedImagesUrls } });

            if (images && images.length) {
              updateValues.publicData.uploadedImagesUrls = updateValues.publicData.uploadedImagesUrls.filter(i => images.findIndex(ri => ri.id && ri.id.uuid && (ri.id.uuid == i.id)) > -1);
              if (publicData.uploadedImagesUrls && (publicData.uploadedImagesUrls.length)) {
                updateValues.publicData.uploadedImagesUrls.push(...this.state.savedImageinAlgolia);
              }
              else{
                // console.log(images,'images',publicData.uploadedImagesUrls,this.state.savedImageinAlgolia);
                // console.log(this.state.savedImageinAlgolia,'this.state.savedImageinAlgolia');
                if(this.state.savedImageinAlgolia && this.state.savedImageinAlgolia.length){
                  uploadedImagesUrls.push(...this.state.savedImageinAlgolia);
                }
              }
              updateValues.publicData.uploadedImagesUrls.push(...images.filter(ri => ri.imageId).map(ri => ({ id: ri.imageId.uuid, url: ri.secure_url })));
            }
            if (updateValues.publicData.uploadedImagesUrls.length) {
              localStorage.setItem('cloudinaryimage', JSON.stringify(updateValues.publicData.uploadedImagesUrls))
            }
            if (images && images.length && !updateValues.publicData.uploadedImagesUrls.length) {
              updateValues.publicData.uploadedImagesUrls = JSON.parse(localStorage.getItem('cloudinaryimage'));
            }
            onSubmit(updateValues);
          }}
          onChange={onChange}
          onUpdateImageOrder={onUpdateImageOrder}
          onRemoveImage={(e) => { onRemove(e) }}
          saveActionMsg={submitButtonText}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
        />
      </div>
    );
  }
}

EditListingPhotosPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  images: [],
  listing: null,
};

EditListingPhotosPanel.propTypes = {
  className: string,
  rootClassName: string,
  errors: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  images: array,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
};

export default EditListingPhotosPanel;
