import React, { Component } from 'react';
import { bool, func, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import routeConfiguration from '../../routeConfiguration';
import { camelize } from '../../util/string';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { Modal } from '../../components';
import { ProfileSetupForm } from '../../forms';

import EmailReminder from './EmailReminder';
import StripeAccountReminder from './StripeAccountReminder';
import {
  TOS_ASSET_NAME,
  TermsOfServiceContent,
} from '../../containers/TermsOfServicePage/TermsOfServicePage';

import css from './ModalMissingInformation.module.css';
import { sendGridMail } from '../../util/api';

const MISSING_INFORMATION_MODAL_WHITELIST = [
  'LoginPage',
  'SignupPage',
  'ContactDetailsPage',
  'EmailVerificationPage',
  'PasswordResetPage',
  'StripePayoutPage',
];

const EMAIL_VERIFICATION = 'EMAIL_VERIFICATION';
const STRIPE_ACCOUNT = 'STRIPE_ACCOUNT';
const PROFILE_SETUP = 'PROFILE_SETUP';

class ModalMissingInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMissingInformationReminder: null,
      hasSeenMissingInformationReminder: false,
      tosModalOpen: false,
      submittedValue: {},
      scrollPosition: 0, // Initialize the scroll position to the top
      buttonVisible: true, // Initially, the button is visible
    };
    this.containerRef = React.createRef();
    this.handleMissingInformationReminder = this.handleMissingInformationReminder.bind(this);
  }

  componentDidUpdate() {
    const { currentUser, currentUserHasListings, currentUserHasOrders, location } = this.props;
    const user = ensureCurrentUser(currentUser);
    this.handleMissingInformationReminder(
      user,
      currentUserHasListings,
      currentUserHasOrders,
      location
    );
  }

  componentDidMount() {
    // Add a scroll event listener to the container
    this.containerRef?.current?.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    // Remove the scroll event listener when the component is unmounted
    this.containerRef?.current?.removeEventListener('scroll', this.handleScroll);
  }

  handleMissingInformationReminder(
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    newLocation
  ) {
    const routes = routeConfiguration();
    const whitelistedPaths = MISSING_INFORMATION_MODAL_WHITELIST.map(page =>
      pathByRouteName(page, routes)
    );

    // Is the current page whitelisted?
    const isPageWhitelisted = whitelistedPaths.includes(newLocation.pathname);

    // Track if path changes inside Page level component
    const pathChanged = newLocation.pathname !== this.props.location.pathname;
    const notRemindedYet =
      !this.state.showMissingInformationReminder && !this.state.hasSeenMissingInformationReminder;

    // Is the reminder already shown on current page
    const showOnPathChange = notRemindedYet || pathChanged;

    if (!isPageWhitelisted && showOnPathChange) {
      // Emails are sent when order is initiated
      // Customer is likely to get email soon when she books something
      // Provider email should work - she should get an email when someone books a listing
      const hasOrders = currentUserHasOrders === true;
      const hasListingsOrOrders = currentUserHasListings || hasOrders;

      const emailUnverified = !!currentUser.id && !currentUser.attributes.emailVerified;
      const emailVerificationNeeded = emailUnverified; //hasListingsOrOrders

      const stripeAccountMissing = !!currentUser.id && !currentUser.attributes.stripeConnected;
      const stripeAccountNeeded = currentUserHasListings && stripeAccountMissing;
      const showProfileSetupModal = !!currentUser.id && !currentUser.attributes.profile.publicData.ListingType;

      // Show reminder
      if (emailVerificationNeeded) {
        this.setState({ showMissingInformationReminder: EMAIL_VERIFICATION });
      } else if (showProfileSetupModal) {
        this.setState({ showMissingInformationReminder: PROFILE_SETUP });
        // } else if (stripeAccountNeeded) {
        //   this.setState({ showMissingInformationReminder: STRIPE_ACCOUNT });
      }
    }
  }



  handleScroll = () => {
    const container = this.containerRef.current;
    // Calculate the maximum scroll position (scrollHeight - clientHeight)
    const maxScroll = container.scrollHeight - container.clientHeight;

    // Check if the user has scrolled to the bottom
    if (container.scrollTop === maxScroll) {
      this.setState({ buttonVisible: false }); // Hide the button
    } else {
      this.setState({ buttonVisible: true }); // Show the button
    }
  };


  scrollToBottom = () => {
    const container = this.containerRef.current;
    container.scrollTop = container.scrollHeight;
    this.setState({ buttonVisible: false }); // Hide the button
  };

  render() {
    const {
      rootClassName,
      className,
      onLogout,
      containerClassName,
      currentUser,
      sendVerificationEmailInProgress,
      updateInProgress,
      sendVerificationEmailError,
      onManageDisableScrolling,
      onResendVerificationEmail,
      onUpdateProfile,
      tosAssetsData,
      tosFetchInProgress,
      tosFetchError,
      onImageUpload,
      image,
      history,
    } = this.props;

    const user = ensureCurrentUser(currentUser);
    const classes = classNames(rootClassName || css.root, className);

    const profileImageId = user.profileImage ? user.profileImage.id : null;
    const profileImage = image || { imageId: profileImageId };

    let content = null;
    const uploadedImage = image;

    const closeModal = () => {
      if (this.state.tosModalOpen) {
        this.setState({
          tosModalOpen: false,
        });
        setTimeout(() => {
          this.setState({
            showMissingInformationReminder: PROFILE_SETUP
          });
          this.setState({ buttonVisible: true })
        }, 200);
      } else {
        this.setState({
          showMissingInformationReminder: null,
          hasSeenMissingInformationReminder: true,
        });
        // if ([EMAIL_VERIFICATION, PROFILE_SETUP].includes(this.state.showMissingInformationReminder)) {
        //   onLogout();
        // }
      }
    }

    const currentUserLoaded = user && user.id;
    if (currentUserLoaded) {
      if (this.state.showMissingInformationReminder === EMAIL_VERIFICATION) {
        content = (
          <EmailReminder
            className={classes}
            user={user}
            onResendVerificationEmail={onResendVerificationEmail}
            sendVerificationEmailInProgress={sendVerificationEmailInProgress}
            sendVerificationEmailError={sendVerificationEmailError}
          />
        );
      } else if (this.state.showMissingInformationReminder == PROFILE_SETUP) {
        content = (
          <ProfileSetupForm
            className={css.signupForm}
            onImageUpload={onImageUpload}
            inProgress={updateInProgress}
            profileImage={profileImage}
            pageName={"TopbarContainer"}
            users={user}
            onManageDisableScrolling={onManageDisableScrolling}
            initialValues={{
              ListingType: 'Pro'
            }}
            onSubmit={(values) => {
              if (!Object.keys(this.state.submittedValue).length) {
                this.setState({ tosModalOpen: true, submittedValue: values, showMissingInformationReminder: null });
                closeModal();
              } else {
                const { location, ListingType, gender, phoneNumber, pronouns, submitTo, countryCode, ...rest } = values;
                const {
                  selectedPlace: { address, origin },
                } = location;
                const userData = {
                  protectedData: {
                    phoneNumber,
                  },
                  publicData: {
                    ListingType,
                    gender,
                    pronouns,
                    phoneNumber,
                    countryCode
                  },
                  profileImageId: uploadedImage && uploadedImage.imageId && uploadedImage.file
                    ? uploadedImage.imageId : null
                };
                const listingData = {
                  title: currentUser.attributes.profile.displayName,
                  geolocation: origin,
                  publicData: {
                    location: { address },
                    gender,
                    pronouns,
                    phoneNumber,
                    ListingType,
                    countryCode,
                    ...rest
                  }
                };
                onUpdateProfile(userData, listingData)
                  .then(() => {
                    const dataSend = { templateId: 'PROFILE_DONE', id: user.id.uuid ? user.id.uuid : '' }
                    if(ListingType =='Pro'){
                      sendGridMail(dataSend);
                    }
                    this.setState({
                      showMissingInformationReminder: null,
                      hasSeenMissingInformationReminder: true,
                    });
                    if (submitTo == 'myServices') {
                      return history.push(createResourceLocatorString(
                        'NewListingPage',
                        routeConfiguration(),
                        {}
                      ));
                    }
                    return history.push(createResourceLocatorString(
                      'LandingPage',
                      routeConfiguration(),
                      {}
                    ));
                  });
              }
            }}
            submittedValue={this.state.submittedValue}
            onOpenTermsOfService={(values) => {
              this.setState({ tosModalOpen: true, submittedValue: values, showMissingInformationReminder: null });
              closeModal();
            }}
          />
        );
      } else if (this.state.showMissingInformationReminder === STRIPE_ACCOUNT) {
        content = <StripeAccountReminder className={classes} />;
      }
    }

    const closeButtonMessage = (
      <FormattedMessage id="ModalMissingInformation.closeVerifyEmailReminder" />
    );

    return (
      <Modal
        id="MissingInformationReminder.modal"
        containerClassName={containerClassName}
        privacyModal={this.state.tosModalOpen ? true : false}
        isOpen={(!!this.state.showMissingInformationReminder) || this.state.tosModalOpen}
        onClose={() => closeModal()}
        showCloseButton={this.state.tosModalOpen
          ? true
          : this.state.showMissingInformationReminder != PROFILE_SETUP && this.state.showMissingInformationReminder != EMAIL_VERIFICATION}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
        closeButtonMessage={closeButtonMessage}
        className={css.termsModal}
      >
        {this.state.tosModalOpen
          ? <div className={classNames(css.termsMainWrapper, this.state.buttonVisible && css.blackShadow)}>

            <div className={css.termsWrapper} ref={this.containerRef}>
              <TermsOfServiceContent
                className={css.termsModal}
                inProgress={tosFetchInProgress}
                error={tosFetchError}
                data={tosAssetsData?.[camelize(TOS_ASSET_NAME)]?.data}
                close={() => closeModal()}
                FromModal={true}
              />
              {this.state.buttonVisible && (
                <span onClick={this.scrollToBottom} className={css.buttonScroll}><span></span>Scroll Down</span>
              )}
            </div>
          </div>
          : content}
      </Modal>
    );
  }
}

ModalMissingInformation.defaultProps = {
  className: null,
  rootClassName: null,
  currentUser: null,
};

ModalMissingInformation.propTypes = {
  id: string.isRequired,
  className: string,
  rootClassName: string,
  containerClassName: string,

  currentUser: propTypes.currentUser,
  onManageDisableScrolling: func.isRequired,
  sendVerificationEmailError: propTypes.error,
  sendVerificationEmailInProgress: bool.isRequired,
};

ModalMissingInformation.displayName = 'ModalMissingInformation';

export default ModalMissingInformation;
