import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import config from '../../../../config';

import CallEnd from '@material-ui/icons/CallEnd';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

import useSymblContext from "../../../hooks/useSymblContext/useSymblContext";
import useRoomState from '../../../hooks/useRoomState/useRoomState';
import { createResourceLocatorString } from '../../../../../../util/routes';
import routeConfiguration from '../../../../../../routeConfiguration';
import { NamedRedirect } from '../../../../../../components';

const useStyles = makeStyles((theme) =>
    createStyles({
        fab: {
            margin: theme.spacing(1),
        },
    })
);
// const { roomState } = useRoomState();

export default function EndCallButton(props) {

    const { disconnectUpdate, endcall, history } = props;
    const classes = useStyles();
    const { room } = useVideoContext();
    const { stopSymblWebSocketApi, isConnected } = useSymblContext();
    const disconnect = async (room, stopSymblWebSocketApi, isConnected) => {
        if (isConnected) {
            await stopSymblWebSocketApi(() => {
                if (!window.location.origin.includes('twil.io')) {
                    // room.disconnect();
                    disconnectUpdate();

                }
            });
        } else {
            if (!window.location.origin.includes('twil.io')) {
                // console.log('out');
                // room.disconnect();
                disconnectUpdate();
            }
        }
    };
    
    if (endcall) {
        console.log('automatic call is declined');
        disconnect(room, stopSymblWebSocketApi, isConnected)
    }

    return (
        <Tooltip title={'End Call'} onClick={() => { disconnect(room, stopSymblWebSocketApi, isConnected) }} placement="top"
            PopperProps={{ disablePortal: true }}>
            <Fab className={classes.fab} style={{ backgroundColor: 'darkred', color: 'white' }}>
                <CallEnd />
            </Fab>
        </Tooltip>
    );
}
