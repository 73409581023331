import { parse } from '../../../util/urlHelpers';
import { pendingTransaction, pendingTransactionWithSpecificId, updateAfterPayment, updateListing } from '../../../util/api';
import { storableError } from '../../../util/errors';
import { addMarketplaceEntities } from '../../../ducks/marketplaceData.duck';

// ================ Action types ================ //

export const CLEAR_UPDATED_FORM = 'app/AdminPanelPage/CLEAR_UPDATED_FORM';

export const GET_PENDING_TRANSACTION_REQUEST = 'app/AdminPendingTransaction/GET_PENDING_TRANSACTION_REQUEST';
export const GET_PENDING_TRANSACTION_SUCCESS = 'app/AdminPendingTransaction/GET_PENDING_TRANSACTION_SUCCESS';
export const GET_PENDING_TRANSACTION_ERROR = 'app/AdminPendingTransaction/GET_PENDING_TRANSACTION_ERROR';


export const GET_PENDING_TRANSACTION_WITH_ID_REQUEST = 'app/AdminPendingTransaction/GET_PENDING_TRANSACTION_WITH_ID_REQUEST';
export const GET_PENDING_TRANSACTION_WITH_ID_SUCCESS = 'app/AdminPendingTransaction/GET_PENDING_TRANSACTION_WITH_ID_SUCCESS';
export const GET_PENDING_TRANSACTION_WITH_ID_ERROR = 'app/AdminPendingTransaction/GET_PENDING_TRANSACTION_WITH_ID_ERROR';

export const GET_UPDATE_AFTER_PAYMENT_REQUEST = 'app/AdminPendingTransaction/GET_UPDATE_AFTER_PAYMENT_REQUEST';
export const GET_UPDATE_AFTER_PAYMENT_SUCCESS = 'app/AdminPendingTransaction/GET_UPDATE_AFTER_PAYMENT_SUCCESS';
export const GET_UPDATE_AFTER_PAYMENT_ERROR = 'app/AdminPendingTransaction/GET_UPDATE_AFTER_PAYMENT_ERROR';



export const SEND_APPROVED_LISTING_REQUEST = 'app/AdminPanelPage/SEND_APPROVED_LISTING_REQUEST';
export const SEND_APPROVED_LISTING_SUCCESS = 'app/AdminPanelPage/SEND_APPROVED_LISTING_SUCCESS';
export const SEND_APPROVED_LISTING_ERROR = 'app/AdminPanelPage/SEND_APPROVED_LISTING_ERROR';


// ================ Reducer ================ //
const resultIds = data => data.data.map(l => l.id);
const perPageOutcomes = 100;
const initialState = {
  listing_pagination: null,
  fetch_listing_InProgress: false,
  updateInProgress: false,
  updateTransactionError: null,
  getListingError: null,
  getPendingTransactionListingData: [],
  listingUpdatedId: null,
  getTransactionListWithIdData: [],
  getTransactionListWithIdDataInProgress: false,
  TransactionListWithIdData_pagination: null,
  getTransactionListWithIdDataError: null,
  updateAfterpaymentInprogress: null,
  updateAfterpaymentError: null,
  updateAfterpaymentdata: null,
  extraIncludeData: null

};

export default function reducer(state = initialState, action = {}) {
  const { type, payload, id = '', pagination = {},extraIncludeData } = action;
  switch (type) {
    //  Admin Listing
    case GET_PENDING_TRANSACTION_REQUEST:
      return {
        ...state,
        fetch_listing_InProgress: true,
        getListingError: null,
        listing_pagination: null
      };
    case GET_PENDING_TRANSACTION_SUCCESS: {

      return {
        ...state,
        getPendingTransactionListingData: ([...new Set(payload)]),
        fetch_listing_InProgress: false,
        listing_pagination: pagination
      };
    }
    case GET_PENDING_TRANSACTION_ERROR: {
      // eslint-disable-next-line no-console
      return {
        ...state,
        getPendingTransactionListingData: null,
        listing_pagination: null,
        fetch_listing_InProgress: false,
        getListingError: payload.error
      };
    }

    case GET_PENDING_TRANSACTION_WITH_ID_REQUEST:
      return {
        ...state,
        getTransactionListWithIdData: null,
        TransactionListWithIdData_pagination: null,
        getTransactionListWithIdDataInProgress: true,
        getTransactionListWithIdDataError: null

      }

    case GET_PENDING_TRANSACTION_WITH_ID_SUCCESS:

      let providerIdArray = [];
      providerIdArray = payload

      // (payload.data && payload.data.data && payload.data.data.map((value) => {
      //   if ((value.attributes.lastTransition == 'transition/partial-complete' || value.attributes.lastTransition == 'transition/review-1-by-customer' || value.attributes.lastTransition == "transition/complete") && value.attributes.protectedData.providerId == id ) {
      //     providerIdArray.push(value)
      //   }
      // }))
      return {
        ...state,
        getTransactionListWithIdData: (providerIdArray),
        extraIncludeData: extraIncludeData,
        TransactionListWithIdData_pagination: 1,
        getTransactionListWithIdDataInProgress: false,
        getTransactionListWithIdDataError: null

      }


    case GET_PENDING_TRANSACTION_WITH_ID_ERROR:
      return {
        ...state,
        getTransactionListWithIdData: null,
        TransactionListWithIdData_pagination: null,
        getTransactionListWithIdDataInProgress: false,
        getTransactionListWithIdDataError: payload.error

      }

    case GET_UPDATE_AFTER_PAYMENT_REQUEST:
      return {
        ...state,
        updateAfterpaymentInprogress: true,
        updateAfterpaymentError: null,
        updateAfterpaymentdata: null,
      }

    case GET_UPDATE_AFTER_PAYMENT_SUCCESS:
      return {
        ...state,
        updateAfterpaymentInprogress: false,
        updateAfterpaymentError: null,
        updateAfterpaymentdata: null,
      }

    case GET_UPDATE_AFTER_PAYMENT_ERROR:
      return {
        ...state,
        updateAfterpaymentInprogress: false,
        updateAfterpaymentError: payload.error,
        updateAfterpaymentdata: null,
      }

    //  Get it Approved
    case SEND_APPROVED_LISTING_REQUEST:
      return {
        ...state,
        updateInProgress: true,
        listingUpdatedId: payload.id,
      };

    case SEND_APPROVED_LISTING_SUCCESS:
      return {
        ...state,
        updateInProgress: false
      };

    case SEND_APPROVED_LISTING_ERROR:
      return {
        ...state,
        listingUpdated: false,
        updateInProgress: false
      };

    case CLEAR_UPDATED_FORM:
      return {
        ...state,
        updateTransactionError: null,
        getTransactionError: null
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const clearUpdatedForm = () => ({
  type: CLEAR_UPDATED_FORM,
});

export const getPendingTransactionRequest = params => ({ type: GET_PENDING_TRANSACTION_REQUEST, payload: { params } });
export const getPendingTransactionSuccess = (result, meta) => ({ type: GET_PENDING_TRANSACTION_SUCCESS, payload: result, pagination: meta });
export const getPendingTransactionError = error => ({ type: GET_PENDING_TRANSACTION_ERROR, payload: error, error: true });

export const getPendingTransactionWithIdRequest = params => ({ type: GET_PENDING_TRANSACTION_WITH_ID_REQUEST, payload: { params } });
export const getPendingTransactionWithIdSuccess = (result, id,extraIncludeData) => ({ type: GET_PENDING_TRANSACTION_WITH_ID_SUCCESS, payload: result, id: id ,extraIncludeData:extraIncludeData});
export const getPendingTransactionWithIdError = error => ({ type: GET_PENDING_TRANSACTION_WITH_ID_ERROR, payload: error, error: true });


export const updateAfterPaymentRequest = params => ({ type: GET_UPDATE_AFTER_PAYMENT_REQUEST, payload: { params } });
export const updateAfterPaymentSuccess = (result, id) => ({ type: GET_UPDATE_AFTER_PAYMENT_SUCCESS, payload: result, id: id });
export const updateAfterPaymentError = error => ({ type: GET_UPDATE_AFTER_PAYMENT_ERROR, payload: error, error: true });


export const sendToApproveListRequest = params => ({ type: SEND_APPROVED_LISTING_REQUEST, payload: { ...params } });
export const sendToApproveListSuccess = result => ({ type: SEND_APPROVED_LISTING_SUCCESS, payload: result });
export const sendToApproveListError = error => ({ type: SEND_APPROVED_LISTING_ERROR, payload: error, error: true });

// ================ Thunk ================ //

export const getListOFPendingTransaction = (searchParams, needRequest = true) => (dispatch, getState, sdk) => {
  const { page = 1, ...rest } = searchParams;
  if (needRequest) {
    dispatch(getPendingTransactionRequest());
  }
  const params = {
    page,
    per_page: perPageOutcomes,
    only: "sale",
    lastTransitions: ["transition/partial-complete", "transition/complete", "transition/review-1-by-customer"],
    searchDate: rest.keywords ? JSON.parse(rest.keywords) : null,
    ...rest
  };
  return pendingTransaction(params).then(response => {
    dispatch(addMarketplaceEntities(response));
    let providerIdArray = [];
    // console.log('ram', response.data, 'sss', response.data.meta);
    // (response.data && response.data.data && response.data.data.map(
    //   ({ attributes: { lastTransition, protectedData: { providerId } } }) => {
    //     if (lastTransition == 'transition/partial-complete' || lastTransition == 'transition/review-1-by-customer' || lastTransition == "transition/complete") {
    //       if (response.data.included && response.data.included.length) {
    //         response.data.included.map((value) => {
    //           if (value.id.uuid == providerId && !value.attributes.deleted) {
    //             console.log(providerId, 'providerId');
    //             let noOfCounts = 0;
    //             dispatch(transactionsWithId(providerId, {})).then((res) => {
    //               (res.data && res.data.data && res.data.data.map((value) => {
    //                 if ((value.attributes.lastTransition == 'transition/partial-complete' || value.attributes.lastTransition == 'transition/review-1-by-customer' || value.attributes.lastTransition == "transition/complete") && value.attributes.protectedData.providerId == providerId && ((value.attributes.metadata && value.attributes.metadata.userDeleted) ? false : true)) {
    //                   console.log(value,'ram');
    //                   noOfCounts = noOfCounts + 1
    //                 }
    //               }))

    //             });
    //             console.log(noOfCounts,'noOfCountsnoOfCounts');
    //             if(noOfCounts){
    //               providerIdArray.push(providerId)

    //             }
    //             // providerIdArray.push(providerId)
    //           }
    //         })
    //       }
    //     }
    //   }))

      async function processData() {
        if (response.data && response.data.data) {
          await Promise.all(response.data.data.map(async ({ attributes: { lastTransition, protectedData: { providerId } } }) => {
            if (lastTransition === 'transition/partial-complete' || lastTransition === 'transition/review-1-by-customer' || lastTransition === "transition/complete") {
              if (response.data.included && response.data.included.length) {
                await Promise.all(response.data.included.map(async (value) => {
                  if (value.id.uuid === providerId && !value.attributes.deleted) {
                    // console.log(providerId, 'providerId');
                    let noOfCounts = 0;
      
                    // Use await to wait for the asynchronous call to complete
                    await dispatch(transactionsWithId(providerId, {})).then((res) => {
                      if (res.data && res.data.data) {
                        res.data.data.forEach((value) => {
                          if ((value.attributes.lastTransition === 'transition/partial-complete' || value.attributes.lastTransition === 'transition/review-1-by-customer' || value.attributes.lastTransition === "transition/complete") && value.attributes.protectedData.providerId === providerId && ((value.attributes.metadata && value.attributes.metadata.userDeleted) ? false : true)) {
                            // console.log(value, 'ram');
                            noOfCounts = noOfCounts + 1;
                          }
                        });
                      }
                    });
      
                    // console.log(noOfCounts, 'noOfCountsnoOfCounts');
      
                    if (noOfCounts) {
                      providerIdArray.push(providerId);
                    }
                  }
                }));
              }
            }
          }));
        }
      }
    //   processData()
    //   console.log('hereeeee');
    // dispatch(getPendingTransactionSuccess(providerIdArray, response.data.meta));
    // return response;
    async function fetchDataAndDispatch() {
      await processData();
      // console.log('hereeeee');
      dispatch(getPendingTransactionSuccess(providerIdArray, response.data.meta));
      return response;
    }
    
    fetchDataAndDispatch();
  })
    .catch(e => {
      dispatch(getPendingTransactionError(storableError(e)));
      throw e;
    });
}

export const approvedByAdmin = ({ id, page }, fetchListings = true) => (dispatch, getState, sdk) => {
  dispatch(sendToApproveListRequest({ id }));

  return updateListing({ id })
    .then(response => {
      dispatch(sendToApproveListSuccess(response));
      if (fetchListings) {
        setTimeout(() => {
          dispatch(fetchAdminByApproval({ page }, false));
        }, 1000);
      }
      return response;
    })
    .catch(e => {
      dispatch(sendToApproveListError(storableError(e)));
      throw e;
    });
}


export const transactionsWithId = (id, data) => (dispatch, getState, sdk) => {
  dispatch(getPendingTransactionWithIdRequest(id));
  const params = {
    id: id,
    per_page: perPageOutcomes,
    searchDate: data.keywords ? JSON.parse(data.keywords) : null,
  };
  return pendingTransactionWithSpecificId(params).then(response => {
    // console.log('pending', params);
    let providerIdArray = [];
    let extraIncludeData = [];
    (response.data && response.data.data && response.data.data.map((value) => {
      // console.log(value.attributes.protectedData, 'rammmmmm', value.attributes.metadata);
      if ((value.attributes.lastTransition == 'transition/partial-complete' || value.attributes.lastTransition == 'transition/review-1-by-customer' || value.attributes.lastTransition == "transition/complete") && value.attributes.protectedData.providerId == id && ((value.attributes.metadata && value.attributes.metadata.userDeleted) ? false : true)) {
        if (value && value.relationships) {
          response && response.data && response.data.included && response.data.included.length && response.data.included.map((val, i) => {
            if (val.id.uuid == value.relationships.customer.data.id.uuid) {
              // console.log(val.attributes.deleted, 'customer');
              value.attributes.customerDeleted = val.attributes.deleted
            }
            if (val.id.uuid == value.relationships.provider.data.id.uuid) {
              value.attributes.ProviderDeleted = val.attributes.deleted
            }
          })
        }
        providerIdArray.push(value)
      }
      extraIncludeData = (response && response.data && response.data.included) || {};
    }))
    dispatch(getPendingTransactionWithIdSuccess(providerIdArray, id,extraIncludeData))
    return response;
  })
    .catch(e => {
      dispatch(getPendingTransactionWithIdError(storableError(e)));
      throw e;
    })
};

export const changeTransitionToComplete = (id, transition, customerDeleted, ProviderDeleted) => (dispatch, getState, sdk) => {
  // console.log(customerDeleted, ProviderDeleted, 'customerDeleted,ProviderDeleted');
  dispatch(updateAfterPaymentRequest(id))
  const params = {
    id: id,
    transition: transition,
    customerDeleted: customerDeleted,
    ProviderDeleted: ProviderDeleted
  };
  return updateAfterPayment(params).then(response => {
    dispatch(updateAfterPaymentSuccess(response));
    dispatch(getPendingTransactionWithIdRequest(id));
    dispatch(getListOFPendingTransaction({}, true));
    return response;
  })
    .catch(e => {
      dispatch(updateAfterPaymentError(storableError(e)));
      throw e;
    })

};

export function loadData(param, search) {
  const { page = 1, ...rest } = parse(search) || {};

  return getListOFPendingTransaction({ ...rest, page });
}
