import React, { useState } from "react";
import "./FirApp.css";
import Phone from "./Phone";
import { FormattedMessage } from "react-intl";
import { Button } from "../..";
const FirApp = ({onGetTokenVoice,oncallApiForVoice,disableMeetingButton,classs,endcall,phoneNu,callConnected,callDisconnected,end}) => {
  const [token, setToken] = useState(null);
  const [clicked, setClicked] = useState(false);
  const identity = "phil";

  const handleClick = () => {
    setClicked(true);
    onGetTokenVoice(encodeURIComponent(identity))
      .then(response =>   {
        setToken(response.twilioVoiceToken) })
      
  };

  return (
    <div className="app">
      {/* <header className="App-header">
        <h1>React &amp; Twilio Phone</h1>
      </header> */}
{/* disabled={disableMeetingButton} */}
      <main>
        {!clicked && <Button    onClick={handleClick} disabled={disableMeetingButton}  className={classs}><FormattedMessage id="TwilioVideo.joinMeetCallButton" /></Button>}

        {token ? <Phone phoneNu={phoneNu} endcall={endcall} oncallApiForVoice={oncallApiForVoice} token={token} callConnected={callConnected} callDisconnected={callDisconnected} end={end}></Phone> : null}
      </main>
    </div>
  );
};

export default FirApp;
