import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconClose.module.css';
const SIZE_SMALL = 'small';
const SIZE_NORMAL = 'normal';

const IconClose = props => {
  const { className, rootClassName, size } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (size === SIZE_SMALL) {
    return (
      <svg className={classes} width="24" height="24" viewBox="0 0 24 24" fill="none" style={{fill:"transparent"}}>
      <path d="M15.3 15.3L8.70001 8.70001M15.3 8.70001L8.70001 15.3" stroke="#595858" strokeWidth="2" strokeLinecap="round"/>
      <path d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z" stroke="#595858" strokeWidth="2"/>
      </svg>
      
    );
  }

  return (
    <svg className={classes} width="24" height="24" viewBox="0 0 24 24" fill="none" style={{fill:"transparent"}}>
      <path d="M15.3 15.3L8.70001 8.70001M15.3 8.70001L8.70001 15.3" stroke="#595858" strokeWidth="2" strokeLinecap="round"/>
      <path d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z" stroke="#595858" strokeWidth="2"/>
      </svg>
  );
};

const { string } = PropTypes;

IconClose.defaultProps = {
  className: null,
  rootClassName: null,
  size: SIZE_NORMAL,
};

IconClose.propTypes = {
  className: string,
  rootClassName: string,
  size: string,
};

export default IconClose;
