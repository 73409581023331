import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, FieldSelect, Button } from '../../components';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';

import css from './EditListingAvailabilitySessionsForm.module.css';

const KEY_CODE_ENTER = 13;

const EditListingAvailabilitySessionsFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        disabled,
        ready,
        inProgress,
        invalid,
        intl,
        updated,
        updateInProgress,
        fetchErrors,
        onOpenTermsOfService,
        pristine,
        isPublished,
        submitButtonText,
        // authInfo,
        idp,
      } = formRenderProps;


      // email
      const sessionTimeLabel = intl.formatMessage({
        id: 'EditListingAvailabilitySessionsForm.sessionTimeLabel',
      });
      const sessionTimePlaceholder = intl.formatMessage({
        id: 'EditListingAvailabilitySessionsForm.sessionTimePlaceholder',
      });

      const buttonForPhotes = intl.formatMessage({
        id: 'EditListingWizard.saveNewAvailability',
      });

      const bookedSessionLabel = intl.formatMessage({
        id: 'EditListingAvailabilitySessionsForm.bookedSessionLabel',
      });


      const leadTimeRequired = intl.formatMessage({
        id: 'EditListingAvailabilitySessionsForm.LeadTimeRequired',
      });


      const bookedSessionrequired = intl.formatMessage({
        id: 'EditListingAvailabilitySessionsForm.bookedSessionRequired',
      });
      const bufferTimeOptions = findOptionsForSelectFilter('bufferTime', config.custom.filters);
      const bookedSession = findOptionsForSelectFilter('bookedSession', config.custom.filters);


      // const emailValid = validators.emailFormatValid(emailInvalidMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      // Initial values from idp provider
      // const { email, firstName, lastName } = authInfo;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <FieldSelect
              className={css.inputBox}
              name="sessionTime"
              id="sessionTime"
              label={sessionTimeLabel}
              placeholder={sessionTimePlaceholder}
              validate={validators.required(leadTimeRequired)}
            >
              <option disabled value="">
                {sessionTimePlaceholder}
              </option>
              {bufferTimeOptions.map((val) => <option key={val.label} >
                {val.label}
              </option>)}
            </FieldSelect>

            <FieldSelect
              name="bookedSession"
              className={css.inputBox}
              id="bookedSession"
              label={bookedSessionLabel}
              placeholder={sessionTimePlaceholder}
              validate={validators.required(bookedSessionrequired)}
            >
              <option disabled value="">
                {sessionTimePlaceholder}
              </option>
              {bookedSession.map((val) => <option key={val.label} value={val.key} >
                {val.label}
              </option>)}
            </FieldSelect>

            
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={submitReady}
                >
                  {submitButtonText}
                </Button>
          </div>
        </Form>
      );
    }}
  />
);

EditListingAvailabilitySessionsFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

EditListingAvailabilitySessionsFormComponent.propTypes = {
  inProgress: bool,

  // onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const EditListingAvailabilitySessionsForm = compose(injectIntl)(EditListingAvailabilitySessionsFormComponent);
EditListingAvailabilitySessionsForm.displayName = 'EditListingAvailabilitySessionsForm';

export default EditListingAvailabilitySessionsForm;
