const config = {
    symbl: {
        // If set to true, a dialog for entering Symbl App ID and App Secret will pop up.
        // Set this to false if you'd like to use token generation for Symbl in the backend server. appId and appSecret settings will be disabled.
        enableInAppCredentials: true,
        // appId and appSecret will be populated automatically from localstorage after user enters them in Settings Dialog.
        // WARNING: You can hard code them here but it's not recommended because it'll expose your credentials in the source in browser.
        //          If you wish to use common appId and appSecret pair then consider setting up a Token Server. Refer to server.js in root of this project.
        appId: '685a4b4d5256324d417472685a38765374756b306d36454e3677435876755167',
        appSecret: '31763453586a4e64344268705f6f3177315a6b336e375354636a4755336e2d6b714f78656333783156374d797a773450377a794732616476594d61564d514d74'
    },
    appBasePath: "/" // Set this to something else if you want to deploy multiple versions on same server. Always end with /
};
export default config;