import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { array, bool, func, node, object, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { propTypes, LISTING_STATE_CLOSED, LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import { ModalInMobile, Button } from '../../components';
import { BookingTimeForm } from '../../forms';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './BookingPanel.module.css';

const { Money } = sdkTypes;
// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openBookModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), book: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const BookingPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    subTitle,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    trades,
    selectedPrice,
    selectedDuration,
    pendingIsApproved,
    handleSelectedPrice,
    localHireTrade,
    showContactUser,
    onContactUser
  } = props;

  const price = listing.attributes.price;
  const timeZone =
    listing.attributes.availabilityPlan && listing.attributes.availabilityPlan.timezone;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingTimeForm = hasListingState && !isClosed;
  const showClosedListingHelpText = listing.id && isClosed;
  const selectedTrade = trades && trades.length
    ? trades[0]
    : {};
    const {bookedSession} = listing && listing.attributes && listing.attributes.publicData || '1'  ;
  const { inputValues = [] } = selectedTrade;
  const { formattedPrice, priceTitle } = priceData((selectedPrice
    ? new Money(selectedPrice, price.currency)
    : selectedDuration && inputValues && inputValues.price
      ? new Money(((inputValues.price / 4) * selectedDuration), price.currency)
      : selectedDuration && price && price.amount
        ? new Money(((price.amount / 4) * selectedDuration), price.currency)
        : price), intl);
  const isBook = !!parse(location.search).book;
  const subTitleText = !!subTitle
    ? subTitle
    : showClosedListingHelpText
    ? intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
    : null;

    const {charge_taxes =false} = (listing && listing.attributes && listing.attributes.publicData) || {};
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'BookingPanel.perNight'
    : isDaily
    ? 'BookingPanel.perDay'
    : 'BookingPanel.perUnits';

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.bookingTitle);

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="BookingTimeFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.modalHeading}>
          <h1 className={css.title}>{title}</h1>
        </div>
        <div className={css.bookingHeading}>
          {formattedPrice
            ? <div className={css.desktopPriceContainer}>
              <span className={css.desktopPriceValue} title={priceTitle}>
                From <b>{formattedPrice}</b>
              </span>
              <span className={css.desktopPerUnit}>
                <FormattedMessage id={unitTranslationKey} values={{
                  type: ((selectedDuration * 15)
                    ? (selectedDuration * 15) >= 60
                      ? (parseInt((selectedDuration * 15) / 60) + (parseInt((selectedDuration * 15) / 60) > 1 ? ' hours ' : ' hour ') + (((selectedDuration * 15) % 60) == 0 ? "" : ((selectedDuration * 15) % 60) + ' minutes'))
                      : ((selectedDuration * 15) + ' minutes')
                    : 'hour')
                }} />
              </span>
            </div>
            : null}
          <div className={css.bookingHeadingContainer}>
            <h2 className={titleClasses}>Request a virtual meet with {title}</h2>
            {subTitleText ? <div className={css.bookingHelp}>{subTitleText}</div> : null}
          </div>
        </div>

        {showBookingTimeForm
          ? !pendingIsApproved 
            ? (
              <div className={css.pendingIsApproved}>
                <p>This listing is pending approval from The Project Garage admin, and it's currently not bookable.</p>
                <p>Others can book it once the admin has approved it.</p>
              </div>
            )
            : (
              <BookingTimeForm
                className={css.bookingForm}
                formId="BookingPanel"
                submitButtonWrapperClassName={css.submitButtonWrapper}
                unitType={unitType}
                onSubmit={onSubmit}
                price={price}
                listingId={listing.id}
                initialValues={{ 
                  sessionLength: selectedDuration,
                  tradeService: trades && trades.length ? trades[0].key : "",
                }}
                isOwnListing={isOwnListing}
                bookedSession={bookedSession}
                monthlyTimeSlots={monthlyTimeSlots}
                onFetchTimeSlots={onFetchTimeSlots}
                startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
                endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
                timeZone={timeZone}
                onFetchTransactionLineItems={onFetchTransactionLineItems}
                lineItems={lineItems}
                fetchLineItemsInProgress={fetchLineItemsInProgress}
                fetchLineItemsError={fetchLineItemsError}
                trades={trades}
                selectedPrice={selectedPrice}
                selectedDuration={selectedDuration}
                pendingIsApproved={pendingIsApproved}
                handleSelectedPrice={handleSelectedPrice}
                charge_taxes={charge_taxes}
                localHireTrade={localHireTrade}
                showContactUser={showContactUser}
                onContactUser={onContactUser}
              />
            ) : null}
      </ModalInMobile>
      <div className={css.openBookingForm}>
        <div className={css.priceContainer}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          <div className={css.perUnit}>
            <FormattedMessage id={unitTranslationKey} values={{
                  type: ((selectedDuration * 15)
                    ? (selectedDuration * 15) >= 60
                      ? (parseInt((selectedDuration * 15) / 60) + (parseInt((selectedDuration * 15) / 60) > 1 ? ' hours ' : ' hour ') + (((selectedDuration * 15) % 60) == 0 ? "" : ((selectedDuration * 15) % 60) + ' minutes'))
                      : ((selectedDuration * 15) + ' minutes')
                    : 'hour')
                }} />
          </div>
        </div>

        {showBookingTimeForm ? (
          <Button
            rootClassName={css.bookButton}
            onClick={() => openBookModal(isOwnListing, isClosed, history, location)}
          >
            <FormattedMessage id="BookingPanel.ctaButtonMessage" />
          </Button>
        ) : isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="BookingPanel.closedListingButtonText" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(BookingPanel);
