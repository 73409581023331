import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import EndCallButton from './EndCallButton/EndCallButton';
import ToggleAudioButton from './ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from './ToggleVideoButton/ToggleVideoButton';
import ToggleScreenShareButton from './ToogleScreenShareButton/ToggleScreenShareButton';
import useIsUserActive from './useIsUserActive/useIsUserActive';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import { IconVideo } from '../../../../../components';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import ReactTooltip from 'react-tooltip';

import css from './Controls.module.css';

// import Tooltip from '@mui/material/Tooltip';
const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      display: 'flex',
      position: 'absolute',
      right: '50%',
      transform: 'translate(50%, 30px)',
      bottom: '50px',
      zIndex: 2100,
      transition: 'opacity 1.2s, transform 1.2s, visibility 0s 1.2s',
      opacity: 0,
      visibility: 'visible',
      maxWidth: 'min-content',
      '&.showControls, &:hover': {
        transition: 'opacity 0.6s, transform 0.6s, visibility 0s',
        opacity: 1,
        visibility: 'visible',
        transform: 'translate(50%, 0px)',
      },
      [theme.breakpoints.down('xs')]: {
        bottom: `${theme.sidebarMobileHeight + 3}px`,
      },
    },
  })
);

export default function Controls(props) {
  const { toggleChatWindow, isChatOpened, toggleCC, showCC,disconnectUpdate,endcall,history } = props;
  const classes = useStyles();
  const {
    room: { localParticipant },
    localTracks,
    getLocalVideoTrack,
    removeLocalVideoTrack,
    onError,
  } = useVideoContext();
  const { roomState } = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const isUserActive = useIsUserActive();
  const showControls = isUserActive || roomState === 'disconnected';
  // Get the local video track
  const localVideoTrack = localParticipant.videoTracks.find(track => track.trackName && track.trackName.includes('camera'));

  // Get the media stream track from the local video track
  const mediaStreamTrack = localVideoTrack?.track?.mediaStreamTrack;
  let cameraView = 'FRONT';
  if (mediaStreamTrack && mediaStreamTrack.label && mediaStreamTrack.label.toLowerCase().includes('back')) {
    cameraView = 'BACK';
  }

  const flipCamera = () => {
    // Get the list of available media devices
    navigator.mediaDevices.enumerateDevices()
      .then(devices => {
        // Find the desired camera
        const desiredCamera = cameraView == 'BACK'
          ? devices.find(device => device.kind && device.label && ['video', 'videoinput'].includes(device.kind) && !device.label.toLowerCase().includes('back'))
          : devices.find(device => device.kind && device.label && ['video', 'videoinput'].includes(device.kind) && device.label.toLowerCase().includes('back'));

        // If the desired camera is found, switch the media stream track
        if (desiredCamera) {
          const videoTrack = localTracks.find(track => track.name.includes('camera'));
          if (videoTrack) {
            const localTrackPublication = localParticipant?.unpublishTrack(videoTrack);
            // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
            if (localParticipant) {
              localParticipant.emit('trackUnpublished', localTrackPublication);
            }
            removeLocalVideoTrack();
          }
          getLocalVideoTrack({ deviceId: { exact: desiredCamera.deviceId } })
            .then((track) => localParticipant?.publishTrack(track, { priority: 'low' }))
            .catch(onError);
        }
      })
      .catch(error => {
        console.error('Error enumerating media devices:', error);
      });
  }

  return (
    <div className={clsx(classes.container, { showControls })}>
      <ToggleAudioButton disabled={isReconnecting} className={css.videoActionButton} />
      <ToggleVideoButton disabled={isReconnecting} className={css.videoActionButton} />
      <button
        className={css.videoActionButton}
        onClick={() => toggleChatWindow(!isChatOpened)}
        data-tip
        data-for="chat"
      >
        {isChatOpened ? (
          <IconVideo type={"CHAT"} rootClassName={css.chatIcon} />
        ) : (
          <IconVideo type={"CHAT"} rootClassName={css.chatIcon} />
        )}
        {/* <Tooltip title={isChatOpened ? 'Chat On' : 'Chat Off'}> */}
        <ReactTooltip id="chat" place="top" effect="solid" className={css.customTooltip}>
          {isChatOpened ? 'Chat On' : 'Chat Off'}
        </ReactTooltip>
        {/* </Tooltip> */}
      </button>
      <button
        className={css.flipActionButton}
        onClick={() => flipCamera()}
        data-tip
        data-for="flipTooltip"
      >
        <IconVideo type={"FLIP"} rootClassName={css.chatIcon} />
        <ReactTooltip id="flipTooltip" place="top" effect="solid" className={css.customTooltip}>
          {cameraView ? 'Switch Front' : 'Switch Back'}
        </ReactTooltip>
      </button>
      {/* <button
        className={css.videoActionButton}
        onClick={() => toggleCC(!showCC)}
        data-tip
        data-for="chatTooltip"
      >
        <IconVideo type={"CC"} rootClassName={css.chatIcon} />
        <ReactTooltip id="chatTooltip" place="top" effect="solid" className={css.customTooltip}>
          {showCC ? 'Captions On' : 'Captions Off'}
        </ReactTooltip>
      </button> */}
      {roomState !== 'disconnected' && (
        <>
          <ToggleScreenShareButton disabled={isReconnecting} className={css.videoActionButton} />
          <EndCallButton history={history} disconnectUpdate={disconnectUpdate} endcall={endcall} className={css.videoActionButton} />
        </>
      )}
    </div>
  );
}
