
/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

import moment from "moment";

export const taxesAndFees = [
  {
    Provice: "Alberta",
    Tax_Codes: "GST",
    Tax_Amounts: "5%",
  },
  {
    Provice: "British Columbia",
    Tax_Codes: "GST + PST",
    Tax_Amounts: "5% + 7%",
  },
  {
    Provice: "Ontario",
    Tax_Codes: "HST",
    Tax_Amounts: "13%",
  },
  {
    Provice: "Manitoba",
    Tax_Codes: "GST + PST",
    Tax_Amounts: "5% + 7%",
  },
  {
    Provice: "Newfoundland and Labrador",
    Tax_Codes: "HST",
    Tax_Amounts: "15%",
  },
  {
    Provice: "Nova Scotia",
    Tax_Codes: "HST",
    Tax_Amounts: "15%",
  },
  {
    Provice: "Quebec",
    Tax_Codes: "GST + QST",
    Tax_Amounts: "5% + 9.975%",
  },
  {
    Provice: "Prince Edward Island",
    Tax_Codes: "HST",
    Tax_Amounts: "15%",
  },
  {
    Provice: "New Brunswick",
    Tax_Codes: "HST",
    Tax_Amounts: "15%",
  },
  {
    Provice: "Northwest Territories",
    Tax_Codes: "GST",
    Tax_Amounts: "5%",
  },
  {
    Provice: "Nunavut",
    Tax_Codes: "GST",
    Tax_Amounts: "5%",
  },
  {
    Provice: "Yukon",
    Tax_Codes: "GST",
    Tax_Amounts: "5%",
  },
  {
    Provice: "Saskatchewan",
    Tax_Codes: "GST + PST",
    Tax_Amounts: "5% + 6%",
  },
];

export const filters = [
  {
    id: 'dates-length',
    label: 'Dates',
    type: 'BookingDateRangeLengthFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates', 'minDuration'],
    config: {
      // A global time zone to use in availability searches. As listings
      // can be in various time zones, we must decide what time zone we
      // use in search when looking for available listings within a
      // certain time interval.
      //
      // If you have all/most listings in a certain time zone, change this
      // config value to that.
      //
      // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
      searchTimeZone: 'Etc/UTC',

      // Options for the minimum duration of the booking
      options: [
        { key: '0', label: 'Any length' },
        { key: '15 Min ', label: '15 Min', shortLabel: '15 Min' },
        { key: '30 Min', label: '30 Min', shortLabel: '30 Min' },
        { key: '45 Min ', label: '45 Min', shortLabel: '45 Min' },
        { key: '1 Hour', label: '1 Hour', shortLabel: '1 hr' },
        { key: '1+ Hour', label: '1+ Hour', shortLabel: '1+ hr' },
      ],
    },
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'yearExperiences',
    label: 'Year of Experience',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['bufferTime_filter'],
    config: {
      options: [
        // { key: 'none', label: 'select' },
        { key: 'Any', label: 'Any' },
        { key: '1-3', label: '1-3' },
        { key: '4-7', label: '4-7' },
        { key: '8-12', label: '8-11' },
        { key: '12+', label: '12+' },
      ],
    },
  },
  {
    id: 'gender',
    label: 'Gender',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_gender_filter'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        // { key: 'none', label: 'select' },
        // { key: 'none', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
        { key: 'Male', label: 'Male' },
        { key: 'Female', label: 'Female' },
        { key: 'Non-Binary', label: 'Non-Binary' },
        { key: 'Prefer Not to Say', label: 'Prefer Not to Say' },
      ],
    },
  },
  {
    id: 'countryCode',
    label: 'countryCode',
    type: 'SelectcountryCode',
    group: 'secondary',
    queryParamNames: ['pub_countryCode'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        // { key: 'none', label: 'select' },
        // { key: 'none', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
        { key: 'canada', label: '(+1) Canada - CA',value: '1' },
        // { key: 'india', label: '(+91) India - IN',value: '91' },
        ,
      ],
    },
  },
  {
    id: 'language',
    label: 'Language',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['language_filter'],
    config: {
      options: [
        // { key: '', label: 'select' },
        { key: 'English', label: 'English' },
        { key: 'French', label: 'French' },
        { key: 'Spanish', label: 'Spanish' },
        { key: 'Chinese', label: 'Chinese' },
        { key: 'German', label: 'German' },
        { key: 'Portugese', label: 'Portugese' },
        { key: 'Ukrainian', label: 'Ukrainian' },
        { key: 'Punjabi', label: 'Punjabi' },
        { key: 'Mandarin', label: 'Mandarin' },
        { key: 'Cantonese', label: 'Cantonese' },
        { key: 'Italian', label: 'Italian' },
        { key: 'Tagalog', label: 'Tagalog' },
        { key: 'Greek', label: 'Greek' },
        { key: 'Vietnamese', label: 'Vietnamese' },
        { key: 'Polish', label: 'Polish' },
        { key: 'Japanese', label: 'Japanese' },
        { key: 'Hindi', label: 'Hindi' },

      ],
    },
  },
  {
    id: 'pronouns',
    label: 'Pronouns',
    type: 'PronounsFilter',
    group: 'secondary',
    queryParamNames: ['Pronouns_filter'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        // { key: 'none', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
        // { key: 'none', label: 'select' },
        { key: '1', label: 'He/Him' },
        { key: '2', label: 'She/Her' },
        { key: '3', label: 'They/Them' },
        { key: '4', label: 'Prefer Not to Say' },
        // { key: '1', label: 'Co/Co' },
        // { key: '2', label: 'En/En' },
        // { key: '3', label: 'Ey/Em' },
        // { key: '4', label: 'He/Him' },
        // { key: '2', label: 'She/Her' },
        // { key: '3', label: 'They/Them' },
        // { key: '4', label: 'He/Him' },
      ],
    },
  },
  {
    id: 'diffTypes',
    label: 'I am a ...',
    type: 'diffTypesFilter',
    group: 'secondary',
    queryParamNames: ['diffTypes_filter'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'DIY', label: 'DIYer' },
        { key: 'PRO-LIST', label: 'Tradesperson PRO-LIST' },
        { key: 'PRO-SEARCH', label: 'Tradesperson PRO-SEARCH' },
        // { key: '3', label: 'They/Them' },
        // { key: '1', label: 'Co/Co' },
        // { key: '2', label: 'En/En' },
        // { key: '3', label: 'Ey/Em' },
        // { key: '4', label: 'He/Him' },
        // { key: '2', label: 'She/Her' },
        // { key: '3', label: 'They/Them' },
        // { key: '4', label: 'He/Him' },
      ],
    },
  },
  {
    id: 'tradeData',
    label: 'Trades',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['tradeData_filter'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        // { key: 'none', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
        // { key: 'none', label: 'select' },
        {
          key: 'electricianCommercial', value: 'Electrician ( Commercial )', label: 'Electrician ( Commercial )', compulsory: '1', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1686221519/trades/Electrician_psvfng.png', searchKeys: ['Appliances', 'Fixtures', 'Electrical', 'accessibility renovations', 'appliance repair', 'bathroom / kitchen fixture installation', 'electrical work', 'in floor heating installation', 'historical restoration', 'home automation', 'home theater', 'landscaping', 'lighting', 'thermostat installation', 'Home Security', 'tiny houses  ALL', 'Pool, hot tub, sauna installation repair', "commercial-wiring",
            "electrical-panel-upgrade",
            "lighting-design",
            "power-distribution",
            "emergency-lighting-installation",
            "electrical-system-maintenance",
            "code-compliance-check",
            "generator-installation",
            "data-center-electrical",
            "HVAC-electrical-services",
            "alarm-system",
            "security-camera-installation",
            "electrical"]
        },
        {
          key: 'plumber', value: 'Plumber', label: 'Plumber', compulsory: '1', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1686221519/trades/Plumber_rklrdo.png', searchKeys: ['Appliances', 'Fixtures', 'Plumbing', 'Tile', 'accessibility renovations', 'appliance repair', 'bathroom / kitchen fixture installation', 'countertop installation ', 'drain services', 'heating and air conditioning', 'historical restoration', 'landscaping', 'plumbing', 'solar water heating', 'water filtration and purification', 'tiny houses  ALL', 'Pool, hot tub, sauna installation repair', "plumbing-services",
            "emergency-plumber",
            "drain-cleaning",
            "pipe-repair",
            "water-heater-installation",
            "toilet-repair",
            "faucet-replacement",
            "leak-detection",
            "sewer-line-repair",
            "bathroom-remodeling",
            "appliance-installation",
            "dishwasher-installation",
            "water-leak-repair",
            "pressure-tank-repair", "Moen",
            "Kohler",
            "Delta",
            "American-Standard",
            "TOTO",
            "Sharkbite",
            "Pex",
            "Maxx",
            "copper", 'plumbing']
        },
        {
          key: 'carpenter', value: 'Carpenter ( General )', label: 'Carpenter ( General )', compulsory: '1', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1686221535/trades/Carpenter_e4c0tu.png', searchKeys: ['Flooring', 'Furniture', 'Windows', 'Carpentry', 'accessibility renovations', 'cabinetry building and renovating', 'carpentry', 'closet and storage solutions', 'countertop installation', 'deck building and repair', 'fences and gates building and repair', 'fireplace', 'floor refinishing', 'flooring installation', 'flooring removal', 'furniture refinishing and repair', 'framing', 'gazebos / sheds building and repair', 'gutters and eavestroughs', 'historical restoration', 'landscaping', 'millwork', 'mirrors and glass', 'railings', 'shutters', 'siding', 'Eavestroughing  Soffit Fascia', 'skylight installation and repair', 'solar panels', 'stair building', 'trim and moldings', 'windows and doors installation', 'woodworking', 'Textiles   re upholstering', 'tiny houses  ALL', 'Radeon mitigation', 'Pool, hot tub, sauna installation repair', 'Structural design / engineering', 'Home gym and fitness equipment installation', "custom-furniture",
            "custom-woodworking",
            "home-renovation",
            "cabinet-installation",
            "trim-and-molding",
            "deck-construction",
            "furniture-repair",
            "door-installation",
            "window-framing",
            "staircase-building",
            "tool-instruction",
            "tool-purchase",
            "tool-brand",
            "shed-construction",
            "pergola-construction",
            "custom-wood-design",
            "design-services",
            "DeWalt",
            "Makita",
            "Milwaukee",
            "Bosch",
            "Festool",
            "RIDGID",
            "Hitachi",
            "Stanley",
            "Craftsman",
            "Metabo",
            "Sanding",
            "Biscuit-join",
            "Joinery",
            "Wood-selection",
            "Building-code",
            "carpentry"]
        },
        {
          key: 'HVAC_mechanical_installer', value: 'HVAC Mechanical Installer', label: 'HVAC Mechanical Installer', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1686221538/trades/HVAC_nflwdw.png', compulsory: '1', searchKeys: ['heating and air conditioning', 'home automation', 'solar water heating', 'Radeon mitigation', "hvac-installation",
            "ac-repair",
            "furnace-maintenance",
            "thermostat-installation",
            "ductwork-inspection",
            "heat-pump-service",
            "ventilation-system-check",
            "air-quality-assessment",
            "refrigerant-recharge",
            "energy-efficient-upgrades",
            "Air-conditioning",
            "Ductless-mini-split-systems",
            "Indoor-air-quality-products",
            "HVAC-zoning-systems",
            "Smart-thermostats",
            "Nest",
            "Ecobee",
            "Honeywell",
            "Emerson",
            "Ductwork-design",
            "Heat-loss-calculation",
            "heating and air conditioning"]
        },
        { key: 'metalworker', value: 'Metalworker', label: 'Metalworker', compulsory: '1', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1686221519/trades/Metalworker_d7fbbt.png', searchKeys: ['tiny houses  ALL'] },
        {
          key: 'gasfitter / Pipefitter', value: 'Gasfitter / Pipefitter', label: 'Gasfitter / Pipefitter', compulsory: '1', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1686221538/trades/Pipefitter_igbp4n.png', searchKeys: ['Appliances', 'appliance repair', 'tiny houses  ALL', 'gasfitting/pipefitting', "home-repair",
            "painting-services",
            "furniture-assembly",
            "door-installation",
            "window-repair",
            "shelf-installation",
            "drywall-patching",
            "gutter-cleaning",
            "light-fixtures-installation",
            "picture-hanging",
            "faucet-replacement",
            "minor-electrical-work",
            "tile-repair",
            "fence-repair",
            "deck-maintenance",
            "appliance-installation",
            "plumbing-fixture-installation",
            "handywork"]
        },
        {
          key: 'automotive_/_small_engine_mechanic', value: 'Automotive Mechanic', label: 'Automotive Mechanic', compulsory: '1', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1701668749/trades/bd700232-aa8c-45df-ac27-540ec2611f22_ctor72.png', searchKeys: ['tiny houses  ALL', "car-engine-troubleshooting",
            "brake-pad-replacement",
            "oil-change-instructions",
            "car-battery-maintenance",
            "tire-pressure-check",
            "transmission-fluid-change",
            "spark-plug-replacement",
            "coolant-system-flush",
            "air-filter-replacement",
            "DIY-car-diagnostics",
            "car-bulb-replacement",
            "auto-body-repair",
            "Ford",
            "Chevrolet",
            "Ram",
            "Jeep",
            "GMC",
            "Dodge",
            "Buick",
            "Cadillac",
            "Chrysler",
            "Tesla",
            "Toyota",
            "Honda",
            "Nissan",
            "Volkswagen",
            "Subaru",
            "Hyundai",
            "Kia",
            "Mercedes-Benz",
            "BMW",
            "Audi",
            "car-repair"]
        },
        {
          key: 'framer', value: 'Framer', label: 'Framer', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1686221519/trades/Framer_ftqdvb.png', searchKeys: ['Carpentry', 'carpentry', 'deck building and repair', 'flooring removal ', 'framing', 'historical restoration', 'roofing', 'skylight installation and repair', 'skylight installation and repair', 'solar panels', 'tiny houses  ALL', 'Radeon mitigation', "home-repair",
            "painting-services",
            "furniture-assembly",
            "door-installation",
            "window-repair",
            "shelf-installation",
            "drywall-patching",
            "gutter-cleaning",
            "light-fixtures-installation",
            "picture-hanging",
            "faucet-replacement",
            "minor-electrical-work",
            "tile-repair",
            "fence-repair",
            "deck-maintenance",
            "appliance-installation",
            "plumbing-fixture-installation",
            "handywork"]
        },
        {
          key: 'roofer', value: 'Roofer', label: 'Roofer', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1686221520/trades/Roofer_oo90ge.png', searchKeys: ['gutters and eavestroughs', 'insulation', 'insulation _', 'roofing', 'skylight installation and repair', 'solar panels', 'tiny houses  ALL', 'Chimney sweeping', 'Christmas light install & removal', "roof-installation",
            "roof-repair",
            "roofing-contractor",
            "roof-inspection",
            "shingle-replacement",
            "gutter-installation",
            "skylight-repair",
            "flat-roofing",
            "roof-maintenance",
            "solar-roof",
            "roof-venting", "Owens Corning",
            "GAF",
            "CertainTeed",
            "TAMKO Building Products",
            "IKO", 'roofing']
        },
        { key: 'drywaller_installer', value: 'Drywall  Installer', label: 'Drywall  Installer', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1686221535/trades/DWINSTALL_chqker.png', searchKeys: ['historical restoration', 'painting', 'plastering', 'Ceiling', 'Drywall','boarding',"drywall-installation-repair",
        "gypsum-board-installation-repair",
        "sheetrock-installation-repair",
        "wallboard-installation-repair",
        "popcorn-ceiling-removal",
        "drywall-installation-tools"] },
        {
          key: 'drywaller_taper_/_finsiher', value: 'Drywall Taper / Finisher', label: 'Drywall Taper / Finisher', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1686221535/trades/DWMUD_vxrflc.png', searchKeys: ['Ceiling', 'Drywall', 'Paint', 'tiny houses  ALL', "drywall-finishing",
            "taping-and-mudding",
            "joint-compound-application",
            "smooth-wall-finish",
            "texture-matching",
            "skim-coating",
            "corner-bead-installation",
            "level-5",
            "crack-filling",
            "drywall-taping-tools",
            "mudding",
            "Tap-Tech",
            "Columbia",
            "Level5",
            "NorthStar",
            "Platinum",
            "Wal-Board",
            "Delko",
            "Premier",
            "Marshalltown",
            "Homax",
            "Rasp",
            "Trim-Tex",
            "taping"]
        },
        {
          key: 'interior_designer', value: 'Interior Designer', label: 'Interior Designer', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1686221537/trades/Homedesigner_k5lfx8.png', searchKeys: ['Paint', 'Window coverings', 'Tile', 'cabinetry building and renovating', 'closet and storage solutions', 'concrete work', 'countertop installation', 'fireplace', 'historical restoration', 'home automation', 'home theater', 'lighting', 'millwork', 'mirrors and glass', 'painting', 'railings', 'trim and moldings', 'wallpapering', 'Textiles   re upholstering', 'permit applications', 'tiny houses  ALL', 'Home gym and fitness equipment installation', "space-planning",
            "color-scheme-selection",
            "furniture-selection",
            "lighting-design",
            "fabric-and-textile-selection",
            "flooring-design",
            "wall-coverings-selection",
            "accessories-placement",
            "kitchen-and-bathroom-design",
            "custom-cabinetry-design",
            "design-advice",
            "floor-plan-advice",
            "design-services",
            "interior designing"]
        },
        { key: 'general_contractor', value: 'General Contractor', label: 'General Contractor', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1686221537/trades/GeneralC_nbypmx.png', searchKeys: ['Carpentry', 'accessibility renovations', 'cabinetry building and renovating', 'foundations', 'framing', 'historical restoration', 'landscaping', 'roofing', 'permit applications', 'tiny houses  ALL', 'Structural design / engineering', 'Custom metal fabrication and ironwork'] },
        {
          key: 'painter', value: 'Painter', label: 'Painter', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1686221519/trades/Painter_rdyqvb.png', searchKeys: ['Drywall', 'Paint', 'bathroom / kitchen fixture installation', 'cabinetry building and renovating', 'concrete work', 'fences and gates building and repair', 'floor refinishing', 'furniture refinishing and repair', 'gazebos / sheds building and repair', 'historical restoration', 'painting', 'plastering', 'shutters', 'stair building', 'trim and moldings', 'wallpapering', 'tiny houses  ALL', "house-painting",
            "interior-painting",
            "exterior-painting",
            "residential-painters",
            "commercial-painting-services",
            "paint-color-consultation",
            "surface-preparation",
            "wallpaper-removal",
            "trim-painting",
            "cabinet-refinishing",
            "drywall-repair",
            "texture-removal",
            "Sherwin-Williams",
            "Benjamin Moore",
            "Behr",
            "Wagner",
            "Graco",
            "Purdy",
            "Wooster",
            "Rust-Oleum",
            "Krylon",
            "3M",
            "painting",]
        },
        {
          key: 'mason', value: 'Mason', label: 'Mason', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1686221519/trades/Mason_y4s88d.png', searchKeys: ['concrete work', 'fireplace', 'foundations', 'historical restoration', 'landscaping', 'masonry and bricklaying', 'stonework', 'stucco', 'tiling  Flooring Installer', 'tuck pointing', 'Parging', 'tiny houses  ALL', 'Chimney sweeping', "house-painting",
            "interior-painting",
            "exterior-painting",
            "residential-painters",
            "commercial-painting-services",
            "paint-color-consultation",
            "surface-preparation",
            "wallpaper-removal",
            "trim-painting",
            "cabinet-refinishing",
            "drywall-repair",
            "texture-removal",
            "Sherwin-Williams",
            "Benjamin Moore",
            "Behr",
            "Wagner",
            "Graco",
            "Purdy",
            "Wooster",
            "Rust-Oleum",
            "Krylon",
            "3M",
            "painting",]
        },
        {
          key: 'tiler', value: 'Tiler', label: 'Tiler', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1686221537/trades/Tiler_jqlqrn.png', searchKeys: ['Flooring', 'Tile', 'bathroom / kitchen fixture installation', 'concrete work', 'countertop installation', 'fireplace', 'in floor heating installation', 'historical restoration', 'stonework', 'stucco', 'tile and grout cleaning', 'tiling  Flooring Installer', 'Parging', 'tiny houses  ALL', 'tiling', "tile-installation",
            "ceramic-tile-work",
            "porcelain-tile-installation",
            "mosaic-tile-design",
            "grout-repair",
            "backsplash-installation",
            "bathroom-tile-remodel",
            "shower-tile-installation",
            "floor-tiling",
            "kitchen-tile-design",
            "Schluter",
            "Wedi",
            "Mapei",
            "Custom Building Products",
            "Laticrete",
            "TEC",
            "Ardex",
            "Rubi Tools",
            "QEP",
            "Raimondi",


          ]
        },
        {
          key: 'handyperson', value: 'Handyperson', label: 'Handyperson', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1686221537/trades/Handyperson_lpgsu6.png', searchKeys: ['Appliances', 'Fixtures', 'Ceiling', 'Flooring', 'Furniture', 'Electrical', 'Windows', 'Plumbing', 'Drywall', 'Paint', 'Insulation', 'Windows', 'Window coverings', 'Carpentry', 'Tile  ', 'accessibility renovations', 'appliance repair ', 'bathroom / kitchen fixture installation', 'carpentry', 'cabinetry building and renovating', 'carpentry', 'carpet / rug cleaning', 'carpet installation', 'concrete work', 'closet and storage solutions', 'countertop installation', 'deck building and repair', 'drain services', 'electrical work', 'fences and gates building and repair', 'in floor heating installation', 'floor refinishing', 'flooring installation', 'flooring removal', 'furniture refinishing and repair', 'framing', 'gazebos / sheds building and repair', 'garage doors and hardware installation', 'heating and air conditioning  ', 'gutters and eavestroughs', 'home automation', 'home theater', 'landscaping', 'lighting', 'millwork', 'mirrors and glass', 'painting', 'plastering', 'plumbing', 'railings', 'shutters', 'siding', 'Eavestroughing  Soffit Fascia', 'skylight installation and repair', 'solar panels', 'stair building', 'stonework', 'stucco', 'thermostat installation', 'tile and grout cleaning', 'tiling  Flooring Installer', 'tuck pointing', 'wallpapering', 'windows and doors installation', 'Home Security', 'Parging', 'trim and moldings', 'windows and doors installation', 'woodworking', 'Textiles   re upholstering', 'Christmas light install & removal   ', 'tiny houses  ALL', 'Gardening', 'Pool, hot tub, sauna installation repair', 'Home gym and fitness equipment installation', "home-repair",
            "painting-services",
            "furniture-assembly",
            "door-installation",
            "window-repair",
            "shelf-installation",
            "drywall-patching",
            "gutter-cleaning",
            "light-fixtures-installation",
            "picture-hanging",
            "faucet-replacement",
            "minor-electrical-work",
            "tile-repair",
            "fence-repair",
            "deck-maintenance",
            "appliance-installation",
            "plumbing-fixture-installation",
            "handywork"]
        },
        {
          key: 'flooring_installer', value: 'Flooring Installer / Repairer', label: 'Flooring Installer / Repairer', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1686221535/trades/Floorinstall_qtxasp.png', searchKeys: ['Tile', 'carpet / rug cleaning', 'carpet / rug repair', 'carpet installation', 'floor refinishing', 'flooring installation', 'flooring removal', 'historical restoration', 'tiny houses  ALL', "hardwood-floor-installation",
            "laminate-flooring-installation",
            "tile-flooring-installation",
            "vinyl-flooring-installation",
            "carpet-installation",
            "linoleum-flooring-installation",
            "engineered-wood-flooring",
            "luxury-vinyl-tile-installation",
            "ceramic-tile-installation",
            "bamboo-flooring-installation",
            "flooring-repair",
            "Mohawk-Industries",
            "Shaw-Floors",
            "Armstrong-Flooring",
            "Pergo",
            "Cracked-tile",
            "Laminate vs. hardwood flooring",
            "Vinyl-plank-flooring",
            "Tile-cleaning",
            "Grout-cleaning",
            "flooring installation/repair"]
        },
        {
          key: 'insulator', value: 'Insulator', label: 'Insulator', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1686221537/trades/Insulator_lju0ku.png', searchKeys: ['Insulation', 'heating and air conditioning', 'home theater', 'insulation', 'tiny houses  ALL', "insulation-installation",
            "thermal-insulation",
            "soundproofing",
            "vapor-barrier-installation",
            "energy-efficiency-upgrades",
            "Owens-Corning",
            "Johns-Manville",
            "CertainTeed",
            "Rockwool",
            "Knauf",
            "Dow Building Solutions",
            "Icynene",
            "Spray-foam-insulation",
            "Fiberglass-batts",
            "Insulation-blower-machines",
            "Rockwool",
            "Soprema",
            "Silverboard",
            "Zip",
            "insulating"]
        },
        {
          key: 'welder', value: 'Welder', label: 'Welder', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1686221538/trades/Welder_uhdqr7.png', searchKeys: ['railings', 'tiny houses  ALL', 'Structural design / engineering', 'Custom metal fabrication and ironwork', "welding-services",
            "metal-fabrication",
            "structural-welding",
            "pipe-welding",
            "aluminum-welding",
            "stainless-steel-welding",
            "custom-metalwork",
            "welding-repairs",
            "ornamental-ironwork",
            "welding-inspections",
            "metal-staircase-design", 'welding', "Arc-welding",
            "MIG-welding",
            "TIG-welding",
            "Stick-welding",
            "Welding helmet",
            "Welding gloves",
            "Welding electrode",
            "Welding torch",
            "Welding rod",
            "Welding mask",]
        },
        {
          key: 'drafter_/_architectural', value: 'Drafter / Architectural', label: 'Drafter / Architectural', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1686221535/trades/Drafting_jwuzfn.png', searchKeys: ['tiny houses  ALL', 'accessibility renovations', 'historical restoration', 'permit applications', 'Structural design / engineering', 'Custom metal fabrication and ironwork', "architectural-drafting",
            "blueprint-design",
            "CAD-drafting",
            "architectural-rendering",
            "building-plans",
            "drafting-services",
            "architectural-drawings",
            "permit-drafting"]
        },
        {
          key: 'home_inspector', value: 'Home Inspector', label: 'Home Inspector', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1701666914/trades/Home_Inspector_kzwswg.png', searchKeys: ["home-inspection",
            "structural-analysis",
            "roofing-inspection",
            "electrical-system-check",
            "plumbing-inspection",
            "HVAC-system-evaluation",
            "foundation-assessment",
            "moisture-detection",
            "radon-testing",
            "pest-infestation-check",
            "home-purchase-advice",
            "basement-crack",
            "inspecting"]
        },
        { key: ' other_technician', value: 'Other Technician', label: 'Other Technician', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1701666838/trades/da9b081a-eaf9-4cff-8ad7-d6ef69609fbc_dunchj.png' },
        {
          key: 'solar-eco/green', value: 'Solar Eco / Green', label: 'Solar Eco / Green', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1686221536/trades/Solargreen_hfmh50.png', searchKeys: ['tiny houses  ALL', 'Electrical  ', 'Insulation', 'Windows', 'gutters and eavestroughs', 'heating and air conditioning', 'home automation', 'insulation', 'insulation', 'lighting', 'roofing', 'skylight installation and repair', 'solar panels', 'solar water heating', 'thermostat installation', 'water filtration and purification', 'windows and doors installation', 'tiny houses  ALL', 'Pool, hot tub, sauna installation repair', "solar-energy-systems",
            "renewable-energy-solutions",
            "green-building-design",
            "sustainable-architecture",
            "eco-friendly-products",
            "energy-efficient-homes",
            "solar-panel-installation",
            "green-roofing",
            "rainwater-harvesting",
            "eco-friendly-landscaping",
            "r-2000",
            "net-zero",
            "tiny-home-design",
            "sustainable-design",
            "passive-house-design",
            "energy-rebates",
            "energy-audit", "Nature's Head",
            "Sun-Mar",
            "BioLet",
            "Separett",
            "Dometic",
            "Unique Off-Grid",
            "Coleman",
            "Dickinson Marine",
            "Mr. Heater",
            "Camp Chef",]
        },
        {
          key: 'renovator', value: 'Renovator', label: 'Renovator', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1686814390/trades/55e8a0d2-e81d-4d6c-9988-e42d33210a1a_he5rtj.png', searchKeys: ['Ceiling', 'Flooring', 'Windows', 'Paint', 'Insulation', 'Windows', 'Carpentry', 'Tile', 'accessibility renovations', 'bathroom / kitchen fixture installation', 'carpentry', 'cabinetry building and renovating', 'carpentry', 'carpet / rug cleaning', 'carpet installation', 'closet and storage solutions  ', 'closet and storage solutions', 'countertop installation', 'deck building and repair', 'drain services', 'fences and gates building and repair', 'in floor heating installation', 'floor refinishing', 'flooring installation', 'flooring removal', 'furniture refinishing and repair', 'framing', 'gazebos / sheds building and repair', 'garage doors and hardware installation', 'heating and air conditioning  ', 'gutters and eavestroughs', 'home automation', 'home theater', 'landscaping', 'lighting', 'millwork', 'mirrors and glass', 'painting', 'plastering', 'plumbing', 'railings', 'shutters', 'siding', 'Eavestroughing  Soffit Fascia', 'skylight installation and repair', 'solar panels', 'stair building', 'stonework', 'stucco', 'thermostat installation', 'tile and grout cleaning', 'tiling  Flooring Installer', 'tuck pointing', 'wallpapering', 'windows and doors installation', 'Home Security', 'Parging', 'trim and moldings', 'windows and doors installation', 'woodworking', 'Parging', 'Home Security', 'tiny houses  ALL', 'Abatement ( mold & asbestos)   ', 'Pool, hot tub, sauna installation repair', 'Home gym and fitness equipment installation', 'renovating', "home-remodeling",
            "kitchen-renovation",
            "bathroom-remodel",
            "room-additions",
            "flooring-installation",
            "interior-painting",
            "drywall-repair",
            "cabinet-refinishing",
            "exterior-siding-installation",
            "window-replacement",
            "design-services",]
        },
        {
          key: 'landscaper', value: 'Landscaper', label: 'Landscaper', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1686814458/trades/525d3d15-7db2-453c-b7d5-06410b81f0b2_dk1x0h.png', searchKeys: ['concrete work', 'deck building and repair', 'fences and gates building and repair', 'gazebos / sheds building and repair', 'gutters and eavestroughs', 'landscaping', 'masonry and bricklaying', 'siding', 'Eavestroughing  Soffit Fascia', 'stonework', 'stucco', 'tiling  Flooring Installer', 'Parging', 'tiny houses  ALL', 'Christmas light install & removal', 'Gardening', 'Pool, hot tub, sauna installation repair', 'Custom metal fabrication and ironwork', "landscape-design",
            "garden-planning",
            "hardscape-construction",
            "softscape-installation",
            "lawn-care",
            "irrigation-system-installation",
            "tree-and-shrub-pruning",
            "mulching-services",
            "outdoor-lighting-design",
            "seasonal-maintenance",
            "deck-planning",
            "deck-construction",
            "shed-planning",
            "shed-construction",
            "pergola-design",
            "pergola-construction",
            "Toja Grid",
            "Scotts",
            "John Deere",
            "Husqvarna",
            "STIHL",
            "CIL",
            "Echo",
            "Techo-bloc",
            "Miracle-Gro (soil and plant food)",
            "Ames",
            "landscaping"]
        },
        {
          key: 'assembler', value: 'Assembler', label: 'Assembler', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1686814510/trades/525d3d15-7db2-453c-b7d5-06410b81f0b2_ffowcw.png', searchKeys: ['Appliances', 'Fixtures', 'Furniture', 'appliance repair', 'bathroom / kitchen fixture installation', 'closet and storage solutions', 'garage doors and hardware installation', 'gazebos / sheds building and repair', 'gutters and eavestroughs', 'tiny houses  ALL', 'Home gym and fitness equipment installation', "furniture assembly",
            "outdoor furniture",
            "indoor furniture",
            "ikea assembly",
            "patio furniture",
            "bar-b-q assembly",
            "playground equipment",
            "home gym equipment",
            "swing set assembly",
            "shed assembly",
            "assembling",
            "Hampton Bay",
            "Martha Stewart Living",
            "Hampton Bay",
            "StyleWell",
            "Noble House",
            "Weber",
            "Dyna-Glo",
            "Char-Broil",
            "Nexgrill",
            "Traeger",
            "Broil-King",
            "Broli-mate",
            "Rubbermaid",
            "Suncast",
            "Arrow",
            "Lifetime",
            "Keter",
            "Backyard Discovery",
            "Gorilla Playsets",
            "Creative Cedar Designs",
            "Swing-N-Slide",
            "Flat-pack",
            "Hampton Bay",
            "LifeArt Cabinetry",
            "Home Decorators Collection",
            "Plywell",
            "Eurostyle"]
        },
        {
          key: 'Restoration_Environmental', value: 'Restoration / Environmental', label: 'Restoration / Environmental', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1686814555/trades/525d3d15-7db2-453c-b7d5-06410b81f0b2_volj9o.png', searchKeys: ['Drywall', 'carpet / rug cleaning', 'drain services', 'flooring removal', 'foundations', 'historical restoration', 'mold detection and remediation', 'waterproofing', 'Textiles   re upholstering', 'tiny houses  ALL', 'Abatement ( mold & asbestos)', 'Radeon mitigation', 'Water infiltration / Restoration', 'restoring', "mold-remediation",
            "asbestos-abatement",
            "water-damage-restoration",
            "fire-damage-repair",
            "lead-paint-removal",
            "indoor-air-quality-testing",
            "biohazard-cleanup",
            "odor-removal",
            "hazardous-material-disposal",
            "disaster-recovery-services",
            "flood",
            "radon",]
        },
        {
          key: 'Exterminator/PestControl', value: 'Exterminator / Pest Control', label: 'Exterminator / Pest Control', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1687842516/trades/e0119468-fabe-455d-a38b-45d254556449_1_zgcyuj.png', searchKeys: ["pest-control-services",
            "termite-inspection",
            "rodent-extermination",
            "bed-bug-treatment",
            "insect-removal",
            "wasp-nest-removal",
            "ant-eradication",
            "cockroach-infestation",
            "spider-extermination",
            "wildlife-removal",
            "Terminix",
            "Orkin",
            "Raid",
            "Ortho",
            "Hot Shot",
            "Spectracide",
            "Black Flag",
            "Zevo",
            "Terro",
            "Victor",
            "Tomcat",
            "Humane-traps",
            "extermination"]

        },
        {
          key: 'makers', value: 'Makers', label: 'Makers', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1701667119/trades/7acae552-1b31-431b-9f75-a8e053258051_eciw2e.png', searchKeys: []
        },
        {
          key: 'Bicycle_Maintenance', value: 'Bicycle Maintenance / Repair', label: 'Bicycle Maintenance / Repair', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1701667187/trades/382b524f-9cde-4472-a55b-51233139e896_xtbsz5.png', searchKeys: ["bicycle-repair",
            "bike-maintenance",
            "pedal-powered-bike-service",
            "bicycle-tune-up",
            "bike-gear-adjustment",
            "Trek",
            "Specialized",
            "Giant",
            "Cannondale",
            "Santa Cruz",
            "Yeti Cycles",
            "Scott",
            "Pivot Cycles",
            "Norco",
            "Kona",
            "Shimano",
            "SRAM",
            "RockShox",
            "Fox Racing Shox",
            "Campagnolo",
            "Mavic",
            "DT Swiss",
            "Race Face",
            "Maxxis",
            "Continental",
            "bicycle maintaining/repairing"]
        },
        {
          key: 'Appliance Repairer_Installer', value: 'Appliance Repairer / Installer', label: 'Appliance Repairer / Installer', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1697003003/trades/Appliance_owq4wr.png', searchKeys: ['appliance repair/installing', "appliance installation",
            "appliance repair",
            "dishwasher installation",
            "refrigerator repair",
            "washer and dryer setup",
            "oven and range installation",
            "microwave repair",
            "garbage disposal installation",
            "HVAC system maintenance",
            "water heater repair",
            "appliance parts advice", "Whirlpool",
            "Samsung",
            "LG",
            "KitchenAid",
            "Maytag",
            "Bosch",
            "Frigidaire",
            "General-Electric (GE)",
            "Electrolux",
            "Panasonic",]
        },
        {
          key: 'E-Bike_Maintenance_Repair', value: 'E-Bike Maintenance / Repair', label: 'E-Bike Maintenance / Repair', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1701667187/trades/382b524f-9cde-4472-a55b-51233139e896_xtbsz5.png', searchKeys: ["e-bike-repair",
            "electric-bicycle-maintenance",
            "e-bike-battery-service",
            "electric-bike-motor-repair",
            "e-bike-controller-troubleshooting", "Bosch",
            "Shimano",
            "Yamaha",
            "Brose",
            "Bafang",
            "Go SwissDrive",
            "TranzX",
            "Panasonic",
            "TDCM",
            "Fazua",
            "Bosch",
            "Shimano",
            "Samsung SDI",
            "LG Chem",
            "Sony",
            "TranzX"
          ]
        },
        {
          key: 'Drywall Contractor', value: 'Drywall Contractor', label: 'Drywall Contractor', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1701669016/trades/c6e8c15b-47bf-42a6-8c27-c8e2527cbc76_t8tha1.png', searchKeys: ["drywall-installation",
            "drywall-repair",
            "sheetrock-maintenance",
            "drywall-taping",
            "plastering-services",
            "popcorn-ceiling-removal",
            "texture-matching",
            "drywall-finishing",
            "wallboard-installation",
            "soundproofing-walls",
            "drywall-planning",
            "drywall-design",
            "drywall-tools",]
        },
        {
          key: ' Electrician_Residential', value: 'Electrician ( Residential )', label: 'Electrician ( Residential )', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1701668954/trades/588b821c-8234-45dc-8734-34d14f7bac15_tfqaqn.png', searchKeys: ["electrical-wiring",
            "lighting-installation",
            "outlet-repair",
            "circuit-breaker-installation",
            "electrical-panel-upgrade",
            "wiring-inspection",
            "electrical-code-compliance",
            "surge-protector-installation",
            "GFCI-outlet-installation",
            "ceiling-fan-installation",
            "3-way-switch",
            "three-way-switch",
            "appliance-wiring",
            "generator",
            "electric-vehicle-charging-setup",
            "smart-home-systems",
            "electrical-tools", "Klein-Tools",
            "Milwaukee",
            "Greenlee",
            "Southwire",
            "Fluke",
            "Knipex",
            "Leviton", "electrical"]
        },
        {
          key: 'Electrician_(Solar)', value: 'Electrician ( Solar )', label: 'Electrician ( Solar )', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1701667809/trades/27b8df0d-53b1-4b89-9e0a-a5350df05eef_wvuj5j.png', searchKeys: ["electrical", "solar-panel-installation",
            "photovoltaic-system-maintenance",
            "renewable-energy-wiring",
            "battery-storage-installation",
            "solar-inverter-repair",
            "energy-efficiency-audit",
            "electric-vehicle-charging-setup",
            "off-grid-power-solutions",
            "sustainable-lighting-design",
            "eco-friendly-electrical-upgrades",
            "smart-thermostat",
            "smart-home-systems",
            "off-grid-design",
            "tiny-home-system",
            "r-2000",
            "net-zero",]
        },
        {
          key: 'Exterior_Finisher', value: 'Exterior Finisher', label: 'Exterior Finisher', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1701668878/trades/05f74f2f-183b-4ce5-b1a1-e91407fbe9b6_qmwulo.png', searchKeys: ["siding-installation",
            "stucco-application",
            "exterior-painting",
            "roofing-installation",
            "window-cladding",
            "door-finishing",
            "deck-staining",
            "soffit-and-fascia-installation",
            "exterior-trim-work",
            "brick-veneer-installation",
            "exterior-finish-advice",
            "exterior-finish-design",
            "James-Hardie",
            "LP-SmartSide",
            "CertainTeed",
            "Mastic",
            "Royal-Building-Products",
            "Nichiha",
            "Alside",
            "Kaycan",
            "Georgia-Pacific",
            "Cedar-shingle",
            "Mitten",
            "Building-Envelope",
            "House-wrap",



          ]
        },
        {
          key: 'Horticulturist', value: 'Horticulturist', label: 'Horticulturist', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1701668834/trades/72006bae-8674-493b-b655-cf912b6f5046_qo7ccy.png', searchKeys: ["plant-breeding",
            "soil-analysis",
            "pest-management",
            "landscape-design",
            "crop-cultivation",
            "garden-maintenance",
            "plant-disease-diagnosis",
            "irrigation-planning",
            "greenhouse-management",
            "environmental-restoration",
            "vegetable-garden-advice",
            "living-roof",]
        },
        {
          key: 'Landscape_Designer', value: 'Landscape Designer', label: 'Landscape Designer', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1701667934/trades/ac7815e9-fb7d-41e2-904e-9be29802fa8d_u944zp.png', searchKeys: ["landscape-design",
            "site-analysis",
            "plant-selection",
            "hardscape-planning",
            "garden-layout",
            "outdoor-living-design",
            "irrigation-system-design",
            "lighting-scheme",
            "sustainable-landscaping",
            "project-management", "Paver-patio-designs",
            "Garden-edging",
            "Water-feature",
            "Drought-resistant-plants",
            "Retaining-wall",
            "Mulch",
            "Topsoil",
            "Unilock",]
        },
        {
          key: 'makersWoods', value: 'Makers ( Wood, Glass, Pottery, Metal )', label: 'Makers ( Wood, Glass, Pottery, Metal )', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1701668148/trades/ed835804-4e40-4a1a-88e5-e8649a1a7779_s7gwqs.png', searchKeys: ['making', "woodworkers",
            "furniture",
            "stained-glass",
            "wood-carvers",
            "cabinetry-makers",
            "blacksmiths",
            "metalworkers",
            "pottery-and-ceramics",
            "glassblowers",
            "sculptors",
            "custom-woodwork",
            "kiln",
            "restoration",]
        },
        {
          key: 'Planner_Permits', value: 'Planner / Permits', label: 'Planner / Permits', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1701668279/trades/74c2bb72-d7ca-416f-a86a-23bbed8a1aef_c2v7dl.png', searchKeys: ["tiny-home-permitting",
            "net-zero-building-regulations",
            "sustainable-construction-guidance",
            "historic-preservation-compliance",
            "coastal-building-permits",
            "mountain-region-construction",
            "urban-development-planning",
            "rural-building-permits",
            "solar-panel-installation-regulations",
            "ADA-compliance-for-buildings",
            "earthquake-resistant-structures",
            "floodplain-development-guidelines",
            "off-grid-living-regulations",
            "energy-efficient-building-codes",
            "passive-house-design-standards",
            "wildfire-resistant-construction",
            "backyard-cottage-approvals",
            "green-building-certifications",
            "commercial-building-permits",
            "residential-zoning-consultation", "Building-code",
            "NBIN", 'planning and permitting', 'mechanic']
        },
        {
          key: 'Small_Engine_Mechanic', value: 'Small Engine Mechanic ( Marine )', label: 'Small Engine Mechanic ( Marine )', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1696317138/trades/f383a293-ba49-4213-bdef-11e03642b1c1_dl2fyf.png', searchKeys: ["marine-engine-repair",
            "boat-motor-maintenance",
            "outboard-motor-service",
            "inboard-engine-troubleshooting",
            "marine-diesel-engine-repair", "Mercury Marine",
            "Yamaha Marine",
            "Evinrude",
            "Suzuki Marine",
            "Honda Marine",
            "Volvo Penta",
            "Tohatsu",
            "Lehr", 'mechanic']
        },





        {
          key: 'makersTextile', value: 'Makers ( Textile & Fabric Arts )', label: 'Makers ( Textile & Fabric Arts )', compulsory: '0', defaultImg: 'https://res.cloudinary.com/dj3yab5rl/image/upload/v1701667119/trades/7acae552-1b31-431b-9f75-a8e053258051_eciw2e.png', searchKeys: ["sewing",
            "serger-repair",
            "sewing-machine-repair",
            "leatherworkers",
            "upholstery",
            "knitting",
            "crochet",
            "textiles",
            "quilters",
            "embroidery",
            "tailors",
            "alterations",
            "fabric-crafts",
            "sewn-clothing",
            "hand-knitted",]
        },
      ],
    },
  },
  {
    id: 'CompulsoryTraditionalTrades',
    label: 'I am a ...',
    type: 'CompulsoryTraditionalFilter',
    group: 'secondary',
    queryParamNames: ['CompulsoryTraditional_filter'],
    config: {
      options: [
        // { key: 'none', label: 'select' },
        { key: 'Skilled', label: 'Skilled', value: 'Skilled' },
        { key: 'Certified', label: 'Certified', value: 'Certified' },
        { key: 'Apprentice', label: 'Apprentice', value: 'Apprentice' },
        { key: 'Journeyperson', label: 'Journeyperson', value: 'Journeyperson' },
        { key: 'Master', label: 'Master', value: 'Master' },
        { key: 'Red Seal', label: 'Red Seal', value: 'Red Seal' },

      ],
    },
  },
  {
    id: 'NonCompulsoryTraditionalTrades',
    label: 'I am a ...',
    type: 'NonCompulsoryTraditionalFilter',
    group: 'secondary',
    queryParamNames: ['NonCompulsoryTraditional_filter'],
    config: {
      options: [
        // { key: 'none', label: 'select' },
        { key: 'Skilled', label: 'Skilled', value: 'Skilled' },
        { key: 'Certified', label: 'Certified', value: 'Certified' },
        { key: 'Apprentice', label: 'Apprentice', value: 'Apprentice' },
        { key: 'Journeyperson', label: 'Journeyperson', value: 'Journeyperson' },
        { key: 'Master', label: 'Master', value: 'Master' },
        { key: 'Red Seal', label: 'Red Seal', value: 'Red Seal' },

      ],
    },
  },
  {
    id: 'yearExperience',
    label: 'I am a ...',
    type: 'yearExperienceFilter',
    group: 'secondary',
    queryParamNames: ['yearExperience_filter'],
    config: {
      options: new Array(66).fill("1").map((v, i) => ({ key: moment().subtract((66 - (i + 1)), 'years').format('YYYY'), label: (moment().subtract((66 - (i + 1)), 'years').format('YYYY')) }))
      // [
      //   // { key: 'none', label: 'select' },
      //   { key: '2001', label: '2001' },
      //   { key: '2002', label: '2002' },
      //   { key: '2003', label: '2003' },
      // ],
    },
  },
  {
    id: 'bufferTime',
    label: 'bufferTime',
    type: 'bufferTimeFilter',
    group: 'secondary',
    queryParamNames: ['bufferTime_filter'],
    config: {
      options: [
        // { key: 'none', label: 'select' },
        { key: '15 minutes', label: '15 minutes' },
        { key: '30 minutes', label: '30 minutes' },
        { key: '45 minutes', label: '45 minutes' },
        { key: '60 minutes', label: '60 minutes' },
      ],
    },
  },
  {
    id: 'consultation',
    label: 'consultation',
    type: 'consultationFilter',
    group: 'secondary',
    queryParamNames: ['consultation_filter'],
    config: {
      options: [
        // { key: 'none', label: 'select' },
        { key: 'I am hoping they can help solve my problem and troubleshoot.', label: 'I am hoping they can help solve my problem and troubleshoot.', value: 'I am hoping they can help solve my problem and troubleshoot.' },
        { key: 'I want clarification on the operation of a tool or the application of a material or install.', label: 'I want clarification on the operation of a tool or the application of a material or install.', value: 'I want clarification on the operation of a tool or the application of a material or install.' },
        { key: 'I want advisement on a project idea or planning.', label: 'I want advisement on a project idea or planning.', value: 'I want advisement on a project idea or planning.' },
        { key: 'I am looking for peer training and mentorship in a specific trade.', label: 'I am looking for peer training and mentorship in a specific trade.', value: 'I am looking for peer training and mentorship in a specific trade.' },
        { key: 'I am hoping to see a demo or a how-to on the operation of a tool or the application of a material or install.', label: 'I am hoping to see a demo or a how-to on the operation of a tool or the application of a material or install.', value: 'I am hoping to see a demo or a how-to on the operation of a tool or the application of a material or install.' },
        { key: 'I am looking to get some answers to my questions.', label: 'I am looking to get some answers to my questions.', value: 'I am looking to get some answers to my questions.' },
        { key: 'Other', label: 'Other', value: 'Other' },
      ],
    },
  },
  {
    id: 'skillLevel',
    label: 'skillLevel',
    type: 'skillLevelFilter',
    group: 'secondary',
    queryParamNames: ['skillLevel_filter'],
    config: {
      options: [
        // { key: 'none', label: 'select' },
        { key: 'Beginner', label: 'Beginner', value: 'Beginner' },
        { key: 'Intermediate', label: 'Intermediate', value: 'Intermediate' },
        { key: 'Skilled', label: 'Skilled', value: 'Skilled' },
        { key: 'Fellow Pro', label: 'Fellow Pro', value: 'Fellow Pro' },
      ],
    },
  },
  {
    id: 'canadaProvience',
    label: 'canadaProvience',
    type: 'canadaProvience',
    group: 'secondary',
    queryParamNames: ['canadaProvience_filter'],
    config: {
      options: [
        // { key: 'none', label: 'select' },
        { key: 'Alberta', label: 'Alberta', value: 'Alberta' },
        { key: 'British_Columbia', label: 'British Columbia', value: 'British Columbia' },
        { key: 'Manitoba', label: 'Manitoba', value: 'Manitoba' },
        { key: 'New_Brunswick', label: 'New Brunswick', value: 'New Brunswick' },
        { key: 'Newfoundland_and_Labrador', label: 'Newfoundland and Labrador', value: 'Newfoundland and Labrador' },
        { key: 'Nova_Scotia', label: 'Nova Scotia', value: 'Nova Scotia' },
        { key: 'Ontario', label: 'Ontario', value: 'Ontario' },
        { key: 'Prince_Edward_Island', label: 'Prince Edward Island', value: 'Prince Edward Island' },
        { key: 'Quebec', label: 'Quebec', value: 'Quebec' },
        { key: 'Saskatchewan', label: 'Saskatchewan', value: 'Saskatchewan' },
        { key: 'Northwest_Territories', label: 'Northwest Territories', value: 'Northwest Territories' },
        { key: 'Nunavut', label: 'Nunavut', value: 'Nunavut' },
        { key: 'Yukon', label: 'Yukon', value: 'Yukon' },
      ],
    },
  },

  {
    id: 'declineReason',
    label: 'declineReason',
    type: 'declineReasonFilter',
    group: 'secondary',
    queryParamNames: ['declineReason_filter'],
    config: {
      options: [
        // { key: 'none', label: 'select' },
        { key: 'Scheduling_Conflict', label: 'Scheduling Conflict', value: 'Scheduling Conflict' },
        { key: 'Insufficent_amount_of_time_booked', label: 'Insufficent amount of time booked', value: 'Insufficent amount of time booked' },
        { key: 'Not_my_area_of_expertise', label: 'Not my area of expertise', value: 'Not my area of expertise' },
      ],
    },
  },
  {
    id: 'bookedSession',
    label: 'bookedSession',
    type: 'bookedSessionFilter',
    group: 'secondary',
    queryParamNames: ['bookedSession_filter'],
    config: {
      options: [
        // { key: 'none', label: 'select' },
        { key: '1', label: '1 hour' },
        { key: '3', label: '3 hours' },
        { key: '8', label: '8 hours' },
        { key: '12', label: '12 hours' },
        { key: '24', label: '24 hours' },
      ],
    },
  },
]


export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

