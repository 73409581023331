import { getCollection, transactionUpdate, updateCollection } from '../../util/api';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

const requestAction = actionType => params => ({ type: actionType, payload: { params } });
const successAction = actionType => result => ({ type: actionType, payload: result.data });
const errorAction = actionType => error => ({ type: actionType, payload: error, error: true });

export const FETCH_COLLECTION_REQUEST = 'app/mongos/FETCH_COLLECTION_REQUEST';
export const FETCH_COLLECTION_SUCCESS = 'app/mongos/FETCH_COLLECTION_SUCCESS';
export const FETCH_COLLECTION_ERROR = 'app/mongos/FETCH_COLLECTION_ERROR';
export const ADMIN_LISTING_SUCCESS = 'app/purchaseTranscript/ADMIN_LISTING_SUCCESS';
export const ADMIN_LISTING_ERROR = 'app/purchaseTranscript/ADMIN_LISTING_ERROR';

const initialState = {
    collectionData: null,
    fetchCollectionInProgress: false,
    fetchCollectionError: null,
    adminListingIds: [],
    adminListingError: false,
};
const resultIds = data => data.map(l => {
    return l.id
});
export default function reducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_COLLECTION_REQUEST:
            return {
                ...state,
                fetchCollectionInProgress: true,
                fetchCollectionError: false
            };
        case FETCH_COLLECTION_SUCCESS:
            return {
                ...state,
                fetchCollectionInProgress: false,
                collectionData: (payload),
            };
        case FETCH_COLLECTION_ERROR:
            return {
                ...state,
                fetchCollectionInProgress: false,
                fetchCollectionError: payload

            };
        case ADMIN_LISTING_SUCCESS:
            return { ...state, adminListingIds: resultIds([payload.data.data]), adminListingError: false, };
        case ADMIN_LISTING_ERROR:
            return { ...state, adminListingError: payload };
        default:
            return state;
    }
}

const adminListingSuccess = (response) => ({
    type: ADMIN_LISTING_SUCCESS,
    payload: response
});

const adminListingError = (error) => ({
    type: ADMIN_LISTING_ERROR,
    payload: error
});

export const fetchCollectionRequest = requestAction(FETCH_COLLECTION_REQUEST);
export const fetchCollectionSuccess = successAction(FETCH_COLLECTION_SUCCESS);
export const fetchCollectionError = errorAction(FETCH_COLLECTION_ERROR);


export const getSpecificTranscript = (txId) => async (dispatch, getState, sdk) => {
    const { uuid } = getState()?.user?.currentUser?.id || '';
    return getCollection({
        tableName: "transcripts", query: {
            txId: txId
        }
    })
        .then(res => {
            dispatch(fetchCollectionSuccess(res))
            return res
        })
        .catch(err => dispatch(fetchCollectionError(err)));

}

export const updateTranscriptDetail = (mongoId, url) => async (dispatch, getState, sdk) => {
    return updateCollection({
        tableName: "transcripts", id: mongoId, payload: url
    })
        .then(res => { dispatch(fetchCollectionSuccess(res)) })
        .catch(err => dispatch(fetchCollectionError(err)));
}
export const updateTransactionMeta = (id, transactionRole) => async (dispatch, getState, sdk) => {
    return transactionUpdate({
        id: id,
        transactionRole: transactionRole
    })
        .then(res => { })
        .catch(err => console.error(err));
}


export const loadData = (params, search) => (dispatch, getState, sdk) => {
    const { uuid } = getState()?.user?.currentUser?.id || '';

    dispatch(fetchCollectionRequest());
    console.log(process.env.REACT_APP_TRANSCRIPT_LISTING,'process.env.REACT_APP_TRANSCRIPT_LISTING');
    // console.log(uuid);
    return sdk.transactions.query({
        lastTransitions: ["transition/partial-complete", "transition/complete", "transition/payoutWithTipalti", "transition/review-1-by-customer", "transition/payoutAfterReview", "transition/reviewAfterPayout", "transition/capture-full-payment"],
        include: ['booking','provider','customer'],
    })
    // 65263859-b83f-4f82-9420-2ff3f060eb00
        .then(response => {
            return sdk.listings
                .show({
                    id: process.env.REACT_APP_TRANSCRIPT_LISTING,  //this is the listing id of admin
                    include: ['author', 'images'],
                    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
                })
                .then(res => {

                    dispatch(adminListingSuccess(res));
                    dispatch(addMarketplaceEntities(res));


                    if (response.data && response.data.data && response.data.data.length) {
                        let transcriptData = [];
                        let promises = response.data.data.map((val) => {
                            return getCollection({
                                tableName: "transcripts", query: {
                                    txId: val.id.uuid
                                }
                            })//,query:{txId: id}
                                .then(res => {
                                    let parseData = JSON.parse(res.data);
                                    if (parseData && parseData.length) {
                                        Array.isArray(response.data.included) && response.data.included.length && response.data.included.map((data) => {
                                            // console.log(val.relationships.customer.data.id.uuid,data.id.uuid,'ram');
                                            if (val.relationships.booking.data.id.uuid == data.id.uuid) {
                                                // console.log('attributes');
                                                Object.assign(val.attributes, data);
                                                response.data.included.map((result) => {
                                                    if (val.relationships.customer.data.id.uuid == result.id.uuid) {
                                                        // console.log('customer',val.relationships.provider.data.id.uuid,result.id.uuid);
                                                        Object.assign(val.attributes.metadata, result)
                                                       
                                                    }
                                                    if (val.relationships.provider.data.id.uuid == result.id.uuid) {
                                                        // console.log('provider','ram',val);
                                                        Object.assign(val.attributes.protectedData, result);
                                                        transcriptData.push(val);
                                                    }
                                                })
                                            }


                                        })
                                    }
                                })
                                .catch(err => dispatch(fetchCollectionError(err)));
                        })
                        Promise.all(promises)
                            .then(() => {
                                let data = {data: transcriptData}
                                // This will be executed after all promises have resolved
                                dispatch(fetchCollectionSuccess(data));
                            });
                    } else {
                        dispatch(fetchCollectionSuccess({ data: { data: [] } }));
                    }
                })


        })
        .catch(e => {
            dispatch(adminListingError(storableError(e)));
            throw e;
        });
}
