import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { Modal, PrimaryButton } from '..';
import { DeclineBookingForm } from '../../forms';

import css from './CancelBookingModal.module.css';
import CancelBookingForm from '../../forms/CancelBookingForm/CancelBookingForm';

const CancelBookingModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    onSubmit,
    submitInProgress,
    submitError,
    isProvider
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const cancelHeading = intl.formatMessage({ id: 'CancelBookingModal.cancelHeading' });

  return (
    <Modal
      id={id}
      isOpen={!!isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      {isOpen == 'DeclineBooking'
        ? <DeclineBookingForm
          initialValues={{ declineReason: 'Scheduling Conflict' }}
          onSubmit={onSubmit}
          inProgress={submitInProgress}
        // submitError={submitError}
        />
        :
        (<CancelBookingForm
          isProvider={isProvider}
          initialValues={{ declineReason: 'Scheduling Conflict' }}
          onSubmit={onSubmit}
          inProgress={submitInProgress}
        // submitError={submitError}
        />)

        }
    </Modal>
  );
};

const { string } = PropTypes;

CancelBookingModal.defaultProps = {
  className: null,
  rootClassName: null,
};

CancelBookingModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
};

export default injectIntl(CancelBookingModal);
