import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput, FieldCheckbox, FieldMultiSelect } from '../../components';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';
import * as validators from '../../util/validators';

import css from './EditListingDescriptionForm.module.css';

const TITLE_MAX_LENGTH = 60;
const Bio_MAX_LENGTH = 500;

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    keepDirtyOnReinitialize={true}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values
      } = formRenderProps;

      const businessName = intl.formatMessage({ id: 'EditListingDescriptionForm.business' });
      const businessPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.businessPlaceholder',
      });
      const businessRequired = intl.formatMessage({
        id: 'EditListingDescriptionForm.businessRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      const websiteName = intl.formatMessage({ id: 'EditListingDescriptionForm.website' });
      const websitePlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.websitePlaceholder',
      });
      const websiteRequired = intl.formatMessage({
        id: 'EditListingDescriptionForm.websiteRequired',
      });

      const businessBioMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.businessBio' });
      const businessBioPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.businessBioPlaceholder',
      });
      const businessBioRequired = intl.formatMessage({
        id: 'EditListingDescriptionForm.businessBioRequired',
      });

      const languageLabel = intl.formatMessage({ id: 'EditListingDescriptionForm.languageLabel' });
      const languagePlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.languagePlaceholder',
      });
      const languageRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.languageRequired',
      });

      const languageRequired = validators.required(languageRequiredMessage);
      const instagramUrlLabel = intl.formatMessage({ id: 'EditListingDescriptionForm.instagramUrlLabel' });
      const instagramUrlPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.instagramUrlPlaceholder',
      });
      const instagramUrlRequired = intl.formatMessage({
        id: 'EditListingDescriptionForm.instagramUrlRequired',
      });

      const linkedinUrlLabel = intl.formatMessage({ id: 'EditListingDescriptionForm.linkedinUrlLabel' });
      const linkedinUrlPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.linkedinUrlPlaceholder',
      });
      const linkedinUrlRequired = intl.formatMessage({
        id: 'EditListingDescriptionForm.linkedinUrlRequired',
      });

      const tiktokURLLabel = intl.formatMessage({ id: 'EditListingDescriptionForm.tiktokURLLabel' });
      const tiktokURLPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.tiktokURLPlaceholder',
      });
      const tiktokUrlRequired = intl.formatMessage({
        id: 'EditListingDescriptionForm.tiktokUrlRequired',
      });

      const facebookUrlLabel = intl.formatMessage({ id: 'EditListingDescriptionForm.facebookUrlLabel' });
      const TradeLabelCheckbox = intl.formatMessage({ id: 'EditListingDescriptionForm.TradeLabelCheckbox' });
      const ProsLabelCheckbox = intl.formatMessage({ id: 'EditListingDescriptionForm.ProsLabelCheckbox' });
      const businessRelatedCheckbox = intl.formatMessage({id: 'EditListingDescriptionForm.BussinessTax'});
      const facebookUrlPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.facebookUrlPlaceholder',
      });
      const facebookUrlRequired = intl.formatMessage({
        id: 'EditListingDescriptionForm.facebookUrlRequired',
      });

      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const language_filter = findOptionsForSelectFilter('language', config.custom.filters);

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <div className={css.rowForm}>
            <FieldTextInput
              id="businessName"
              name="businessName"
              className={css.rowInput}
              type="text"
              label={businessName}
              placeholder={businessPlaceholder}
              maxLength={TITLE_MAX_LENGTH}
              // validate={composeValidators(required(businessRequired), maxLength60Message)}
              autoFocus
            />
            <FieldTextInput
              id="website"
              name="website"
              className={css.rowInput}
              type="text"
              label={websiteName}
              placeholder={websitePlaceholder}
            />
          </div>

          <FieldTextInput
            id="businessBio"
            name="businessBio"
            className={css.fullInput}
            type="textarea"
            label={businessBioMessage}
            placeholder={businessBioPlaceholder}
            maxLength={Bio_MAX_LENGTH}
            validate={composeValidators(required(businessBioRequired))}
          />
          <div className={css.rowForm}>
            <FieldCheckbox
              id="charge_taxes"
              className={css.rowCheckBox}
              name="charge_taxes"
              label={businessRelatedCheckbox}
            />
          </div>

          <FieldMultiSelect
            isMulti={true}
            isRequired={true}
            className={css.fullInput}
            id="language"
            name="language"
            label={languageLabel}
            fontClass={css.fontClass}
            options={language_filter}
            autoComplete={"categories"}
            validate={languageRequired}
            defaultValue={values.language || ""}
          />

          <div className={css.rowForm}>
            <FieldTextInput
              id="instagramUrl"
              name="instagramUrl"
              className={css.rowInput}
              type="text"
              label={instagramUrlLabel}
              placeholder={instagramUrlPlaceholder}
            />
            <FieldTextInput
              id="linkedinUrl"
              name="linkedinUrl"
              className={css.rowInput}
              type="text"
              label={linkedinUrlLabel}
              placeholder={linkedinUrlPlaceholder}
            />
          </div>

          <div className={css.rowForm}>
            <FieldTextInput
              id="tiktokURL"
              name="tiktokURL"
              className={css.rowInput}
              type="text"
              label={tiktokURLLabel}
              placeholder={tiktokURLPlaceholder}
            />
            <FieldTextInput
              id="facebookUrl"
              name="facebookUrl"
              className={css.rowInput}
              type="text"
              label={facebookUrlLabel}
              placeholder={facebookUrlPlaceholder}
            />
          </div>
          <div className={css.rowForm}>
            <FieldCheckbox
              id="trade"
              className={css.rowCheckBox}
              name="localHireTrade"
              label={TradeLabelCheckbox}
            />
            <FieldCheckbox
              id="pros"
              className={css.rowCheckBox}
              name="mentoringFellowPros"
              label={ProsLabelCheckbox}
            />
          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>

      </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
