import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './ListingIconCard.module.css';

const LOCATION = "location";
const TRADECHECK = "tradecheck";
const EYEVIEW = "eye";
const INFO = "info";

const ListingIconCard = props => {
  const { className, rootClassName, type } = props;
  const classes = classNames(rootClassName || css.root, className);

  switch (type) {
    case LOCATION:
      return (
        <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.99999 9.5C7.45833 9.5 7.85083 9.3285 8.1775 8.9855C8.50416 8.6425 8.66722 8.23067 8.66666 7.75C8.66666 7.26875 8.50333 6.85663 8.17666 6.51363C7.84999 6.17062 7.45777 5.99942 6.99999 6C6.54166 6 6.14916 6.1715 5.82249 6.5145C5.49583 6.8575 5.33277 7.26933 5.33333 7.75C5.33333 8.23125 5.49666 8.64337 5.82333 8.98637C6.14999 9.32938 6.54222 9.50058 6.99999 9.5ZM6.99999 18.25C4.76388 16.2521 3.09388 14.3965 1.98999 12.6832C0.886106 10.97 0.333884 9.38392 0.333328 7.925C0.333328 5.7375 1.00361 3.99479 2.34416 2.69687C3.68472 1.39896 5.23666 0.75 6.99999 0.75C8.76388 0.75 10.3161 1.39896 11.6567 2.69687C12.9972 3.99479 13.6672 5.7375 13.6667 7.925C13.6667 9.38333 13.1144 10.9694 12.01 12.6832C10.9055 14.3971 9.23555 16.2527 6.99999 18.25Z" fill="#DA8240" />
        </svg>
      );
    case TRADECHECK:
      return (
        <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M3.45818 7.65003L0.468725 5.54873C-0.156242 5.08289 -0.156242 4.328 0.468725 3.85977C1.09529 3.39394 2.10966 3.39394 2.73623 3.85977L4.38922 4.9661L8.0954 0.497238C8.61139 -0.0366814 9.60974 -0.160904 10.3325 0.223709C11.0504 0.608322 11.2154 1.35485 10.6994 1.88997L5.89235 7.50191C5.6039 7.80292 5.13117 8 4.59114 8C4.16808 8 3.76105 7.87578 3.45818 7.65003Z" fill="#FDB714" />
        </svg>
      );
    case INFO:
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C13 7.71667 12.904 7.479 12.712 7.287C12.52 7.095 12.2827 6.99933 12 7C11.7167 7 11.479 7.096 11.287 7.288C11.095 7.48 10.9993 7.71733 11 8C11 8.28333 11.096 8.521 11.288 8.713C11.48 8.905 11.7173 9.00067 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22Z" fill="black" />
        </svg>
      );
    case EYEVIEW:
      return (
        <svg width="24px" height="24px" viewBox="0 0 0.72 0.72" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path cx="12" cy="12" r="3.5" stroke="#222222" d="M0.465 0.36A0.105 0.105 0 0 1 0.36 0.465A0.105 0.105 0 0 1 0.255 0.36A0.105 0.105 0 0 1 0.465 0.36z" strokeWidth="0.03" fill='none' />
          <path d="M0.606 0.328C0.617 0.342 0.623 0.349 0.623 0.36C0.623 0.371 0.617 0.378 0.606 0.392C0.563 0.444 0.469 0.54 0.36 0.54C0.251 0.54 0.157 0.444 0.114 0.392C0.103 0.378 0.097 0.371 0.097 0.36C0.097 0.349 0.103 0.342 0.114 0.328C0.157 0.276 0.251 0.18 0.36 0.18C0.469 0.18 0.563 0.276 0.606 0.328Z" stroke="#222222" strokeWidth="0.03" fill="none" /></svg>
      );
    default:
      return (
        <svg
          className={classes}
          width="29"
          height="19"
          viewBox="0 0 29 19"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fillRule="evenodd">
            <path
              d="M26.58 19H2.42A2.4004 2.4004 0 0 1 0 16.62V2.38A2.4 2.4 0 0 1 2.42 0h24.16A2.4004 2.4004 0 0 1 29 2.38v14.25c-.0165 1.3216-1.0984 2.3811-2.42 2.37zM10 5.83c0-.46-.35-.83-.78-.83H3.78c-.43 0-.78.37-.78.83v3.34c0 .46.35.83.78.83h5.44c.43 0 .78-.37.78-.83V5.83z"
              fill="#DADDE2"
            />
            <path
              d="M25 15h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0H4c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1z"
              fill="#B2B6C1"
            />
          </g>
        </svg>
      );
  }
};

ListingIconCard.defaultProps = {
  className: null,
  rootClassName: null,
  type: 'default',
};

ListingIconCard.propTypes = {
  className: string,
  rootClassName: string,
  type: string,
};

export default ListingIconCard;
