import MainParticipantInfo from '../MainParticipantInfo/MainParticipantInfo';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';
import React from 'react';
import useMainSpeaker from '../../hooks/useMainSpeaker/useMainSpeaker';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import Transcript from "../Transcript/Transcript";
import ParticipantStrip from '../ParticipantStrip/ParticipantStrip';

export default function MainParticipant(props) {
  const { txId, showCC, onInsertCollection, transactionRole,
    userId, } = props;
  const mainParticipant = useMainSpeaker();
  const [selectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();

  const videoPriority =
    mainParticipant === selectedParticipant || mainParticipant === screenShareParticipant ? 'high' : null;

  return (
    /* audio is disabled for this participant component because this participant's audio 
       is already being rendered in the <ParticipantStrip /> component.  */
    <div
      style={{ position: 'relative', width: '100%' }}
    >
      <ParticipantStrip
        style={{
          backgroundColor: 'red'
        }}
      />
      <MainParticipantInfo participant={mainParticipant}>
        <ParticipantTracks participant={mainParticipant} enableScreenShare videoPriority={videoPriority} mainPariticipant={true} />
        <Transcript
          txId={txId}
          height={"100%"}
          onInsertCollection={onInsertCollection}
          showCC={showCC}
          transactionRole={transactionRole}
          userId={userId}
        />
      </MainParticipantInfo>
    </div>
  );
}
