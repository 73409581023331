import React, { Component } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field, Form as FinalForm } from 'react-final-form';
import config from '../../config';
import * as validators from '../../util/validators';
import { findOptionsForSelectFilter } from '../../util/search';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import * as normalizePhoneNumberUS from './normalizePhoneNumberUS';
import ImageCropper from '../../components/ImageCropper/imageCropper';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
} from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import {
  Form,
  FieldSelect,
  PrimaryButton,
  FieldTextInput,
  FieldPhoneNumberInput,
  LocationAutocompleteInputField,
  AddImages,
  ImageFromFile,
  Avatar,
  IconCard,
  FieldCheckbox,
  FieldRadioButton,
  IconBadgesCard,
  Modal,
  IconSpinner,
} from '../../components';
import { LightBlueButton } from '../../components/Button/Button';

import css from './ProfileSetupForm.module.css';
import { ensureCurrentUser } from '../../util/data';

const KEY_CODE_ENTER = 13;
const ACCEPT_IMAGES = 'image/*';
const UPLOAD_CHANGE_DELAY = 2000; // Show spinner so that browser has time to load img srcset
const identity = v => v;
class ProfileSetupFormComponent extends Component {

  constructor(props) {
    super(props);

    this.uploadDelayTimeoutId = null;
    this.state = { uploadDelay: false, showCroppedImage: false, imageName: '', imgFile: '' };
    this.submittedValues = {};

  }

  componentDidUpdate(prevProps) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
    if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
      this.setState({ uploadDelay: true });
      this.uploadDelayTimeoutId = window.setTimeout(() => {
        this.setState({ uploadDelay: false });
      }, UPLOAD_CHANGE_DELAY);
    }
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        initialValues={this.props.submittedValue && Object.keys(this.props.submittedValue).length ? this.props.submittedValue : this.props.initialValues}
        keepDirtyOnReinitialize={true}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            formId,
            handleSubmit,
            inProgress,
            invalid,
            intl,
            form,
            values,
            onImageUpload,
            pristine,
            profileImage,
            updateInProgress,
            updateProfileError,
            uploadImageError,
            uploadInProgress,
            onOpenTermsOfService,
            currentUser,
            pageName,
            submittedValue,
            onManageDisableScrolling
          } = fieldRenderProps;

          const uploadingOverlay =
            uploadInProgress || this.state.uploadDelay ? (
              <div className={css.uploadingImageOverlay}>
                <IconSpinner />
              </div>
            ) : null;
          const user = ensureCurrentUser(currentUser);
          const hasUploadError = !!uploadImageError && !uploadInProgress;
          const errorClasses = classNames({ [css.avatarUploadError]: hasUploadError });
          const transientUserProfileImage = profileImage?.uploadedImage || user.profileImage;
          const transientUser = { ...user, profileImage: transientUserProfileImage };

          // Ensure that file exists if imageFromFile is used
          const fileExists = !!profileImage.file;
          const fileUploadInProgress = uploadInProgress && fileExists;
          const delayAfterUpload = profileImage.imageId && this.state.uploadDelay;
          const imageFromFile =
            fileExists && (fileUploadInProgress || delayAfterUpload) ? (
              <ImageFromFile
                id={profileImage.id}
                className={errorClasses}
                rootClassName={css.uploadingImage}
                aspectRatioClassName={css.squareAspectRatio}
                file={profileImage.file}
              >
                {uploadingOverlay}
              </ImageFromFile>
            ) : null;

          // Avatar is rendered in hidden during the upload delay
          // Upload delay smoothes image change process:
          // responsive img has time to load srcset stuff before it is shown to user.
          const avatarClasses = classNames(errorClasses, css.avatar, {
            [css.avatarInvisible]: this.state.uploadDelay,
          });
          const avatarComponent =
            !fileUploadInProgress && profileImage.imageId ? (
              <Avatar
                className={avatarClasses}
                renderSizes="(max-width: 767px) 96px, 240px"
                user={transientUser}
                disableProfileLink
              />
            ) : null;

          const chooseAvatarLabel =
            profileImage.imageId || fileUploadInProgress ? (
              <div className={css.avatarContainer}>
                {imageFromFile}
                {avatarComponent}
                <div className={css.changeAvatar}>
                <IconCard brand="iconPencil"/>
                  <FormattedMessage id="ProfileSettingsForm.changeAvatar" />
                </div>
              </div>
            ) : (
              <div className={css.avatarPlaceholder}>
                <IconBadgesCard type="uploadicon" />
                <div className={css.changeButton}>
                  <IconCard brand="iconPencil"/>
                  
                  Change</div>
              </div>
            );

          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileSettingsForm.updateProfileFailed" />
            </div>
          ) : null;

          const GenderOptions = findOptionsForSelectFilter('gender', config.custom.filters);
          const prononOptions = findOptionsForSelectFilter('pronouns', config.custom.filters);
          const diffTypesOptions = findOptionsForSelectFilter('diffTypes', config.custom.filters);
          const countryCode = findOptionsForSelectFilter('countryCode', config.custom.filters);

          // address
          const addressLabel = intl.formatMessage({
            id: 'ProfileSetupForm.addressLabel',
          });
          const addressPlaceholder = intl.formatMessage({
            id: 'ProfileSetupForm.addressPlaceholder',
          });
          const addressRequiredMessage = intl.formatMessage({
            id: 'EditListingLocationForm.addressRequired',
          });
          const addressNotRecognizedMessage = intl.formatMessage({
            id: 'EditListingLocationForm.addressNotRecognized',
          });
          const addressRequired = validators.required(addressRequiredMessage);

          // phonenumber
          const numberLabel = intl.formatMessage({
            id: 'ProfileSetupForm.phoneLabel',
          });
          const numberPlaceholder = intl.formatMessage({
            id: 'ProfileSetupForm.phonePlaceholder',
          });
          const numberRequiredMessage = intl.formatMessage({
            id: 'ProfileSetupForm.phoneRequired',
          });

          const phoneNumberMinLengthMessage = intl.formatMessage(
            {
              id: 'ProfileSetupForm.phoneTooShort',
            },
            {
              minLength: validators.PASSWORD_MIN_LENGTH,
            }
          );
          const numberinLength = validators.minLength(
            phoneNumberMinLengthMessage,
            validators.PHONE_MIN_LENGTH
          );

          const numberRequired = validators.required(numberRequiredMessage);

          // Gender
          const genderLabel = intl.formatMessage({
            id: 'ProfileSetupForm.genderLabel',
          });
          const genderPlaceholder = intl.formatMessage({
            id: 'ProfileSetupForm.genderPlaceholder',
          });
          const genderRequiredMessage = intl.formatMessage({
            id: 'ProfileSetupForm.genderRequired',
          });
          const genderRequired = validators.required(genderRequiredMessage);
          const countryCodeRequired = validators.required('This field is required');

          // Pronouns
          const pronounsLabel = intl.formatMessage({
            id: 'ProfileSetupForm.pronounsLabel',
          });
          const pronounsPlaceholder = intl.formatMessage({
            id: 'ProfileSetupForm.pronounsPlaceholder',
          });
          const pronounsRequiredMessage = intl.formatMessage({
            id: 'ProfileSetupForm.pronounsRequired',
          });
          const pronounsRequired = validators.required(pronounsRequiredMessage);

          // i am 
          const differentTypeLabel = intl.formatMessage({
            id: 'ProfileSetupForm.typeLabel',
          });
          const differentTypePlaceholder = intl.formatMessage({
            id: 'ProfileSetupForm.typePlaceholder',
          });
          const differentTypeRequiredMessage = intl.formatMessage({
            id: 'ProfileSetupForm.typeRequired',
          });
          const differentTypeRequired = validators.required(differentTypeRequiredMessage);

          const tradespersonLabel = intl.formatMessage({
            id: 'ProfileSetupForm.tradespersonLabel',
          });
          const diyerLabel = intl.formatMessage({
            id: 'ProfileSetupForm.diyerLabel',
          });

          // First name
          const firstNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNameLabel',
          });
          const firstNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNamePlaceholder',
          });
          const firstNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNameRequired',
          });
          const firstNameRequired = validators.required(firstNameRequiredMessage);

          // Last name
          const lastNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNameLabel',
          });
          const lastNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNamePlaceholder',
          });
          const lastNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNameRequired',
          });
          const lastNameRequired = validators.required(lastNameRequiredMessage);

          // email
          const emailLabel = intl.formatMessage({
            id: 'SignupForm.emailLabel',
          });
          const emailPlaceholder = intl.formatMessage({
            id: 'SignupForm.emailPlaceholder',
          });
          const emailRequiredMessage = intl.formatMessage({
            id: 'SignupForm.emailRequired',
          });
          const emailRequired = validators.required(emailRequiredMessage);
          const emailInvalidMessage = intl.formatMessage({
            id: 'SignupForm.emailInvalid',
          });
          const emailValid = validators.emailFormatValid(emailInvalidMessage);

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = inProgress;
          // const submitDisabled = invalid || submitInProgress || (pageName == 'TopbarContainer' && !Object.keys(submittedValue).length);
          const submitDisabled = invalid || submitInProgress || uploadInProgress;
          const listMyServiceButtonDisabled = values.ListingType == 'diyer';



          const cropperModal = (
            <Modal
              containerClassName={css.cropperModal}
              id="EditListingPhotosForm.imageCropper"
              isOpen={this.state.showCroppedImage}
              onClose={() => { this.setState({ showCroppedImage: false }) }}
              usePortal
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <div className={css.termsWrapper}>
                <ImageCropper
                  onImageUploadHandler={(e) => onImageUpload({ id: this.state.imageName, file: e })}
                  onRemoveImage={() => this.setState({ showCroppedImage: false })}
                  imageId={this.state.imageName}
                  inputImg={this.state.imgFile}
                />
              </div>
            </Modal>
          );

          // const disableButtton = () => {
          //   if (submitDisabled) {
          //     return true
          //   } else {
          //     if (values.ListingType == 'diyer') {
          //       return true
          //     } else {
          //       return false
          //     }
          //   }
          // }


          const handleTermsKeyUp = e => {
            // Allow click action with keyboard like with normal links
            if (e.keyCode === KEY_CODE_ENTER) {
              onOpenTermsOfService(values);
            }
          };

          const termsLink = (
            <span
              className={css.termsLink}
              onClick={() => onOpenTermsOfService(values)}
              role="button"
              tabIndex="0"
              onKeyUp={handleTermsKeyUp}
            >
              <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
            </span>
          );

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <div>

                <div>
                  <Field
                    accept={ACCEPT_IMAGES}
                    id="profileImage"
                    name="profileImage"
                    label={chooseAvatarLabel}
                    type="file"
                    form={null}
                    uploadImageError={uploadImageError}
                    disabled={uploadInProgress}
                  >
                    {fieldProps => {
                      const { accept, id, input, label, disabled, uploadImageError } = fieldProps;
                      const { name, type } = input;
                      const onChange = e => {
                        const file = e.target.files[0];
                        form.change(`profileImage`, file);
                        form.blur(`profileImage`);
                        if (file != null) {
                          const tempId = `${file.name}_${Date.now()}`;
                          // onImageUpload({ id: tempId, file });

                          this.setState({ showCroppedImage: true, imageName: tempId, imgFile: file })
                        }
                      };

                      let error = null;

                      if (isUploadImageOverLimitError(uploadImageError)) {
                        error = (
                          <div className={css.error}>
                            <FormattedMessage id="ProfileSettingsForm.imageUploadFailedFileTooLarge" />
                          </div>
                        );
                      } else if (uploadImageError) {
                        error = (
                          <div className={css.error}>
                            <FormattedMessage id="ProfileSettingsForm.imageUploadFailed" />
                          </div>
                        );
                      }

                      return (
                        <div className={css.uploadAvatarWrapper}>
                          <label className={css.label} htmlFor={id}>
                            {label}
                          </label>
                          <input
                            accept={accept}
                            id={id}
                            name={name}
                            className={css.uploadAvatarInput}
                            disabled={disabled}
                            onChange={onChange}
                            type={type}
                          />
                          {error}
                        </div>
                      );
                    }}
                  </Field>
                </div>

                {this.state.showCroppedImage && cropperModal}
                {pageName == "ProfilePage"
                  ? (
                    <>
                      {/* <h3 className={css.sectionTitle}>
                        <FormattedMessage id="ProfileSettingsForm.yourName" />
                      </h3> */}
                      <div className={css.nameContainer}>
                        <FieldTextInput
                          className={css.firstName}
                          type="text"
                          id="firstName"
                          name="firstName"
                          label={firstNameLabel}
                          placeholder={firstNamePlaceholder}
                          validate={firstNameRequired}
                        />
                        <FieldTextInput
                          className={css.lastName}
                          type="text"
                          id="lastName"
                          name="lastName"
                          label={lastNameLabel}
                          placeholder={lastNamePlaceholder}
                          validate={lastNameRequired}
                        />
                      </div>
                      <div>
                        <FieldTextInput
                          className={css.emailInput}
                          type="email"
                          id='email'
                          name="email"
                          disabled
                          autoComplete="email"
                          label={emailLabel}
                          placeholder={emailPlaceholder}
                          validate={validators.composeValidators(emailRequired, emailValid)}
                        />
                      </div>
                    </>)
                  : null}

                <div>
                  <LocationAutocompleteInputField
                    className={css.locationAddress}
                    locationIcon={true}
                    inputClassName={css.locationAutocompleteInput}
                    iconClassName={css.locationAutocompleteInputIcon}
                    predictionsClassName={css.predictionsRoot}
                    validClassName={css.validLocation}
                    name="location"
                    label={addressLabel}
                    placeholder={addressPlaceholder}
                    useDefaultPredictions={false}
                    format={identity}
                    valueFromForm={values.location}
                    validate={composeValidators(
                      autocompleteSearchRequired(addressRequiredMessage),
                      autocompletePlaceSelected(addressNotRecognizedMessage)
                    )}
                  />
                </div>
                <div className={css.countryField}>
                  <FieldSelect
                    id={formId ? `${formId}.countryCode` : 'countryCode'}
                    name="countryCode"
                    className={css.countrySelect}
                    label={'Country Code'}
                    placeholder={'CountryCode'}
                    validate={countryCodeRequired}
                  >
                    <option disabled value="">
                      {genderPlaceholder}

                    </option>
                    {countryCode.map(({ key, label, value }) => {
                      return (
                        <option key={key} value={value}>
                          {label}
                        </option>
                      );
                    })}
                  </FieldSelect>
                  <FieldPhoneNumberInput
                    className={css.PhoneInput}
                    name="phoneNumber"
                    id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                    label={numberLabel}
                    placeholder={numberPlaceholder}
                    format={normalizePhoneNumberUS.format}
                    parse={normalizePhoneNumberUS.parse}
                    validate={validators.composeValidators(numberRequired, numberinLength)}
                  />
                </div>
                {/* {pageName == "ProfilePage"
                  ? null : (
                    <div >
                      <FieldSelect
                        className={css.diffTypes}
                        id={formId ? `${formId}.differentType` : 'differentType'}
                        name="ListingType"
                        label={differentTypeLabel}
                        placeholder={differentTypePlaceholder}
                        validate={differentTypeRequired}
                      >
                         <option disabled value="">
                         {differentTypePlaceholder}

                    </option>
                        
                        {diffTypesOptions.map(({ key, label }) => {
                          return (
                            <option key={key} value={key} >
                              {label}
                            </option>
                          );
                        })}
                      </FieldSelect>
                    </div>
                  )} */}

                {pageName == "ProfilePage"
                  ? null : (<div className={css.diffrentTypeBox}>
                    <div className={css.diffrentTypeLabel}>{differentTypeLabel}</div>
                    <FieldRadioButton
                      id={formId ? `${formId}.tradesperson` : 'tradesperson'}
                      name="ListingType"
                      label={tradespersonLabel}
                      value="Pro"
                      checkedClassName={css.checkedAvailable}
                      showAsRequired={pristine}
                    />
                    <FieldRadioButton
                      id={formId ? `${formId}.diyers` : 'diyers'}
                      name="ListingType"
                      label={diyerLabel}
                      value="diyer"
                      checkedClassName={css.checkedAvailable}
                      showAsRequired={pristine}
                    />
                  </div>)}

                <div className={css.name}>
                  <FieldSelect
                    id={formId ? `${formId}.gender` : 'gender'}
                    name="gender"
                    className={css.genderSelect}
                    label={genderLabel}
                    placeholder={genderPlaceholder}
                  // validate={genderRequired}
                  >
                    <option disabled value="">
                      {genderPlaceholder}

                    </option>
                    {GenderOptions.map(({ key, label }) => {
                      return (
                        <option key={key} value={key}>
                          {label}
                        </option>
                      );
                    })}
                  </FieldSelect>

                  <FieldSelect
                    className={css.lastNameRoot}
                    id={formId ? `${formId}.pronouns` : 'pronouns'}
                    name="pronouns"
                    autoComplete="family-name"
                    label={pronounsLabel}
                    placeholder={pronounsPlaceholder}
                  >
                    <option disabled value="">
                      {pronounsPlaceholder}
                    </option>
                    {prononOptions.map(({ key, label }) => {
                      return (
                        <option key={key} value={key}>
                          {label}
                        </option>
                      );
                    })}
                  </FieldSelect>
                </div>
              </div>

              <div className={css.bottomWrapper}>
                {pageName == "ProfilePage"
                  ? (
                    <PrimaryButton
                      type="submit"
                      inProgress={submitInProgress}
                      disabled={submitDisabled}
                    >
                      <FormattedMessage id="ProfileSetupForm.UpdateValues" />
                    </PrimaryButton>
                  )
                  : <>
                    <p className={css.bottomWrapperText}>
                      <span className={css.termsText}>
                        <FormattedMessage
                          id="ProfileSetupForm.termsAndCondition"
                          values={{ termsLink }}
                        />
                      </span>
                    </p>
                    <LightBlueButton
                      className={submitDisabled ? css.servicesButton : css.servicesButton}
                      type="submit"
                      disabled={submitDisabled}
                      inProgress={submitInProgress}
                    >
                      <FormattedMessage id="ProfileSetupForm.findPro" />
                    </LightBlueButton>
                    <PrimaryButton
                      type="button"
                      className={(submitDisabled && !listMyServiceButtonDisabled) ? '' : ''}
                      disabled={submitDisabled ? true : (values.ListingType == 'diyer') ? true : false}
                      // inProgress={submitInProgress}
                      onClick={() => {
                        form.change('submitTo', 'myServices');
                        handleSubmit(values);
                      }}
                    >
                      <FormattedMessage id="ProfileSetupForm.myServices" />
                    </PrimaryButton>
                  </>}
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

ProfileSetupFormComponent.defaultProps = {
  inProgress: false, rootClassName: null,
  className: null,
  uploadImageError: null,
  updateProfileError: null,
  updateProfileReady: false,
};

const { bool, func } = PropTypes;

ProfileSetupFormComponent.propTypes = {
  inProgress: bool,
  onOpenTermsOfService: func.isRequired,
  intl: intlShape.isRequired,
  // rootClassName: string,
  // className: string,
  // uploadImageError: propTypes.error,
  // uploadInProgress: bool.isRequired,
  // updateInProgress: bool.isRequired,
  // updateProfileError: propTypes.error,
  // updateProfileReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ProfileSetupForm = compose(injectIntl)(ProfileSetupFormComponent);
ProfileSetupForm.displayName = 'ProfileSetupForm';

export default ProfileSetupForm;
