import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Button, Form, FieldCurrencyInput, FieldRadioButton, FieldPhoneNumberInput, FieldTextInput } from '../../components';
import css from './EditListingPricingForm.module.css';

const { Money } = sdkTypes;

export const EditListingPricingFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values
      } = formRenderProps;

      const unitType = config.bookingUnitType;
      const isNightly = unitType === LINE_ITEM_NIGHT;
      const isDaily = unitType === LINE_ITEM_DAY;
      const MAX_LENGTH = 30;

      const nameInsurerMessage = intl.formatMessage({
        id: 'EditListingPricingForm.nameInsurerMessage',
      });
      const nameInsurerPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.nameInsurerPlaceholderMessage',
      });

      const policyNumberMessage = intl.formatMessage({
        id: 'EditListingPricingForm.policyNumberMessage',
      });

      const bussinessRelatedCheckboxLabel = intl.formatMessage({
        id: 'EditListingPricingForm.bussinessRelatedCheckboxLabel',
      });

      const insuranceRelatedCheckboxLabel = intl.formatMessage({
        id: 'EditListingPricingForm.insuranceRelatedCheckboxLabel',
      });

      const policyNumberPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.policyNumberPlaceholderMessage',
      });

      const insurerRequiredMessage = intl.formatMessage({
        id: 'EditListingPricingForm.insurerNameMessage',
      });
      const insurerRequired = validators.required(insurerRequiredMessage);


      const policynoRequiredMessage = intl.formatMessage({
        id: 'EditListingPricingForm.policyRequired',
      });

      const policyRequired = validators.required(policynoRequiredMessage);

      const translationKey = isNightly
        ? 'EditListingPricingForm.pricePerNight'
        : isDaily
          ? 'EditListingPricingForm.pricePerDay'
          : 'EditListingPricingForm.pricePerUnit';

      const pricePerUnitMessage = intl.formatMessage({
        id: translationKey,
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingPricingForm.maxLimit' },
        {
          maxLength: MAX_LENGTH,
        }
      );

      const maxLength = validators.maxLength(
        maxLengthMessage,
        MAX_LENGTH
      );

      const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.priceInputPlaceholder',
      });

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );

      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}
          <div>
            <div className={css.subTitleName}>
              <FormattedMessage id="EditListingPricingForm.wehaveToProtectYou" />
            </div>
            <div className={css.insuranceCheck}>
              <FieldRadioButton
                id="bussinessRelated"
                className={css.rowCheckBox}
                name="insurance"
                label={bussinessRelatedCheckboxLabel}
                value="Insured"
              />
              <FieldRadioButton
                id="InsuaranceRelated"
                className={css.rowCheckBox}
                name="insurance"
                label={insuranceRelatedCheckboxLabel}
                value="Not Insured"
              />
            </div>
            {values.insurance == 'Insured'
              ? <>
                <FieldTextInput
                  id="nameInsurer"
                  name="nameInsurer"
                  className={css.insureInput}
                  label={nameInsurerMessage}
                  placeholder={nameInsurerPlaceholderMessage}
                  validate={insurerRequired}
                />
                <FieldTextInput
                  id="policyNumber"
                  name="policyNumber"
                  className={css.insureInput}
                  label={policyNumberMessage}
                  placeholder={policyNumberPlaceholderMessage}
                  validate={validators.composeValidators(policyRequired, maxLength)}
                  
                />
              </>
              : null}
          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
