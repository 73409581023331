import { useEffect, useState } from 'react';
import useDominantSpeaker from '../useDominantSpeaker/useDominantSpeaker';
import useVideoContext from '../useVideoContext/useVideoContext';

let hasEnter = false, hasLeft = false

export default function useParticipants() {
  const { room } = useVideoContext();

  const dominantSpeaker = useDominantSpeaker();
  const [participants, setParticipants] = useState([room?.participants?.values()]);

  // When the dominant speaker changes, they are moved to the front of the participants array.
  // This means that the most recent dominant speakers will always be near the top of the
  // ParticipantStrip component.
  useEffect(() => {
    if (dominantSpeaker) {
      setParticipants(prevParticipants => [
        dominantSpeaker,
        ...prevParticipants.filter(participant => participant !== dominantSpeaker),
      ]);
    }
  }, [dominantSpeaker]);

  useEffect(() => {
    const participantConnected = (participant) => {
      setParticipants(prevParticipants => [...prevParticipants, participant]);
      if (!hasEnter) {
        // notyf.open({
        //   "type": "enter",
        //   "message": `${participant?.identity} joined the meeting`
        // })
      }

      hasEnter = !hasEnter
    }
    const participantDisconnected = (participant) => {
      setParticipants(prevParticipants => prevParticipants.filter(p => p !== participant));
      if (!hasLeft) {
        // notyf.open({
        //   "type": "left",
        //   "message": `${participant?.identity} has left the meeting`
        // })
      }

      hasLeft = !hasLeft
    }
    room.on('participantConnected', participantConnected);
    room.on('participantDisconnected', participantDisconnected);
    return () => {
      room.off('participantConnected', participantConnected);
      room.off('participantDisconnected', participantDisconnected);
    };
  }, [room]);

  return participants;
}
