import React, { useState } from 'react';
import { Button, IconCard, SecondaryButton } from '..';
import css from './GoogleAPI.module.css';


export default function GoogleAPI(props) {
    const [eventpublished, setEventPublished] = useState(false);


    const {
        location,
        description = process.env.REACT_APP_ROOT_URL,
        startTime,
        endTime,
        timeZone,
        customerEmail = 'Anurag@icodelabs.co',
        providerEmail = 'aspolist27@gmail.com',
        customerName = 'Anurag',
        providerName = 'As polist',
        conferenceData = {
            meetingURL: process.env.REACT_APP_ROOT_URL
        },
        meetingStartTimePassed,
        updateTransactionMeta,
        transactionId,
        alreadyEventAdded,
        transactionRole
    } = props;


    const publishTheCalenderEvent = async (event = {}) => {
        try {
            return gapi.client.load('calendar', 'v3', () => {
                const request = gapi.client.calendar.events.insert({
                    'calendarId': 'primary',
                    'resource': event
                });

                request.execute(function (event) {
                    updateTransactionMeta(transactionId?.uuid,transactionRole);
                    setEventPublished(true);
                    console.log('Event created: ' + event.htmlLink);
                    return event;
                });
            })
        } catch (error) {
            console.log("Error not create event working", error);
            return "Error not create event working";
        }
    }



 
    const event = {
        summary: `Consult with ${customerName} - ${providerName}`,
        location,
        description,
        start: {
            dateTime: startTime || '2023-07-10T09:00:00-07:00',
            timeZone: timeZone || 'Canada/Central'
        },
        end: {
            dateTime: endTime || '2023-07-10T17:00:00-07:30',
            timeZone: timeZone || 'Canada/Central'
        },
        // 'recurrence': [
        //     'RRULE:FREQ=DAILY;COUNT=2'
        // ],
        attendees: [
            { displayName: customerName, email: customerEmail },
            { displayName: providerName, email: providerEmail }
        ],
        conferenceData,
        reminders: {
            useDefault: true,
            // 'overrides': [
            //     { 'method': 'email', 'minutes': 24 * 60 },
            //     { 'method': 'popup', 'minutes': 10 }
            // ]
        }
    };

    const checkMeetingInCalendar = async () => {
        try {
            const response = await gapi.client.calendar.events.list({
                calendarId: 'primary',
                start: new Date(startTime).toISOString(),
                end: new Date(endTime).toISOString(),
                summary: `Consult with ${customerName} - ${providerName}`,
            });

            const events = response.result.items;
            console.log(events, 'events');
            if (events.length > 0) {
                setIsMeetingSaved(true);
            } else {
                setIsMeetingSaved(false);
            }
        } catch (error) {
            console.log("Error checking meeting status:", error);
        }
    };

    /**
     *  Sign in the user upon button click.
     */
    function handleAuthClick() {
        if (typeof window != 'undefined' && window.tokenClient) {
            window.tokenClient.callback = async (resp) => {
                if (resp.error !== undefined) {
                    throw (resp);
                }
                await publishTheCalenderEvent(event);
            };

            if (gapi.client.getToken() === null) {
                // Prompt the user to select a Google Account and ask for consent to share their data
                // when establishing a new session.
                window.tokenClient.requestAccessToken({ prompt: 'consent' });
            } else {
                // Skip display of account chooser and consent dialog for an existing session.
                window.tokenClient.requestAccessToken({ prompt: '' });
            }
        }
    }

    /**
     *  Sign out the user upon button click.
     */
    const token = gapi.client.getToken();
    function handleSignoutClick() {
        if (token !== null) {
            google.accounts.oauth2.revoke(token.access_token);
            gapi.client.setToken('');
        }
    }
// disabled={meetingStartTimePassed || eventpublished || alreadyEventAdded }
    return (
        <> 
        {/* <div className='calenderEvent-wrapper'>
            <SecondaryButton disabled={meetingStartTimePassed || eventpublished || alreadyEventAdded }    onClick={handleAuthClick}>
                Connect Google Calender
            </SecondaryButton>
            
        </div> */}
          <div className='calenderEvent-wrapper'>

          <SecondaryButton className={css.btnCalendar} disabled={meetingStartTimePassed || eventpublished || alreadyEventAdded }    onClick={handleAuthClick}>
            <IconCard brand="iconCalendar" />
            Connect Google Calender
          </SecondaryButton>
          </div></>
       
    )
}