import React, { Component } from 'react';
import { compose } from 'redux';
import classNames from 'classnames';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { array, bool, func, object, string } from 'prop-types';

import config from '../../config';

import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { timestampToDate } from '../../util/dates';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';

import { FieldSelect, Form, IconSpinner, InlineTextButton, ListingIconCard, OrangeButton, PrimaryButton } from '../../components';

import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';

import css from './BookingTimeForm.module.css';
import { getPrice } from '../../util/data';

export class BookingTimeFormComponent extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleFormSubmit(e) {
    this.props.onSubmit(e);
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues) {
    const { selectedPrice, trades } = this.props;
    const { bookingStartTime, bookingEndTime, tradeService, sessionLength } = formValues.values;
    const startDate = bookingStartTime ? timestampToDate(bookingStartTime) : null;
    const endDate = bookingEndTime ? timestampToDate(bookingEndTime) : null;

    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;

    // We expect values bookingStartTime and bookingEndTime to be strings
    // which is the default case when the value has been selected through the form
    const isSameTime = bookingStartTime === bookingEndTime;
    const selectedTrade = trades && trades.length && tradeService
      ? trades.filter(t => t.key == tradeService)[0]
      : {};
    const { inputValues } = selectedTrade;
    const { price = 0 } = inputValues || {};

    if (bookingStartTime && bookingEndTime && !isSameTime && !this.props.fetchLineItemsInProgress && sessionLength) {
      this.props.onFetchTransactionLineItems({
        bookingData: { startDate, endDate, selectedPrice: (price || selectedPrice), selectedDuration: (15 * sessionLength), proHasBussinessAccount: this.props.charge_taxes ? this.props.charge_taxes : false },
        listingId,
        isOwnListing,
      });
    }
  }

  render() {
    const { rootClassName, className, price: unitPrice,localHireTrade,showContactUser,onContactUser, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={(e) => { this.handleFormSubmit(e) }}
        keepDirtyOnReinitialize={true}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            listingId,
            submitButtonWrapperClassName,
            unitType,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            timeZone,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            trades,
            selectedDuration,
            pendingIsApproved,
            handleSelectedPrice,
            invalid,
            bookedSession,
            charge_taxes
          } = fieldRenderProps;

          if (lineItems && lineItems.length) {
            if (lineItems[0] && lineItems[0].unitPrice) {
              form.change('selectedPrice', lineItems[0].unitPrice.amount)
            }
          }

          const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
          const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingEndTitle',
          });

          const startDate = startTime ? timestampToDate(startTime) : null;
          const endDate = endTime ? timestampToDate(endTime) : null;

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate && endDate
              ? {
                unitType,
                startDate,
                endDate,
                timeZone,
              }
              : null;

          const showEstimatedBreakdown =
            bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
              </h3>
              <EstimatedBreakdownMaybe bookingData={bookingData} lineItems={lineItems} />
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingTimeForm.fetchLineItemsError" />
            </span>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };
          const commonPlaceholder = intl.formatMessage({
            id: 'EditListingDescriptionForm.SelectPlaceHolder',
          });
          const labelOfTrade = intl.formatMessage({
            id: 'FieldDateTimeInput.tradeLabel',
          });
          const requiredField = intl.formatMessage({ id: 'FieldDateTimeInput.requiredField' });

          const selectedTrade = trades && trades.length && values.tradeService
            ? trades.filter(t => t.key == values.tradeService)[0]
            : {};
          const { inputValues } = selectedTrade;
          const { price = 0 } = inputValues || {};
          const submitDisabled = !pendingIsApproved || !values.tradeService || invalid || !bookingData || fetchLineItemsInProgress || fetchLineItemsError;

          return (
            <Form onSubmit={handleSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  this.handleOnChange(values);
                }}
              />

              {/* <FieldSelect
                className={css.inputBox}
                id='tradeService'
                name='tradeService'
                label={labelOfTrade}
                validate={validators.required(requiredField)}
                onChange={e => {
                  const selectedTrade = trades && trades.length && e
                    ? trades.filter(t => t.key == e)[0]
                    : {};
                  const { inputValues } = selectedTrade;
                  const { price = 0 } = inputValues || {};
                  handleSelectedPrice(getPrice(price, selectedDuration), selectedDuration, e);
                }}
              >
                <option disabled value="">{commonPlaceholder}</option>
                {trades && trades.length && trades.map((nctt) => <option key={nctt.key} value={nctt.key}>{nctt.label}</option>)}
              </FieldSelect> */}

              {monthlyTimeSlots && timeZone && values.tradeService ? (
                <FieldDateAndTimeInput
                  {...dateInputProps}
                  className={css.bookingDates}
                  listingId={listingId}
                  bookedSession={bookedSession}
                  bookingStartLabel={bookingStartLabel}
                  onFetchTimeSlots={onFetchTimeSlots}
                  monthlyTimeSlots={monthlyTimeSlots}
                  values={values}
                  intl={intl}
                  form={form}
                  pristine={pristine}
                  timeZone={timeZone}
                  price={price}
                  selectedDuration={selectedDuration}
                  handleSelectedPrice={handleSelectedPrice}
                  labelOfTrade={labelOfTrade}
                  trades={trades}
                  commonPlaceholder={commonPlaceholder}
                  requiredField={requiredField}
                />
              ) : null}

              {bookingInfoMaybe}
              {loadingSpinnerMaybe}
              {bookingInfoErrorMaybe}

              <div className={css.bottomButtonWrapper}>
                {/* <p className={css.listingSmallText}>
                  <FormattedMessage
                    id={
                      isOwnListing
                        ? 'BookingTimeForm.ownListing'
                        : 'BookingTimeForm.youWontBeChargedInfo'
                    }
                  />
                </p> */}
                <div className={css.timeChangedText}>
                  {/* <span className={css.informationIcon}>
                    <ListingIconCard type="info" />
                    <span className={css.hoverText}>
                    <FormattedMessage id="BookingTimeForm.InfoOfPayment" />
                    </span>
                  </span> */}
                  After 15 minutes you will be charged for
                  <span className={classNames(css.informationIcon, css.informationIcon2)}>
                    <a >time used</a>.
                    <span className={classNames(css.hoverText, css.hoverText2)}>
                      <FormattedMessage id="BookingTimeForm.InfoOfPayment" />
                    </span>
                  </span>
                </div>

                <div className={submitButtonClasses}>
                  <OrangeButton
                    type="submit"
                    disabled={submitDisabled}
                  >
                    <FormattedMessage id="BookingTimeForm.requestToBook" />
                  </OrangeButton>
                </div>
                {localHireTrade ? <p className={css.helpText}>Virtual help not enough? Connect with this Pro about in person help.</p>: null}
                {localHireTrade
                  ? <div className={css.avlLocalHire}>
                    <span>
                      <ListingIconCard type="location" />
                    </span>
                    <FormattedMessage id="SectionHeading.availableForLocalHire" />
                    <div className={css.author}>
                      {showContactUser ? (
                        <span className={css.contactWrapper}>
                          <InlineTextButton
                            rootClassName={css.contactLink}
                            onClick={onContactUser}
                            enforcePagePreloadFor="SignupPage"
                          >
                            <FormattedMessage id="ListingPage.sendEnquiry" />
                          </InlineTextButton>
                        </span>
                      ) : null}
                    </div>

                  </div>
                  : null}
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
