import React, { useState } from 'react';
import { string, arrayOf, bool, func, number } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import dropWhile from 'lodash/dropWhile';
import classNames from 'classnames';
import { Avatar, InlineTextButton, ReviewRating, Skeleton, UserDisplayName } from '../../components';
import { formatDate } from '../../util/dates';
import { ensureTransaction, ensureUser, ensureListing } from '../../util/data';
import {
  TRANSITION_ACCEPT,
  TRANSITION_CANCEL,
  TRANSITION_CANCEL_PROVIDER,
  TRANSITION_CANCEL_CUSTOMER,
  TRANSITION_COMPLETE,
  TRANSITION_DECLINE,
  TRANSITION_EXPIRE,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  transitionIsReviewed,
  txIsDelivered,
  txIsInFirstReviewBy,
  txIsReviewed,
  isCustomerReview,
  isProviderReview,
  txRoleIsProvider,
  txRoleIsCustomer,
  getUserTxRole,
  isRelevantPastTransition,
  TRANSITION_PAYOUT_COMPLETE,
  TRANSITION_PARTIAL_COMPLETE,
  TRANSITION_PAID_AND_REVIEWED,
  txReviewAfterPaid,
  txIsPaidByTipalti,
  txPartiallyComplete
} from '../../util/transaction';
import { propTypes } from '../../util/types';
import * as log from '../../util/log';

import css from './ActivityFeed.module.css';

const Message = props => {
  const { message, intl, transaction } = props;
  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });

  return (
    <div className={css.message}>
      {/* <Avatar className={css.avatar} user={message.sender} disableProfileLink /> */}
      <div className={css.bullet}>
        <p className={css.transitionContent}>•</p>
      </div>
      <div>
        {message.attributes.content.includes("/meeting/") ? <p className={css.messageContentUrl}>Your consult link:
          <a href={message.attributes.content}>
            {' ' + message.attributes.content}
          </a></p> : <p className={css.messageContent}>{message.attributes.content}</p>}
        <p className={css.messageDate}>
          {formatDate(intl, todayString, message.attributes.createdAt)}
        </p>
      </div>
    </div>
  );
};

Message.propTypes = {
  message: propTypes.message.isRequired,
  intl: intlShape.isRequired,
};

const OwnMessage = props => {
  const { message, intl, transaction } = props;
  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
  return (
    <div className={css.ownMessage}>
      <div className={css.ownMessageContentWrapper}>
        {message.attributes.content.includes("/meeting/") ? <p className={css.ownMessageContent}>Your consult link:
          <a href={message.attributes.content}>
            {' ' + message.attributes.content}
          </a></p> : <p className={css.ownMessageContent}>{message.attributes.content}</p>}
      </div>
      <p className={css.ownMessageDate}>
        {formatDate(intl, todayString, message.attributes.createdAt)}
      </p>
    </div>
  );
};
const ProjectRequirement = props => {
  const { transaction, currentUser, intl, isCustomer, items } = props;

  const dateOfForm = items?.length ? items[0].createdAt : new Date();
  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });

  const currentTransaction = ensureTransaction(transaction);
  const {
    chatOver,
    consultationReason,
    inputSkill,
    issues,
    listDetail = '',
    storedImage = []
  } = currentTransaction?.attributes?.protectedData || {};

  const customer = currentTransaction?.customer;
  const provider = currentTransaction?.provider;

  const deletedListing = intl.formatMessage({ id: 'ActivityFeed.deletedListing' });
  const listingTitle = currentTransaction?.listing?.attributes?.deleted
    ? deletedListing
    : currentTransaction?.listing?.attributes?.title;

  const ownRole = !currentUser?.id && currentTransaction?.attributes?.protectedData?.guestUser
    ? 'customer'
    : getUserTxRole(currentUser?.id, currentTransaction);

  const displayName = txRoleIsProvider(ownRole)
    ? <UserDisplayName user={customer} intl={intl} />
    : <UserDisplayName user={provider} intl={intl} />;

  const mediaUrls = storedImage.map(url => ({
    url,
    type: /\.(mp4|webm|mov|avi)$/.test(url) ? 'video' : 'image'
  }));

  const descriptionLabel = intl.formatMessage({ id: 'RequirmentsProjectForm.descriptionLabel' });
  const skillLevelConsultation = intl.formatMessage({ id: 'RequirmentsProjectForm.skillLevelConsultation' });
  const preferencelabel = intl.formatMessage({ id: 'RequirmentsProjectForm.preferencelabel' });
  const stepIssues = intl.formatMessage({ id: 'RequirmentsProjectForm.textareaDescribeYourIssue' });
  const materialInvolved = intl.formatMessage({ id: 'RequirmentsProjectForm.listDetailsLikeMaterials' });
  const photoVideos = intl.formatMessage({ id: 'RequirmentsProjectForm.attachPhotoVideo' });

  const [isLightboxOpen, setLightboxOpen] = useState(false);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);

  const openLightbox = index => {
    setCurrentMediaIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  const showPrev = () => {
    setCurrentMediaIndex((currentMediaIndex - 1 + mediaUrls.length) % mediaUrls.length);
  };

  const showNext = () => {
    setCurrentMediaIndex((currentMediaIndex + 1) % mediaUrls.length);
  };

  return (
    <div>
      {!isCustomer && (
        <div className={css.transition}>
          <div className={css.bullet}>
            <p className={css.transitionContent}>•</p>
          </div>
          <div>
            <p className={css.transitionContent}>
              <FormattedMessage id="ActivityFeed.requestForm" values={{ displayName, listingTitle }} />
            </p>
            <p className={css.transitionDate}>
              {formatDate(intl, todayString, !currentUser?.id && currentTransaction?.attributes?.protectedData?.guestUser ? new Date(dateOfForm) : dateOfForm)}
            </p>
          </div>
        </div>
      )}

      <p className={css.messageFirstLabel}>{descriptionLabel}</p>
      <p className={css.messageItem}>{consultationReason}</p>
      <p className={css.messageLabel}>{skillLevelConsultation}</p>
      <p className={css.messageItem}>{inputSkill}</p>
      <p className={css.messageLabel}>{preferencelabel}</p>
      <p className={css.messageItem}>{chatOver}</p>
      <p className={css.messageLabel}>{stepIssues}</p>
      <p className={css.messageItem}>{issues}</p>

      {listDetail && (
        <div>
          <p className={css.messageLabel}>{materialInvolved}</p>
          <p className={css.messageItem}>{listDetail}</p>
        </div>
      )}

      {storedImage.length > 0 && (
        <div>
          <p className={css.messageLabel}>{photoVideos}</p>
          <div className={css.filesWrapper}>
            {mediaUrls.map((media, index) => (
              <div key={index} className={css.threeToTwoWrapper} onClick={() => openLightbox(index)}>
                {media.type === 'video' ? (
                  <div className={css.aspectWrapper}>
                    <video width="118" height="118" controls>
                      <source src={media.url} type="video/mp4" />
                    </video>
                  </div>
                ) : (
                  <div className={css.imageBox}>
                    <img src={media.url} alt={`project-img-${index}`} />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      {isLightboxOpen && (
        <div className={css.lightbox}>
          <button className={css.lightboxClose} onClick={closeLightbox}>×</button>
          <div className={css.outerLightbox}>
            {mediaUrls[currentMediaIndex].type === 'video' ? (
              <video className={css.lightboxContent} controls>
                <source src={mediaUrls[currentMediaIndex].url} type="video/mp4" />
              </video>
            ) : (
              <img className={css.lightboxContent} src={mediaUrls[currentMediaIndex].url} alt={`project-img-${currentMediaIndex}`} />
            )}
            <div className={css.lightBoxButton}>
              <button className={css.lightboxPrev} onClick={showPrev}>
              <svg fill="#000000" width="20px" height="20px" viewBox="0 0 1.3 1.3" enable-background="new 0 0 52 52"><path d="M0.95 0.208v0.885c0 0.025 -0.033 0.043 -0.055 0.023L0.365 0.683c-0.02 -0.015 -0.02 -0.048 0 -0.063L0.895 0.182c0.023 -0.017 0.055 -0.003 0.055 0.025"/></svg>
              </button>
              <button className={css.lightboxNext} onClick={showNext}>
              <svg fill="#000000" width="20px" height="20px" viewBox="0 0 0.6 0.6" id="next" data-name="Flat Color" ><path id="primary" d="m0.465 0.28 -0.3 -0.225A0.025 0.025 0 0 0 0.125 0.075v0.45a0.025 0.025 0 0 0 0.014 0.022 0.025 0.025 0 0 0 0.025 -0.002l0.3 -0.225a0.025 0.025 0 0 0 0 -0.04Z" fill="#fff"/></svg>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

OwnMessage.propTypes = {
  message: propTypes.message.isRequired,
  intl: intlShape.isRequired,
};

const Review = props => {
  const { content, rating } = props;
  return (
    <div>
      <p className={css.reviewContent}>{content}</p>
      {rating ? (
        <ReviewRating
          reviewStarClassName={css.reviewStar}
          className={css.reviewStars}
          rating={rating}
        />
      ) : null}
    </div>
  );
};

Review.propTypes = {
  content: string.isRequired,
  rating: number.isRequired,
};

const hasUserLeftAReviewFirst = (userRole, transaction) => {
  // Because function txIsInFirstReviewBy uses isCustomer to check in which state the reviews are
  // we should also use isCustomer insted of isProvider
  const isCustomer = txRoleIsCustomer(userRole);
  return txIsInFirstReviewBy(transaction, isCustomer);
};

const resolveTransitionMessage = (
  transaction,
  transition,
  listingTitle,
  ownRole,
  otherUsersName,
  intl,
  onOpenReviewModal,
  isCustomer
) => {
  const isOwnTransition = transition.by === ownRole;
  const currentTransition = transition.transition;
  const displayName = otherUsersName;

  switch (currentTransition) {
    case TRANSITION_CONFIRM_PAYMENT:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionRequest" values={{ listingTitle }} />
      ) : (
        <FormattedMessage
          id="ActivityFeed.transitionRequest"
          values={{ displayName, listingTitle }}
        />
      );
    case TRANSITION_ACCEPT:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionAccept" />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionAccept" values={{ displayName }} />
      );
    case TRANSITION_DECLINE:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionDecline" />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionDecline" values={{ displayName }} />
      );
    case TRANSITION_EXPIRE:
      return txRoleIsProvider(ownRole) ? (
        <FormattedMessage id="ActivityFeed.ownTransitionExpire" />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionExpire" values={{ displayName }} />
      );
    case TRANSITION_CANCEL:
      return <FormattedMessage id="ActivityFeed.transitionCancel" />;
    case TRANSITION_CANCEL_PROVIDER:
      return <FormattedMessage id="ActivityFeed.transitionCancel" />;
    case TRANSITION_CANCEL_CUSTOMER:
      return <FormattedMessage id="ActivityFeed.transitionCancel" />;
    case TRANSITION_COMPLETE:
      // Show the leave a review link if the state is delivered and if the current user is the first to leave a review
      // if(!isCustomer){
      //   return null
      // }else{
      let reviewPeriodJustStarted = txIsDelivered(transaction);

      if (!isCustomer) {
        reviewPeriodJustStarted = false;
      }

      const reviewAsFirstLink = reviewPeriodJustStarted ? (
        <InlineTextButton  onClick={onOpenReviewModal} className={css.leaveReview}>
          <FormattedMessage id="ActivityFeed.leaveAReview" values={{ displayName }} />
        </InlineTextButton>

      ) : null;

      return (
        <FormattedMessage
          id="ActivityFeed.transitionComplete"
          values={{ reviewLink: reviewAsFirstLink }}
        />
      );

    case TRANSITION_PAYOUT_COMPLETE:

      let reviewPeriodJustStart = txIsPaidByTipalti(transaction);
      if (!isCustomer) {
        reviewPeriodJustStart = false;
      }

      const reviewAsFirstLin = reviewPeriodJustStart ? (
        <InlineTextButton onClick={onOpenReviewModal} className={css.leaveReview}>
          <FormattedMessage id="ActivityFeed.leaveAReview" values={{ displayName }} />
        </InlineTextButton>
      ) : null;

      return (
        <FormattedMessage
          id={isCustomer && !reviewPeriodJustStart ? 'ActivityFeed.reviewThanks' : isCustomer ? 'ActivityFeed.transitionCompleteLeaveReview' : "ActivityFeed.tipaltiPayout"}
          values={{ reviewLink: reviewAsFirstLin }}
        />
      );
    case TRANSITION_PARTIAL_COMPLETE:

      let reviewPeriodStarted = txIsPaidByTipalti(transaction);

      if (txPartiallyComplete(transaction)) {
        reviewPeriodStarted = true
      }

      // let reviewPeriodStarted = true;
      if (!isCustomer) {
        reviewPeriodStarted = false;
      }

      const reviewAsFirstLines = reviewPeriodStarted ? (
        <InlineTextButton onClick={onOpenReviewModal} className={css.leaveReview}>
          <FormattedMessage id="ActivityFeed.leaveAReview" values={{ displayName }} />
        </InlineTextButton>
      ) : null;

      return (
        <FormattedMessage
          id="ActivityFeed.transitionComplete"
          values={{ reviewLink: reviewAsFirstLines }}
        />
      );
    // }
    case TRANSITION_PAID_AND_REVIEWED:
      if (isOwnTransition) {
        return <FormattedMessage id="ActivityFeed.ownTransitionReview" values={{ displayName }} />;
      } else {
        // show the leave a review link if current user is not the first
        // one to leave a review
        const reviewPeriodIsOver = txIsReviewed(transaction);
        const userHasLeftAReview = hasUserLeftAReviewFirst(ownRole, transaction);
        let reviewAsSecondLink = !(reviewPeriodIsOver || userHasLeftAReview) ? (
          <InlineTextButton onClick={onOpenReviewModal}>
            <FormattedMessage id="ActivityFeed.leaveAReviewSecond" values={{ displayName }} />
          </InlineTextButton>
        ) : null;

        if (!isCustomer) {
          reviewAsSecondLink = false
        }
        return (
          <FormattedMessage
            id="ActivityFeed.transitionReview"
            values={{ displayName, reviewLink: reviewAsSecondLink }}
          />
        );
      }


    case TRANSITION_REVIEW_1_BY_PROVIDER:
    case TRANSITION_REVIEW_1_BY_CUSTOMER:
      if (isOwnTransition) {
        return <FormattedMessage id="ActivityFeed.ownTransitionReview" values={{ displayName }} />;
      } else {
        // show the leave a review link if current user is not the first
        // one to leave a review
        const reviewPeriodIsOver = txIsReviewed(transaction);
        const userHasLeftAReview = hasUserLeftAReviewFirst(ownRole, transaction);
        let reviewAsSecondLink = !(reviewPeriodIsOver || userHasLeftAReview) ? (
          <InlineTextButton onClick={onOpenReviewModal}>
            <FormattedMessage id="ActivityFeed.leaveAReviewSecond" values={{ displayName }} />
          </InlineTextButton>
        ) : null;

        if (!isCustomer) {
          reviewAsSecondLink = false
        }
        return (
          <FormattedMessage
            id="ActivityFeed.transitionReview"
            values={{ displayName, reviewLink: reviewAsSecondLink }}
          />
        );
      }
    case TRANSITION_REVIEW_2_BY_PROVIDER:
    case TRANSITION_REVIEW_2_BY_CUSTOMER:
      if (isOwnTransition) {
        return <FormattedMessage id="ActivityFeed.ownTransitionReview" values={{ displayName }} />;
      } else {
        return (
          <FormattedMessage
            id="ActivityFeed.transitionReview"
            values={{ displayName, reviewLink: null }}
          />
        );
      }

    default:
      log.error(new Error('Unknown transaction transition type'), 'unknown-transition-type', {
        transitionType: currentTransition,
      });
      return '';
  }
};

const reviewByAuthorId = (transaction, userId) => {
  return transaction.reviews.filter(
    r => !r.attributes.deleted && r.author.id.uuid === userId.uuid
  )[0];
};

const Transition = props => {
  const { transition, transaction, currentUser, intl, onOpenReviewModal, isCustomer } = props;

  const currentTransaction = ensureTransaction(transaction);

  const customer = currentTransaction.customer;
  const provider = currentTransaction.provider;

  const deletedListing = intl.formatMessage({
    id: 'ActivityFeed.deletedListing',
  });
  const listingTitle = currentTransaction.listing.attributes.deleted
    ? deletedListing
    : currentTransaction.listing.attributes.title;
  const lastTransition = currentTransaction.attributes.lastTransition;

  const ownRole = !(currentUser && currentUser.id) && currentTransaction.attributes.protectedData.guestUser ? 'customer' : getUserTxRole(currentUser.id, currentTransaction);

  const otherUsersName = txRoleIsProvider(ownRole) ? (
    <UserDisplayName user={customer} intl={intl} />
  ) : (
    <UserDisplayName user={provider} intl={intl} />
  );

  const transitionMessage = resolveTransitionMessage(
    transaction,
    transition,
    listingTitle,
    ownRole,
    otherUsersName,
    intl,
    onOpenReviewModal,
    isCustomer
  );
  const currentTransition = transition.transition;

  const deletedReviewContent = intl.formatMessage({ id: 'ActivityFeed.deletedReviewContent' });
  let reviewComponent = null;

  if (transitionIsReviewed(lastTransition)) {
    if (isCustomerReview(currentTransition)) {
      const review = reviewByAuthorId(currentTransaction, customer.id);
      reviewComponent = review ? (
        <Review content={review.attributes.content} rating={review.attributes.rating} />
      ) : (
        <Review content={deletedReviewContent} />
      );
    } else if (isProviderReview(currentTransition)) {
      const review = reviewByAuthorId(currentTransaction, provider.id);
      reviewComponent = review ? (
        <Review content={review.attributes.content} rating={review.attributes.rating} />
      ) : (
        <Review content={deletedReviewContent} />
      );
    }
  }

  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });

  return (
    <div className={css.transition}>
      <div className={css.bullet}>
        <p className={css.transitionContent}>•</p>
      </div>
      <div>
        <p className={css.transitionContent}>{transitionMessage}</p>
        <p className={css.transitionDate}>{formatDate(intl, todayString, (!(currentUser && currentUser.id) && currentTransaction.attributes.protectedData.guestUser ? new Date(transition.createdAt) : transition.createdAt))}</p>
        {reviewComponent}


      </div>
    </div>
  );
};

Transition.propTypes = {
  transition: propTypes.transition.isRequired,
  transaction: propTypes.transaction.isRequired,
  // currentUser: propTypes.currentUser.isRequired,
  intl: intlShape.isRequired,
  onOpenReviewModal: func.isRequired,
};

const EmptyTransition = () => {
  return (
    <div className={css.transition}>
      <div className={css.bullet}>
        <p className={css.transitionContent}>•</p>
      </div>
      <div>
        <p className={css.transitionContent} />
        <p className={css.transitionDate} />
      </div>
    </div>
  );
};

const isMessage = item => item && item.type === 'message';

// Compare function for sorting an array containing messages and transitions
const compareItems = (a, b) => {
  const itemDate = item => (isMessage(item) ? item.attributes.createdAt : item.createdAt);
  return itemDate(a) - itemDate(b);
};

const organizedItems = (messages, transitions, hideOldTransitions) => {
  const items = messages.concat(transitions).sort(compareItems);
  if (hideOldTransitions) {
    // Hide transitions that happened before the oldest message. Since
    // we have older items (messages) that we are not showing, seeing
    // old transitions would be confusing.
    return dropWhile(items, i => !isMessage(i));
  } else {
    return items;
  }
};

export const ActivityFeedComponent = props => {
  const {
    rootClassName,
    className,
    messages,
    transaction,
    currentUser,
    hasOlderMessages,
    onOpenReviewModal,
    onShowOlderMessages,
    fetchMessagesInProgress,
    intl,
    isCustomer,
    customerName,
    transcriptBookingType,
    urlForTranscript
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentTransaction = ensureTransaction(transaction);
  const transitions = currentTransaction.attributes.transitions
    ? currentTransaction.attributes.transitions
    : [];
  const currentCustomer = ensureUser(currentTransaction.customer);
  const currentProvider = ensureUser(currentTransaction.provider);
  const currentListing = ensureListing(currentTransaction.listing);

  const transitionsAvailable = !!(
    ((currentUser && currentUser.id) || currentTransaction.attributes.protectedData.guestUser) &&
    currentCustomer.id &&
    currentProvider.id &&
    currentListing.id
  );
  const bookingState = (transaction && transaction.booking && transaction.booking && transaction.booking.attributes && transaction.booking.attributes.state) || '';
  const bookingId = (transaction && transaction.id && transaction.id.uuid) || '';

  // combine messages and transaction transitions
  const items = organizedItems(messages, transitions, hasOlderMessages || fetchMessagesInProgress);

  const transitionComponent = transition => {
    if (transitionsAvailable) {
      return (
        <Transition
          transition={transition}
          transaction={transaction}
          currentUser={currentUser}
          intl={intl}
          onOpenReviewModal={onOpenReviewModal}
          isCustomer={isCustomer}
        />
      );
    } else {
      return <EmptyTransition />;
    }
  };

  const messageComponent = message => {
    const isOwnMessage =
      message.sender &&
      message.sender.id &&
      currentUser &&
      currentUser.id &&
      message.sender.id.uuid === currentUser.id.uuid;
    if (isOwnMessage) {
      return <OwnMessage transaction={transaction} message={message} intl={intl} />;
    }
    return <Message transaction={transaction} message={message} intl={intl} />;
  };

  const messageListItem = message => {
    return (
      <li id={`msg-${message.id.uuid}`} key={message.id.uuid} className={css.messageItem}>
        {messageComponent(message)}
      </li>
    );
  };

  const transitionListItem = transition => {
    if (isRelevantPastTransition(transition.transition)) {
      // if (!isCustomer && transition.transition == 'transition/complete') {
      //   return null;
      // } else {
      return (
        <li key={transition.transition} className={css.transitionItem}>
          {transitionComponent(transition)}
        </li>
      );
      // }
    }
    // else {
    //   return null;
    // }
  };

  return (
    <ul className={classes}>
      {hasOlderMessages ? (
        <li className={css.showOlderWrapper} key="show-older-messages">
          <InlineTextButton className={css.showOlderButton} onClick={onShowOlderMessages}>
            <FormattedMessage id="ActivityFeed.showOlderMessages" />
          </InlineTextButton>
        </li>
      ) : null}
      {transcriptBookingType ?
        <div>
          {urlForTranscript ? (
            urlForTranscript === 'No' ? (
              <div>Sorry! No Transcript found, Please Contact Admin.</div>
            ) : (
              <>
                {'Transcript Url:'}
                <a target='_blank' href={urlForTranscript}>
                  {' https://res.cloudinary.com/..............pdf'}
                </a>
              </>
            )
          ) : (
            <div >
              <div className={css.skeleton}>
                <Skeleton width='100%' height={22} />
              </div>
            </div>
          )}
        </div>
        : <>
          <ProjectRequirement transaction={transaction} intl={intl} isCustomer={isCustomer} customerName={customerName} currentUser={currentUser} items={items} />
          {items.map((item, n) => {
            if (isMessage(item)) {
              return messageListItem(item);
            } else {
              return <> {transitionListItem(item, bookingState, bookingId)}   {(bookingState == 'accepted' && n == 2) ? <p className={css.messageContentUrl}>Your consult link:
                <a href={process.env.REACT_APP_ROOT_URL + '/meeting/' + bookingId}>
                  {' ' + process.env.REACT_APP_ROOT_URL + '/meeting/' + bookingId}
                </a></p> : null}</>;
            }
          })}
        </>
      }


    </ul >
  );
};

ActivityFeedComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

ActivityFeedComponent.propTypes = {
  rootClassName: string,
  className: string,

  // currentUser: propTypes.currentUser,
  transaction: propTypes.transaction,
  messages: arrayOf(propTypes.message),
  hasOlderMessages: bool.isRequired,
  onOpenReviewModal: func.isRequired,
  onShowOlderMessages: func.isRequired,
  fetchMessagesInProgress: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ActivityFeed = injectIntl(ActivityFeedComponent);

export default ActivityFeed;