const uploadURL = process.env.REACT_APP_CLOUDINARY_URL;
import { storableError } from '../../util/errors';
const requestAction = actionType => params => ({ type: actionType, payload: { params } });
const successAction = actionType => result => ({ type: actionType, payload: result.data || null });
const errorAction = actionType => error => ({ type: actionType, payload: error, error: true });

export const ADD_IMAGE_REQUEST = 'app/RequirementPage/ADD_IMAGE_REQUEST';
export const ADD_IMAGE_SUCCESS = 'app/RequirementPage/ADD_IMAGE_SUCCESS';
export const ADD_IMAGE_ERROR = 'app/RequirementPage/ADD_IMAGE_ERROR';

const initialState = {
    uploadInProgress: false,
    Images: {},
    uploadImageError: false,
};
export default function reducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case ADD_IMAGE_REQUEST:
            return { ...state, uploadInProgress: true, uploadImageError: null };
        case ADD_IMAGE_SUCCESS:
            return { ...state, uploadInProgress: false, Images: payload };

        case ADD_IMAGE_ERROR:
            const { id, error } = payload;
            return {
                ...state,
                uploadInProgress: false,
                uploadImageError: error
            };
        default:
            return state;
    }
}

export const uploadImage = requestAction(ADD_IMAGE_REQUEST);
export const uploadImageSuccess = successAction(ADD_IMAGE_SUCCESS);
export const uploadImageError = errorAction(ADD_IMAGE_ERROR);


export const requestImageUpload = (actionPayload) => async (dispatch, getState, sdk) => {
    const uploadPromises = [];
    if (actionPayload.files && actionPayload.files.length) {
        for await (const file of actionPayload.files) {
            const id = `${file.name}_${Date.now()}`;
            dispatch(uploadImage({ id, file }));
            const queryParams = {
                expand: true,
                'fields.image': ['variants.square-small', 'variants.square-small2x'],
            };
            uploadPromises.push(
                new Promise((resolve, reject) => {
                    const formData = new FormData();
                    const fileType = file.type;
                    formData.append('file', file);
                    formData.append('upload_preset', process.env.REACT_APP_CLOUDINARY_PRESET);
                    formData.append('resource_type', fileType);
                    formData.append('api_key', process.env.REACT_APP_CLOUDINARY_API_KEY);

                    fetch(uploadURL, { method: 'POST', body: formData })
                        .then(response => response.text())
                        .then(data => {
                            const res = JSON.parse(data);
                            if (res && res.secure_url) {
                                let imageData = JSON.parse(window.sessionStorage.getItem('image'));
                                if (imageData) {
                                    imageData.push(res.secure_url);
                                    window.sessionStorage.setItem('image', JSON.stringify(imageData));
                                } else {
                                    window.sessionStorage.setItem('image', JSON.stringify([res.secure_url]));
                                }
                                dispatch(
                                    uploadImageSuccess({
                                        data: {
                                            id, // Assuming id is defined somewhere in your code.
                                            secure_url: res.secure_url,
                                        },
                                    })
                                );
                                resolve(res); // Resolve the Promise with the response.
                            } else {
                                reject('Upload failed'); // Reject the Promise if the response is not as expected.
                            }
                        })
                        .catch(e => {
                            uploadImageError({ id, error: storableError(e) })
                            // reject(error); // If an error occurs during the fetch, reject the Promise with the error.
                        });
                }))
        }
        console.log(uploadPromises, 'uploadPromises');
        return await Promise.all(uploadPromises);
    }
}