import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './CancelBookingForm.css';
import { Form as FinalForm } from 'react-final-form';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { Form, FieldRadioButton, Button } from '../../components';

const CancelBookingFormComponent = props => (
    <FinalForm
        {...props}
        keepDirtyOnReinitialize={true}
        render={fieldRenderProps => {
            const {
                rootClassName,
                className,
                handleSubmit,
                inProgress,
                intl,
                invalid,
                isProvider
            } = fieldRenderProps;

            const FirstCheckboxLabel = intl.formatMessage({
                id: 'CancelBookingForm.FirstCheckboxLabel',
            });
            const SecondCheckboxLabel = intl.formatMessage({ 
                id: isProvider ? 'DeclineBookingForm.SecondCheckboxLabel' : 'CancelBookingForm.SecondCheckboxLabel',
            });
            const ThirdCheckboxLabel = intl.formatMessage({
                id: 'CancelBookingForm.ThirdCheckboxLabel',
                id: isProvider ? 'DeclineBookingForm.ThirdCheckboxLabel' : 'CancelBookingForm.ThirdCheckboxLabel',
            });

            const classes = classNames(rootClassName || css.root, className);
            const submitInProgress = inProgress;
            const submitDisabled = invalid || submitInProgress;

            return (
                <Form className={classes} onSubmit={handleSubmit}>
                    <h2 className={css.heading}>
                    You need to cancel this consult due to:
                    </h2>
                    <div className={css.inputWrapper} >
                   
                        <FieldRadioButton
                            id={'SchedulingConflict'}
                            name="declineReason"
                            label={FirstCheckboxLabel}
                            value={FirstCheckboxLabel}
                            checkedClassName={css.rowCheckBox}
                        />
                        <FieldRadioButton
                            id={'InsufficentAmount'}
                            name="declineReason"
                            label={SecondCheckboxLabel}
                            value={SecondCheckboxLabel}
                            checkedClassName={css.rowCheckBox}

                        />
                        <FieldRadioButton
                            id={'areaofexperties'}
                            name="declineReason"
                            label={ThirdCheckboxLabel}
                            value={ThirdCheckboxLabel}
                            checkedClassName={css.rowCheckBox}
                        />
                    </div>
                    <br/>
                    <Button
                        className={css.submitButton}
                        type="submit"
                        inProgress={submitInProgress}
                        disabled={submitDisabled}
                    >
                        Submit
                    </Button>
                </Form>
            );
        }}
    />
);

CancelBookingFormComponent.defaultProps = {
    rootClassName: null,
    className: null,
    form: null,
    inProgress: false,
};

const { string, bool } = PropTypes;

CancelBookingFormComponent.propTypes = {
    rootClassName: string,
    className: string,
    form: string,
    inProgress: bool,
    intl: intlShape.isRequired,
};

const CancelBookingForm = compose(injectIntl)(CancelBookingFormComponent);
CancelBookingForm.displayName = 'CancelBookingForm';

export default CancelBookingForm;
