import React, { useEffect } from 'react';
import { array, bool, func, number, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { propTypes } from '../../util/types';
import { sendVerificationEmail, hasCurrentUserErrors } from '../../ducks/user.duck';
import { logout, authenticationInProgress } from '../../ducks/Auth.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { updateProfile, uploadImage } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { Topbar } from '../../components';

export const TopbarContainerComponent = props => {
  const {
    authInProgress,
    currentPage,
    currentSearchParams,
    currentUser,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    currentUserHasOrders,
    history,
    isAuthenticated,
    authScopes,
    hasGenericError,
    location,
    notificationCount,
    onLogout,
    onManageDisableScrolling,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onResendVerificationEmail,
    updateInProgress,
    onUpdateProfile,
    tosAssetsData,
    tosFetchInProgress,
    tosFetchError,
    image,
    ...rest
  } = props;

  const onImageUploadHandler = (values, fn) => {
    const { id, imageId, file } = values;
    if (file) {
      fn({ id, imageId, file });
    }
  };
  useEffect(() => {
    (function (apiKey) {
      (function (p, e, n, d, o) {
        let v, w, x, y, z; o = p[d] = p[d] || {}; o._q = o._q || [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
        for (w = 0, x = v.length; w < x; ++w)(function (m) {
          o[m] = o[m] || function () { o._qm === v[0] ? 'unshift' : 'push'; };
        })(v[w]);
        y = e.createElement(n); y.async = !0; y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
        z = e.getElementsByTagName(n)[0]; z.parentNode.insertBefore(y, z);

        pendo.initialize({
          visitor: {
            id: currentUser ? currentUser.id.uuid : 'guest',
            email: currentUser ? currentUser.attributes.email : '',
            full_name: currentUser ? currentUser.attributes.profile.displayName : '',
          },
          account: {
            id: currentUser ? currentUser.id.uuid : 'guest',
          }
        });
        if (typeof pendo.get_visitor_id == 'function') {
          if (currentUser && pendo.get_visitor_id() != currentUser.id.uuid) {
            pendo.set_visitor_id(currentUser.id.uuid);
            pendo.set_account_id(currentUser.id.uuid);
          } else {
            pendo.set_visitor_id('guest');
            pendo.set_account_id('guest');
          }
        }
      })(window, document, 'script', 'pendo');
    })('68490c44-3d98-45ab-6d67-cf1b7ebf8172');
  }, []);

  return (
    <Topbar
      authInProgress={authInProgress}
      currentPage={currentPage}
      currentSearchParams={currentSearchParams}
      currentUser={currentUser}
      currentUserHasListings={currentUserHasListings}
      currentUserListing={currentUserListing}
      currentUserListingFetched={currentUserListingFetched}
      currentUserHasOrders={currentUserHasOrders}
      history={history}
      isAuthenticated={isAuthenticated}
      authScopes={authScopes}
      location={location}
      notificationCount={notificationCount}
      onLogout={onLogout}
      onManageDisableScrolling={onManageDisableScrolling}
      onResendVerificationEmail={onResendVerificationEmail}
      sendVerificationEmailInProgress={sendVerificationEmailInProgress}
      sendVerificationEmailError={sendVerificationEmailError}
      showGenericError={hasGenericError}
      updateInProgress={updateInProgress}
      onUpdateProfile={onUpdateProfile}
      tosAssetsData={tosAssetsData}
      tosFetchInProgress={tosFetchInProgress}
      tosFetchError={tosFetchError}
      onImageUpload={e => onImageUploadHandler(e, onImageUpload)}
      image={image}
      {...rest}
    />
  );
};

TopbarContainerComponent.defaultProps = {
  currentPage: null,
  currentSearchParams: null,
  currentUser: null,
  currentUserHasOrders: null,
  notificationCount: 0,
  sendVerificationEmailError: null,
  currentUserListing: null,
  authScopes: null,

  tosAssetsData: null,
  tosFetchInProgress: false,
  tosFetchError: null,
};

TopbarContainerComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentPage: string,
  currentSearchParams: object,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserListingFetched: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserHasOrders: bool,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  hasGenericError: bool.isRequired,

  tosAssetsData: object,
  tosFetchInProgress: bool,
  tosFetchError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({ state: object }).isRequired,
};

const mapStateToProps = state => {
  // Topbar needs isAuthenticated
  const { isAuthenticated, logoutError, authScopes } = state.Auth;
  // Topbar needs user info.
  const {
    currentUser,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    currentUserHasOrders,
    currentUserNotificationCount: notificationCount,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
  } = state.user;
  const {
    pageAssetsData: tosAssetsData,
    inProgress: tosFetchInProgress,
    error: tosFetchError
  } = state.hostedAssets || {};
  const { updateInProgress, image } = state.ProfileSettingsPage;
  const hasGenericError = !!(logoutError || hasCurrentUserErrors(state));

  return {
    tosAssetsData,
    tosFetchInProgress,
    tosFetchError,
    authInProgress: authenticationInProgress(state),
    currentUser,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    currentUserHasOrders,
    notificationCount,
    isAuthenticated,
    authScopes,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    hasGenericError,
    updateInProgress,
    image
  };
};

const mapDispatchToProps = dispatch => ({
  onLogout: historyPush => dispatch(logout(historyPush)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onUpdateProfile: (data, listingData) => dispatch(updateProfile(data, listingData)),
  onImageUpload: data => dispatch(uploadImage(data)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const TopbarContainer = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(TopbarContainerComponent);

export default TopbarContainer;
