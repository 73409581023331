import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

const IconVideo = props => {
    const { className, rootClassName, type } = props;
    const classes = classNames(rootClassName, className);

    switch (type) {
        case "CC":
            return (
                <svg
                    className={classes}
                    width="56px"
                    height="56px"
                    viewBox="0 0 56 56"
                    xmlns="http://www.w3.org/2000/svg"
                    {...props}
                >
                    <title>{"ionicons-v5_logos"}</title>
                    <path d="M0 8.75V47.25H56V8.75ZM50.75 27.976c0 2.815 -0.175 4.957 -0.412 8.446s-2.1 5.943 -6.463 6.328S33.4 43.171 28 43.159c-5.359 0.012 -11.5 -0.012 -15.875 -0.409s-6.212 -2.844 -6.463 -6.328S5.25 30.791 5.25 27.976s0.012 -4.644 0.412 -8.446 2.516 -5.919 6.463 -6.304S22.875 12.841 28 12.841s11.922 0 15.875 0.385 6.063 2.516 6.463 6.304S50.75 25.173 50.75 27.976Z" />
                    <path d="M40.203 30.936v0.084c0 1.961 -1.215 3.116 -2.838 3.116s-2.717 -1.299 -2.873 -3.116c0 0 -0.143 -0.95 -0.143 -2.875a25.102 25.102 0 0 1 0.167 -3.128c0.289 -2.045 1.287 -3.116 2.909 -3.116s2.897 1.396 2.897 3.513v0.06h5.423c0 -2.635 -0.662 -5.005 -1.996 -6.497S40.442 16.734 37.822 16.734a11.819 11.819 0 0 0 -3.609 0.517 6.433 6.433 0 0 0 -2.837 1.817C30.585 19.939 29.969 21.094 29.531 22.55s-0.656 3.281 -0.656 5.498c0 2.166 0.18 3.97 0.529 5.426s0.875 2.611 1.575 3.477a5.442 5.442 0 0 0 2.625 1.805q1.586 0.505 3.719 0.505c3.005 0 5.169 -0.766 6.467 -2.25S45.719 33.366 45.719 30.527H40.179C40.203 30.527 40.203 30.827 40.203 30.936Z" />
                    <path d="M21.58 30.936v0.084c0 1.961 -1.214 3.116 -2.837 3.116s-2.717 -1.299 -2.873 -3.116c0 0 -0.143 -0.95 -0.143 -2.875a25.102 25.102 0 0 1 0.167 -3.128c0.289 -2.045 1.287 -3.116 2.909 -3.116S21.7 23.297 21.7 25.413v0.06H27.125c0 -2.635 -0.656 -5.005 -1.996 -6.497S21.82 16.734 19.2 16.734a11.819 11.819 0 0 0 -3.609 0.517 6.433 6.433 0 0 0 -2.837 1.817c-0.794 0.866 -1.407 2.021 -1.852 3.477s-0.656 3.281 -0.656 5.498c0 2.166 0.18 3.97 0.529 5.426s0.875 2.611 1.575 3.477a5.442 5.442 0 0 0 2.625 1.805q1.587 0.505 3.719 0.505c3.006 0 5.17 -0.766 6.468 -2.25s1.948 -3.645 1.948 -6.485H21.556C21.58 30.527 21.58 30.827 21.58 30.936Z" />
                </svg>
            );
        case "CHAT":
            return (
                <svg
                    className={classes}
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 56 56"
                    style={{
                        enableBackground: "new 0 0 230 230",
                    }}
                    xmlSpace="preserve"
                    width={56}
                    height={56}
                    {...props}
                >
                    <path d="M56 54.073V1.927H0v43.583h42.563L56 54.073zM3.652 5.579h48.696v41.836l-8.72 -5.558H3.652V5.579zM15.278 27.289h25.443v3.652H15.278V27.289zM15.278 16.495h25.443v3.652H15.278V16.495z" />
                </svg>
            );
        case "ERASE":
            return (
                <svg className={classes} width="24px" height="24px" viewBox="0 0 24 24">
                    <g>
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M14 19h7v2h-9l-3.998.002-6.487-6.487a1 1 0 0 1 0-1.414L12.12 2.494a1 1 0 0 1 1.415 0l7.778 7.778a1 1 0 0 1 0 1.414L14 19zm1.657-4.485l3.535-3.536-6.364-6.364-3.535 3.536 6.364 6.364z" />
                    </g>
                </svg>
            );
        case "CLEAR":
            return (
                <svg className={classes} width="24px" height="24px" viewBox="0 0 24 24">
                    <path
                        fill="none"
                        stroke="#000"
                        stroke-width="2"
                        d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M5,5 L19,19"
                    />
                </svg>
            );
        case "MUTE-MIC":
            return (
                <svg width="16px" height="16px" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512">
                    <g>
                        <g>
                            <g>
                                <path d="M426.667,213.333c-11.782,0-21.333,9.551-21.333,21.333V256c0,82.469-66.865,149.333-149.333,149.333     S106.667,338.469,106.667,256v-21.333c0-11.782-9.551-21.333-21.333-21.333c-11.782,0-21.333,9.551-21.333,21.333V256     c0,98.821,74.674,180.199,170.667,190.814v22.519H192c-11.782,0-21.333,9.551-21.333,21.333S180.218,512,192,512h128     c11.782,0,21.333-9.551,21.333-21.333s-9.551-21.333-21.333-21.333h-42.667v-22.519C373.326,436.199,448,354.821,448,256v-21.333     C448,222.885,438.449,213.333,426.667,213.333z" />
                                <path d="M256,384c70.683,0,128-57.317,128-128V128C384,57.317,326.683,0,256,0S128,57.317,128,128v128     C128,326.683,185.317,384,256,384z M170.667,128c0-47.119,38.214-85.333,85.333-85.333S341.333,80.881,341.333,128v128     c0,47.119-38.214,85.333-85.333,85.333S170.667,303.119,170.667,256V128z" />
                                <path d="M70.248,411.582l-64,64c-8.331,8.331-8.331,21.839,0,30.17c8.331,8.331,21.839,8.331,30.17,0l64-64     c8.331-8.331,8.331-21.839,0-30.17C92.087,403.251,78.58,403.251,70.248,411.582z" />
                                <path d="M249.752,292.418l64-64c8.331-8.331,8.331-21.839,0-30.17c-8.331-8.331-21.839-8.331-30.17,0l-64,64     c-8.331,8.331-8.331,21.839,0,30.17S241.42,300.749,249.752,292.418z" />
                                <path d="M505.752,6.248c-8.331-8.331-21.839-8.331-30.17,0l-64,64c-8.331,8.331-8.331,21.839,0,30.17     c8.331,8.331,21.839,8.331,30.17,0l64-64C514.083,28.087,514.083,14.58,505.752,6.248z" />
                            </g>
                        </g>
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                </svg>
            );
        case "UNMUTE-VIDEO":
            return (
                <svg
                    className={classes}
                    width="41"
                    height="29"
                    viewBox="0 0 41 29"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M40.3384 28.1243C39.931 28.3431 39.4362 28.3202 39.0508 28.065L28.2289 20.8968V23.8561C28.2289 26.2799 26.2571 28.2515 23.8335 28.2515H4.39544C1.97184 28.2515 0 26.2799 0 23.8561V4.41788C0 1.99427 1.97184 0.0224377 4.39544 0.0224377H23.8335C26.2571 0.0224377 28.2289 1.99427 28.2289 4.41788V7.37664L39.0508 0.208803C39.2602 0.0701581 39.5019 -4.53553e-07 39.7443 -4.53553e-07C39.9481 -4.53553e-07 40.1524 0.0495617 40.3384 0.149527C40.7458 0.368378 41 0.793523 41 1.25584V27.018C41 27.4803 40.7458 27.9054 40.3384 28.1243ZM25.7173 4.41788C25.7173 3.37938 24.8722 2.53412 23.8335 2.53412H4.39544C3.35678 2.53412 2.51168 3.37938 2.51168 4.41788V23.8561C2.51168 24.8948 3.35678 25.7399 4.39544 25.7399H23.8335C24.8722 25.7399 25.7173 24.8948 25.7173 23.8561V18.5586V9.71484V4.41788ZM38.4883 3.59404L28.2288 10.3893V17.8842L38.4883 24.6798V3.59404Z"
                        fill="black"
                    />
                </svg>
            );
        case "UNMUTE":
            return (
                <svg
                    className={classes}
                    width="47"
                    height="35"
                    viewBox="0 0 47 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M26.8955 0C26.4054 0 25.9153 0.130027 25.4752 0.380078C25.4452 0.400082 25.4152 0.410084 25.3952 0.430088L15.3831 7.3515H7.3315C3.29067 7.3515 0 10.6422 0 14.683V20.2441C0 24.285 3.29067 27.5756 7.3315 27.5756H15.7832L25.3752 34.497C25.4052 34.517 25.4452 34.5471 25.4752 34.5671C25.9053 34.8171 26.3954 34.9471 26.8955 34.9471C27.6356 34.9471 28.3358 34.6671 28.8659 34.157C29.426 33.6169 29.7361 32.8967 29.7361 32.1166V2.84058C29.7361 1.27026 28.4658 0 26.8955 0ZM27.2856 32.1066C27.2856 32.2166 27.2456 32.3066 27.1755 32.3866C27.1055 32.4566 26.9955 32.4966 26.9055 32.4966C26.8555 32.4966 26.8055 32.4866 26.7555 32.4666L16.9135 25.3552C16.7034 25.2051 16.4534 25.1251 16.1933 25.1251H7.3315C4.64095 25.1251 2.4505 22.9347 2.4505 20.2441V14.683C2.4505 11.9924 4.64095 9.802 7.3315 9.802H15.7632C16.0133 9.802 16.2533 9.72198 16.4634 9.58196L26.7355 2.48051C26.7855 2.4505 26.8455 2.4405 26.8955 2.4405C27.0555 2.4405 27.2856 2.56052 27.2856 2.83058V32.1066Z"
                        fill="black"
                    />
                    <path
                        d="M40.3411 4.35695C40.5598 4.12838 40.8562 4 41.1653 4C41.4744 4 41.7708 4.12838 41.9895 4.35695C48.6702 11.3482 48.6702 22.6812 41.9895 29.6724C41.7684 29.888 41.476 30.0054 41.1738 29.9998C40.8717 29.9942 40.5834 29.8661 40.3697 29.6425C40.156 29.4189 40.0336 29.1172 40.0282 28.801C40.0229 28.4848 40.1351 28.1788 40.3411 27.9474C41.7132 26.5118 42.8015 24.8074 43.5441 22.9315C44.2867 21.0557 44.6689 19.0451 44.6689 17.0147C44.6689 14.9843 44.2867 12.9737 43.5441 11.0979C42.8015 9.222 41.7132 7.5176 40.3411 6.08197C40.1227 5.85312 40 5.5429 40 5.21946C40 4.89602 40.1227 4.5858 40.3411 4.35695V4.35695Z"
                        fill="black"
                    />
                    <path
                        d="M36.1086 10.3454C35.9939 10.2359 35.8577 10.149 35.7079 10.0898C35.558 10.0305 35.3974 10 35.2352 10C35.073 10 34.9124 10.0305 34.7625 10.0898C34.6126 10.149 34.4765 10.2359 34.3618 10.3454C34.2471 10.4549 34.1561 10.5849 34.094 10.728C34.0319 10.8711 34 11.0245 34 11.1794C34 11.3342 34.0319 11.4876 34.094 11.6307C34.1561 11.7738 34.2471 11.9038 34.3618 12.0133C35.0498 12.6702 35.5955 13.45 35.9678 14.3082C36.3401 15.1665 36.5318 16.0863 36.5318 17.0153C36.5318 17.9442 36.3401 18.8641 35.9678 19.7224C35.5955 20.5806 35.0498 21.3604 34.3618 22.0173C34.1437 22.2408 34.0249 22.5364 34.0306 22.8418C34.0362 23.1473 34.1658 23.4387 34.392 23.6547C34.6183 23.8707 34.9235 23.9944 35.2434 23.9998C35.5633 24.0052 35.8729 23.8918 36.1069 23.6836C37.9593 21.9148 39 19.5159 39 17.0145C39 14.5131 37.9593 12.1142 36.1069 10.3454H36.1086Z"
                        fill="black"
                    />
                </svg>
            );
        case "SCREEN":

            return (
                <svg
                    className={classes}
                    width="35"
                    height="33"
                    viewBox="0 0 35 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M31.4036 0H3.59665C1.6134 0 0 1.65061 0 3.67961V23.5285C0 25.5575 1.6134 27.2082 3.59665 27.2082H16.3283V30.6031H8.88754C8.24058 30.6031 7.71609 31.1397 7.71609 31.8015C7.71609 32.4634 8.24058 33 8.88754 33H26.112C26.759 33 27.2835 32.4634 27.2835 31.8015C27.2835 31.1397 26.759 30.6031 26.112 30.6031H18.6712V27.2082H31.4036C33.3867 27.2082 35 25.5575 35 23.5285V3.67961C35 1.65061 33.3867 0 31.4036 0ZM32.6571 23.5285C32.6571 24.2359 32.0948 24.8112 31.4036 24.8112H17.4998H3.59665C2.90529 24.8112 2.34289 24.2359 2.34289 23.5285V3.67961C2.34289 2.9723 2.90529 2.39693 3.59665 2.39693H31.4035C32.0947 2.39693 32.657 2.9723 32.657 3.67961L32.6571 23.5285Z"
                        fill="black"
                    />
                </svg>
            );
        case "MUTE-VIDEO":
            return (
                <svg
                    className={classes}
                    width="44"
                    height="32"
                    viewBox="0 0 44 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M32.0052 2.85369C32.5042 2.37657 32.5218 1.58531 32.0447 1.08636C31.5676 0.587419 30.7763 0.569728 30.2774 1.04685L28.7809 2.47788C28.1936 2.1864 27.5323 2.02244 26.8334 2.02244H7.39536C4.97175 2.02244 2.99992 3.99427 2.99992 6.41788V25.8561C2.99992 26.2479 3.05144 26.6279 3.14807 26.9896L0.90343 29.1361C0.404484 29.6132 0.386795 30.4045 0.863917 30.9034C1.34104 31.4024 2.1323 31.4201 2.63124 30.9429L4.49745 29.1584C5.27167 29.8386 6.2862 30.2515 7.39536 30.2515H26.8334C29.257 30.2515 31.2289 28.2799 31.2289 25.8561V22.8968L42.0507 30.065C42.4361 30.3202 42.9309 30.3431 43.3383 30.1243C43.7457 29.9054 43.9999 29.4803 43.9999 29.018V3.25584C43.9999 2.79352 43.7457 2.36838 43.3383 2.14953C43.1523 2.04956 42.948 2 42.7442 2C42.5018 2 42.2602 2.07016 42.0507 2.2088L31.2289 9.37664V6.41788C31.2289 5.60196 31.0054 4.83725 30.6164 4.18174L32.0052 2.85369ZM26.6306 4.53412H7.39536C6.3567 4.53412 5.5116 5.37938 5.5116 6.41788V24.7295L26.6306 4.53412ZM6.3282 27.4077L28.6781 6.03527C28.7037 6.15885 28.7172 6.28683 28.7172 6.41788V11.7148V20.5586V25.8561C28.7172 26.8948 27.8721 27.7399 26.8334 27.7399H7.39536C6.9995 27.7399 6.63176 27.6171 6.3282 27.4077ZM31.2287 12.3893L41.4882 5.59404V26.6798L31.2287 19.8842V12.3893Z"
                        fill="black"
                    />
                </svg>
            );
        case "MUTE":

            return (
                <svg
                    className={classes}
                    width="49"
                    height="35"
                    viewBox="0 0 49 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M26.8955 0C26.4054 0 25.9153 0.130027 25.4752 0.380078C25.4452 0.400082 25.4152 0.410084 25.3952 0.430088L15.3831 7.3515H7.3315C3.29067 7.3515 0 10.6422 0 14.683V20.2441C0 24.285 3.29067 27.5756 7.3315 27.5756H15.7832L25.3752 34.497C25.4052 34.517 25.4452 34.5471 25.4752 34.5671C25.9053 34.8171 26.3954 34.9471 26.8955 34.9471C27.6356 34.9471 28.3358 34.6671 28.8659 34.157C29.426 33.6169 29.7361 32.8967 29.7361 32.1166V2.84058C29.7361 1.27026 28.4658 0 26.8955 0ZM27.2856 32.1066C27.2856 32.2166 27.2456 32.3066 27.1755 32.3866C27.1055 32.4566 26.9955 32.4966 26.9055 32.4966C26.8555 32.4966 26.8055 32.4866 26.7555 32.4666L16.9135 25.3552C16.7034 25.2051 16.4534 25.1251 16.1933 25.1251H7.3315C4.64095 25.1251 2.4505 22.9347 2.4505 20.2441V14.683C2.4505 11.9924 4.64095 9.802 7.3315 9.802H15.7632C16.0133 9.802 16.2533 9.72198 16.4634 9.58196L26.7355 2.48051C26.7855 2.4505 26.8455 2.4405 26.8955 2.4405C27.0555 2.4405 27.2856 2.56052 27.2856 2.83058V32.1066Z"
                        fill="black"
                    />
                    <path
                        d="M48.6199 10.1121C48.1398 9.63197 47.3697 9.63197 46.8896 10.1121L41.2584 15.7432L35.6273 10.1121C35.1472 9.63197 34.377 9.63197 33.8969 10.1121C33.4168 10.5922 33.4168 11.3623 33.8969 11.8424L39.5281 17.4736L33.8969 23.1047C33.4168 23.5848 33.4168 24.355 33.8969 24.8351C34.137 25.0751 34.447 25.1951 34.7671 25.1951C35.0872 25.1951 35.3972 25.0751 35.6373 24.8351L41.2684 19.2039L46.8996 24.8351C47.1396 25.0751 47.4497 25.1951 47.7697 25.1951C48.0898 25.1951 48.3999 25.0751 48.6399 24.8351C49.12 24.355 49.12 23.5848 48.6399 23.1047L42.9888 17.4736L48.6199 11.8424C49.09 11.3623 49.09 10.5922 48.6199 10.1121Z"
                        fill="black"
                    />
                </svg>
            );
        case "LEAVE-CALL":
            return (
                <svg
                    className={classes}
                    width="42"
                    height="19"
                    viewBox="0 0 42 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7.33335 16.3458L12.7396 13.6917C12.9754 13.5841 13.1782 13.4156 13.3272 13.2036C13.4763 12.9916 13.5661 12.7437 13.5875 12.4854L13.7042 10.3354C13.7221 10.0676 13.8177 9.81076 13.9795 9.59649C14.1412 9.38222 14.3619 9.21981 14.6146 9.12917C18.9825 7.50542 23.7884 7.50542 28.1563 9.12917C28.4089 9.21981 28.6297 9.38222 28.7914 9.59649C28.9531 9.81076 29.0488 10.0676 29.0667 10.3354L29.2938 12.4854C29.309 12.7445 29.3931 12.9948 29.5374 13.2105C29.6817 13.4262 29.881 13.5995 30.1146 13.7125L35.5209 16.3667L36.9 17.0562C37.1002 17.1526 37.3195 17.2024 37.5417 17.2021C37.9011 17.2034 38.2481 17.0704 38.5146 16.8292C39.2584 16.169 39.8339 15.341 40.1933 14.4137C40.5528 13.4865 40.6858 12.4869 40.5813 11.4979L40.5188 11.0083L40.4854 10.7542C40.4736 10.638 40.4555 10.5226 40.4313 10.4083L40.3479 10.0708C39.2021 5.58958 31.8521 2.06458 22.7313 1.72083L20.1375 1.72083C11.0292 2.06458 3.67918 5.58958 2.4646 10.0542L2.38126 10.3917C2.35707 10.5052 2.33899 10.6199 2.3271 10.7354L2.29168 10.9896L2.22918 11.4792C2.12527 12.468 2.25876 13.4673 2.61856 14.3941C2.97836 15.321 3.55409 16.1486 4.29793 16.8083C4.56441 17.0496 4.9114 17.1826 5.27085 17.1812C5.49299 17.1816 5.71234 17.1317 5.91251 17.0354L7.33335 16.3458Z"
                        stroke="black"
                        strokeWidth="2.5"
                        strokeMiterlimit="10"
                    />
                </svg>
            );
        case "COPY":
            return (
                <svg width="20px" height="20px" id="Capa_1" x="0px" y="0px" viewBox="0 0 488.3 488.3">
                    <g>
                        <g>
                            <path d="M314.25,85.4h-227c-21.3,0-38.6,17.3-38.6,38.6v325.7c0,21.3,17.3,38.6,38.6,38.6h227c21.3,0,38.6-17.3,38.6-38.6V124    C352.75,102.7,335.45,85.4,314.25,85.4z M325.75,449.6c0,6.4-5.2,11.6-11.6,11.6h-227c-6.4,0-11.6-5.2-11.6-11.6V124    c0-6.4,5.2-11.6,11.6-11.6h227c6.4,0,11.6,5.2,11.6,11.6V449.6z" />
                            <path d="M401.05,0h-227c-21.3,0-38.6,17.3-38.6,38.6c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5c0-6.4,5.2-11.6,11.6-11.6h227    c6.4,0,11.6,5.2,11.6,11.6v325.7c0,6.4-5.2,11.6-11.6,11.6c-7.5,0-13.5,6-13.5,13.5s6,13.5,13.5,13.5c21.3,0,38.6-17.3,38.6-38.6    V38.6C439.65,17.3,422.35,0,401.05,0z" />
                        </g>
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                </svg>
            );
        case "PEN":
            return (
                <svg className={classes} id="Layer_1" x="0px" y="0px" viewBox="0 0 486.395 486.395">
                    <path d="M486.395,113.609l-46.198-46.198l46.016-46.016L465,0.182l-46.016,46.016L372.786,0L55.796,316.991L24.463,425.937  l7.391,7.391L0,465.182l21.213,21.213l31.854-31.854l7.391,7.392L169.405,430.6l246.232-246.233l21.254,21.254l-93.647,93.647  l21.213,21.213l114.86-114.86l-42.467-42.467L486.395,113.609z M443.969,113.609l-28.332,28.332l-71.182-71.183l28.332-28.332  L443.969,113.609z M58.183,417.23l18.342-63.775l56.415,56.415l-63.775,18.342L58.183,417.23z M161.536,396.041l-71.182-71.182  L323.242,91.971l71.182,71.183L161.536,396.041z" />
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                </svg>
            );
        case "FLIP":
            return (
                <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M9.828 5l-2 2H4v12h16V7h-3.828l-2-2H9.828zM9 3h6l2 2h4a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h4l2-2zm.64 4.53a5.5 5.5 0 0 1 6.187 8.92L13.75 12.6h1.749l.001-.1a3.5 3.5 0 0 0-4.928-3.196L9.64 7.53zm4.677 9.96a5.5 5.5 0 0 1-6.18-8.905L10.25 12.5H8.5a3.5 3.5 0 0 0 4.886 3.215l.931 1.774z" />
                    </g>
                </svg>
            );
        default:
            return (
                <svg className={className} width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g id="grid_system" />
                    <g id="_icons">
                        <path d="M20.8,7.3C20,6.8,19,6.6,18.1,6.9l-2.3,0.8C15.2,6.1,13.7,5,12,5H6C3.8,5,2,6.8,2,9v6c0,2.2,1.8,4,4,4h6   c1.7,0,3.2-1.1,3.8-2.7l2.3,0.8c0.3,0.1,0.6,0.2,1,0.2c0.6,0,1.2-0.2,1.7-0.6c0.8-0.6,1.2-1.5,1.2-2.4V9.8   C22,8.8,21.5,7.9,20.8,7.3z M12,17H6c-1.1,0-2-0.9-2-2V9c0-1.1,0.9-2,2-2h6c1.1,0,2,0.9,2,2v6C14,16.1,13.1,17,12,17z M20,14.2   c0,0.3-0.2,0.6-0.4,0.8c-0.3,0.2-0.6,0.2-0.9,0.1L16,14.3V9.7l2.7-0.9C19,8.7,19.3,8.8,19.6,9C19.8,9.2,20,9.4,20,9.8V14.2z" />
                    </g>
                </svg>
            );
    }
};

IconVideo.defaultProps = {
    className: null,
    rootClassName: null,
    type: 'default',
};

IconVideo.propTypes = {
    className: string,
    rootClassName: string,
    type: string,
};

export default IconVideo;
