import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './SocialIconCard.module.css';

const INSTAGRAM = "instagram";
const FACEBOOK = "facebook";
const BROWSER = "browser";
const TIKTOK = "tiktok";
const LINKEDIN = "linkedin";

const SocialIconCard = props => {
  const { className, rootClassName, type } = props;
  const classes = classNames(rootClassName || css.root, className);

  switch (type) {
    case INSTAGRAM:
      return (
        <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.4972 7.33177C8.56916 7.33177 6.99575 8.98013 6.99575 11C6.99575 13.0199 8.56916 14.6682 10.4972 14.6682C12.4252 14.6682 13.9986 13.0199 13.9986 11C13.9986 8.98013 12.4252 7.33177 10.4972 7.33177ZM20.9988 11C20.9988 9.48097 21.012 7.97571 20.9305 6.45943C20.8491 4.69824 20.4656 3.13519 19.2363 1.84732C18.0044 0.556694 16.515 0.157674 14.8339 0.0723668C13.3839 -0.0129408 11.9471 0.000818524 10.4998 0.000818524C9.04985 0.000818524 7.61303 -0.0129408 6.16571 0.0723668C4.4846 0.157674 2.99262 0.559446 1.76331 1.84732C0.53138 3.13794 0.150505 4.69824 0.0690761 6.45943C-0.0123524 7.97846 0.000781304 9.48373 0.000781304 11C0.000781304 12.5163 -0.0123524 14.0243 0.0690761 15.5406C0.150505 17.3018 0.534007 18.8648 1.76331 20.1527C2.99525 21.4433 4.4846 21.8423 6.16571 21.9276C7.61566 22.0129 9.05248 21.9992 10.4998 21.9992C11.9498 21.9992 13.3866 22.0129 14.8339 21.9276C16.515 21.8423 18.007 21.4406 19.2363 20.1527C20.4682 18.8621 20.8491 17.3018 20.9305 15.5406C21.0146 14.0243 20.9988 12.519 20.9988 11ZM10.4972 16.6441C7.51584 16.6441 5.10976 14.1234 5.10976 11C5.10976 7.87664 7.51584 5.35594 10.4972 5.35594C13.4785 5.35594 15.8846 7.87664 15.8846 11C15.8846 14.1234 13.4785 16.6441 10.4972 16.6441ZM16.1052 6.44292C15.4092 6.44292 14.847 5.85402 14.847 5.12478C14.847 4.39554 15.4092 3.80664 16.1052 3.80664C16.8013 3.80664 17.3634 4.39554 17.3634 5.12478C17.3636 5.29794 17.3312 5.46945 17.2681 5.62947C17.2049 5.78949 17.1123 5.93489 16.9954 6.05733C16.8785 6.17978 16.7397 6.27686 16.587 6.34303C16.4342 6.40919 16.2705 6.44314 16.1052 6.44292Z" fill="#003047" />
        </svg>
      );
    case FACEBOOK:
      return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M0 12.5698C0 18.7844 4.333 23.9521 10 25V15.9719H7V12.5H10V9.72188C10 6.59687 11.933 4.86146 14.667 4.86146C15.533 4.86146 16.467 5 17.333 5.13854V8.33333H15.8C14.333 8.33333 14 9.09687 14 10.0698V12.5H17.2L16.667 15.9719H14V25C19.667 23.9521 24 18.7854 24 12.5698C24 5.65625 18.6 0 12 0C5.4 0 0 5.65625 0 12.5698Z" fill="#003047" />
        </svg>
      );
    case BROWSER:
      return (
        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.796 13.8C15.884 13.041 15.95 12.282 15.95 11.5C15.95 10.718 15.884 9.959 15.796 9.2H19.514C19.69 9.936 19.8 10.7065 19.8 11.5C19.8 12.2935 19.69 13.064 19.514 13.8M13.849 20.194C14.509 18.9175 15.015 17.5375 15.367 16.1H18.612C17.5463 18.0185 15.8555 19.4718 13.849 20.194ZM13.574 13.8H8.426C8.316 13.041 8.25 12.282 8.25 11.5C8.25 10.718 8.316 9.9475 8.426 9.2H13.574C13.673 9.9475 13.75 10.718 13.75 11.5C13.75 12.282 13.673 13.041 13.574 13.8ZM11 20.654C10.087 19.274 9.35 17.7445 8.899 16.1H13.101C12.65 17.7445 11.913 19.274 11 20.654ZM6.6 6.9H3.388C4.44274 4.9763 6.13228 3.52071 8.14 2.806C7.48 4.0825 6.985 5.4625 6.6 6.9ZM3.388 16.1H6.6C6.985 17.5375 7.48 18.9175 8.14 20.194C6.13649 19.4714 4.44933 18.0179 3.388 16.1ZM2.486 13.8C2.31 13.064 2.2 12.2935 2.2 11.5C2.2 10.7065 2.31 9.936 2.486 9.2H6.204C6.116 9.959 6.05 10.718 6.05 11.5C6.05 12.282 6.116 13.041 6.204 13.8M11 2.3345C11.913 3.7145 12.65 5.2555 13.101 6.9H8.899C9.35 5.2555 10.087 3.7145 11 2.3345ZM18.612 6.9H15.367C15.0227 5.47568 14.5129 4.10078 13.849 2.806C15.873 3.5305 17.556 4.991 18.612 6.9ZM11 0C4.917 0 0 5.175 0 11.5C0 14.55 1.15893 17.4751 3.22183 19.6317C4.24327 20.6996 5.4559 21.5467 6.79048 22.1246C8.12506 22.7025 9.55546 23 11 23C13.9174 23 16.7153 21.7884 18.7782 19.6317C20.8411 17.4751 22 14.55 22 11.5C22 9.9898 21.7155 8.49438 21.1627 7.09914C20.6099 5.7039 19.7996 4.43615 18.7782 3.36827C17.7567 2.3004 16.5441 1.45331 15.2095 0.875385C13.8749 0.297456 12.4445 0 11 0Z" fill="#003047" />
        </svg>
      );
    case TIKTOK:
      return (
        <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21 9.02186C18.9357 9.02638 16.9221 8.43628 15.2435 7.3349V15.0162C15.2429 16.4389 14.7685 17.8275 13.8838 18.9963C12.999 20.1652 11.746 21.0586 10.2923 21.5571C8.83867 22.0556 7.25362 22.1354 5.74914 21.7859C4.24466 21.4363 2.89245 20.6741 1.87333 19.6011C0.854205 18.5281 0.21674 17.1955 0.0461722 15.7814C-0.124395 14.3674 0.180066 12.9393 0.918844 11.6882C1.65762 10.437 2.7955 9.42241 4.18034 8.78004C5.56517 8.13767 7.13095 7.89815 8.6683 8.0935V11.9569C7.96481 11.7541 7.20938 11.7602 6.5099 11.9744C5.81041 12.1886 5.20265 12.5999 4.7734 13.1496C4.34415 13.6992 4.11536 14.3592 4.1197 15.0352C4.12404 15.7112 4.3613 16.3686 4.79759 16.9136C5.23387 17.4586 5.84688 17.8633 6.54906 18.07C7.25125 18.2766 8.0067 18.2745 8.70754 18.0641C9.40838 17.8537 10.0188 17.4457 10.4515 16.8983C10.8843 16.3509 11.1173 15.6922 11.1173 15.0162V0H15.2435C15.2406 0.319399 15.2698 0.638362 15.3307 0.952859C15.4741 1.65492 15.7722 2.32279 16.2068 2.91562C16.6415 3.50845 17.2035 4.01378 17.8585 4.40068C18.7903 4.96548 19.8829 5.26651 21 5.26629V9.02186Z" fill="#003047" />
        </svg>
      );
    case LINKEDIN:
      return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M2.7206e-07 1.838C2.7206e-07 1.35053 0.193646 0.883032 0.538338 0.53834C0.88303 0.193648 1.35053 2.45031e-06 1.838 2.45031e-06H20.16C20.4016 -0.000392101 20.6409 0.0468654 20.8641 0.139069C21.0874 0.231273 21.2903 0.366612 21.4612 0.537339C21.6322 0.708065 21.7677 0.910826 21.8602 1.13401C21.9526 1.3572 22.0001 1.59643 22 1.838V20.16C22.0003 20.4016 21.9529 20.6409 21.8606 20.8642C21.7683 21.0875 21.6328 21.2904 21.462 21.4613C21.2912 21.6322 21.0884 21.7678 20.8651 21.8602C20.6419 21.9526 20.4026 22.0001 20.161 22H1.838C1.59655 22 1.35746 21.9524 1.1344 21.86C0.911335 21.7676 0.708671 21.6321 0.537984 21.4613C0.367297 21.2905 0.231932 21.0878 0.139623 20.8647C0.0473133 20.6416 -0.000131096 20.4025 2.7206e-07 20.161V1.838ZM8.708 8.388H11.687V9.884C12.117 9.024 13.217 8.25 14.87 8.25C18.039 8.25 18.79 9.963 18.79 13.106V18.928H15.583V13.822C15.583 12.032 15.153 11.022 14.061 11.022C12.546 11.022 11.916 12.111 11.916 13.822V18.928H8.708V8.388ZM3.208 18.791H6.416V8.25H3.208V18.79V18.791ZM6.875 4.812C6.88105 5.08667 6.83217 5.35979 6.73124 5.61532C6.63031 5.87084 6.47935 6.10364 6.28723 6.30003C6.09511 6.49643 5.8657 6.65247 5.61245 6.75901C5.35921 6.86554 5.08724 6.92042 4.8125 6.92042C4.53776 6.92042 4.26579 6.86554 4.01255 6.75901C3.7593 6.65247 3.52989 6.49643 3.33777 6.30003C3.14565 6.10364 2.99469 5.87084 2.89376 5.61532C2.79283 5.35979 2.74395 5.08667 2.75 4.812C2.76187 4.27286 2.98439 3.75979 3.36989 3.38269C3.75539 3.00558 4.27322 2.79442 4.8125 2.79442C5.35178 2.79442 5.86961 3.00558 6.25511 3.38269C6.64061 3.75979 6.86313 4.27286 6.875 4.812Z" fill="#003047" />
        </svg>
      );
    default:
      return (
        <svg
          className={classes}
          width="29"
          height="19"
          viewBox="0 0 29 19"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fillRule="evenodd">
            <path
              d="M26.58 19H2.42A2.4004 2.4004 0 0 1 0 16.62V2.38A2.4 2.4 0 0 1 2.42 0h24.16A2.4004 2.4004 0 0 1 29 2.38v14.25c-.0165 1.3216-1.0984 2.3811-2.42 2.37zM10 5.83c0-.46-.35-.83-.78-.83H3.78c-.43 0-.78.37-.78.83v3.34c0 .46.35.83.78.83h5.44c.43 0 .78-.37.78-.83V5.83z"
              fill="#DADDE2"
            />
            <path
              d="M25 15h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0H4c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1z"
              fill="#B2B6C1"
            />
          </g>
        </svg>
      );
  }
};

SocialIconCard.defaultProps = {
  className: null,
  rootClassName: null,
  type: 'default',
};

SocialIconCard.propTypes = {
  className: string,
  rootClassName: string,
  type: string,
};

export default SocialIconCard;
